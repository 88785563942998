import {useMutation} from '@apollo/client';
import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {useCreateShareLink} from '../../hooks/sharedlinks';
import useHover from '../../hooks/useHover';

import {faLink} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, OverlayTrigger} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton} from 'react-share';
import styles from './css/Links.module.scss';
import './css/Shake.css';

import {useRecordClick, useRecordPublicClick} from '../../hooks/analytics';
import {SHORTEN_URL} from '../../mutations/url';

import {AuthContext} from '../../context/auth-context';
import {ShortenedUrlContext} from '../../context/shortenedUrl';
import CenteredSpinner from '../loading/CenteredSpinner';
import UpdatingPopover from './UpdatingPopover';

const SocialShareLinks = (props) => {
	const {
		item: {ID: itemID, __typename: itemType, Title: itemTitle, ContentTypeID: contentTypeID, Slug: slug},
		noShare,
		detailsView,
		tourClasses,
		popoverPlacement,
	} = props;
	const {urlMap, setUrl} = useContext(ShortenedUrlContext);
	const {isAuthenticated} = useContext(AuthContext);
	const [hoverRef, isHovered] = useHover();
	const [hoverRef2, isHovered2] = useHover();
	const [showPopover, setShowPopover] = useState(false);

	const togglePopover = useCallback(() => {
		setShowPopover(!showPopover);
	}, [showPopover]);

	const recordTwitterShareClick = useRecordClick({
		resourceID: itemID,
		resourceType: itemType,
		description: 'share on twitter',
	});
	const recordLinkedinShareClick = useRecordClick({
		resourceID: itemID,
		resourceType: itemType,
		description: 'share on linkedin',
	});
	const recordLinkShareClick = useRecordClick({
		resourceID: itemID,
		resourceType: itemType,
		description: 'share by link',
	});
	const recordPublicTwitterShareClick = useRecordPublicClick({
		resourceID: itemID,
		resourceType: itemType,
		description: 'share on twitter',
	});
	const recordPublicLinkedinShareClick = useRecordPublicClick({
		resourceID: itemID,
		resourceType: itemType,
		description: 'share on linkedin',
	});
	const recordPublicLinkShareClick = useRecordPublicClick({
		resourceID: itemID,
		resourceType: itemType,
		description: 'share by link',
	});
	const link = useCreateShareLink({itemID, slug, contentTypeID});
	const [
		shortenUrl,
		{data: shortenedUrl, loading: shortenedUrlLoading, called: shortenedUrlCalled},
	] = useMutation(SHORTEN_URL, {variables: {url: link}});

	const handleClick = () => {
		if (!props.noShare && !shortenedUrlCalled && !urlMap[link]) {
			shortenUrl();
		}
	};

	useEffect(() => {
		if (!shortenedUrlLoading && shortenedUrl) {
			setUrl(link, shortenedUrl.shortenUrl);
		}
		// eslint-disable-next-line
	}, [shortenedUrl, shortenedUrlLoading]);

	const handleLinkShareClick = useCallback(
		(event) => {
			if (isAuthenticated) {
				recordLinkShareClick();
			} else {
				recordPublicLinkShareClick();
			}
			toast('Link Copied!', {
				type: toast.TYPE.SUCCESS,
				position: toast.POSITION.TOP_CENTER,
				autoClose: 1500,
			});
			togglePopover();
		},
		[recordLinkShareClick, togglePopover, isAuthenticated, recordPublicLinkShareClick]
	);

	const popover = (
		<UpdatingPopover id={`socialSharePopup-${itemType}${itemID}`}>
			<div>
				{noShare ? (
					<div>This report is entitled to your account and cannot be shared.</div>
				) : shortenedUrlLoading || !urlMap[link] ? (
					<div className={styles.spinnerContainer}>
						<CenteredSpinner />
					</div>
				) : (
					<Fragment>
						<span
							ref={hoverRef}
							onClick={isAuthenticated ? recordTwitterShareClick : recordPublicTwitterShareClick}
							title="Share to Twitter"
						>
							<TwitterShareButton
								url={urlMap[link]}
								title={itemTitle}
								hashtags={['FrontierView']}
								onClick={togglePopover}
							>
								<TwitterIcon
									size={32}
									iconFillColor={isHovered ? 'white' : 'grey'}
									round={true}
									bgStyle={isHovered ? {} : {fill: 'white'}}
								/>
							</TwitterShareButton>
						</span>
						<span
							ref={hoverRef2}
							onClick={isAuthenticated ? recordLinkedinShareClick : recordPublicLinkedinShareClick}
							title="Share to LinkedIn"
						>
							<LinkedinShareButton url={urlMap[link]} onClick={togglePopover}>
								<LinkedinIcon
									size={32}
									iconFillColor={isHovered2 ? 'white' : 'grey'}
									round={true}
									bgStyle={isHovered2 ? {} : {fill: 'white'}}
								/>
							</LinkedinShareButton>
						</span>
						<span title="Get shortened URL">
							<CopyToClipboard text={urlMap[link]} onCopy={handleLinkShareClick}>
								<Button className={styles.copyButton} onClick={togglePopover}>
									<FontAwesomeIcon icon={faLink} />
								</Button>
							</CopyToClipboard>
						</span>
					</Fragment>
				)}
			</div>
		</UpdatingPopover>
	);

	const buttonClasses = [styles.dropdown];
	if (noShare) {
		buttonClasses.push(styles.dropdownDisabled);
	}
	if (/*itemType === 'NewsArticle' || */ itemType === 'InsightBite' || detailsView) {
		buttonClasses.push('text-muted');
	} else {
		buttonClasses.push('text-white');
	}
	return (
		<OverlayTrigger
			trigger="click"
			placement={popoverPlacement || 'top'}
			rootClose={true}
			shouldUpdatePosition={true}
			onToggle={(show) => setShowPopover(show)}
			show={showPopover}
			overlay={popover}
		>
			<Button
				onClick={handleClick}
				variant="link"
				className={`${buttonClasses.join(' ')} ${tourClasses && tourClasses.share ? tourClasses.share : null} ${
					detailsView ? `shake-item ${styles.detailsShareButtonSize}` : ''
				}`}
				title="Share (can be shared externally)"
			>
				Share
			</Button>
		</OverlayTrigger>
	);
};

export default SocialShareLinks;
