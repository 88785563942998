import React from 'react';
import {Card, Media} from 'react-bootstrap';
import Link from '../analytics/Link';
import CMSLink from '../links/CMSLink';

import styles from './css/Card.module.css';

export default function MarketCard(props) {
	const {item: hub} = props;
	const pathname = `/hub/${hub.ID}/${hub.Slug}`;

	return (
		<Card border="light" className={'rounded-0 shadow ' + styles.cardContainer} style={{marginBottom: '25px'}}>
			<Card.Body>
				<Media className={styles.marketMedia}>
					<Media.Body className="p-2">
						<CMSLink item={hub} color="black">
							<Link to={pathname} resourceID={hub.ID} resourceType="Hub" description="card" className="text-muted">
								<h4>{hub.Title}</h4>
							</Link>
						</CMSLink>
						<Link to={pathname} resourceID={hub.ID} resourceType="Hub" description="card" className="text-muted">
							<div className="mt-2">
								<h6>View Insights</h6>
							</div>
						</Link>
					</Media.Body>
				</Media>
			</Card.Body>
		</Card>
	);
}
