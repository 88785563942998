import React, {useContext, useEffect} from 'react';
import {Row, Col, Card, Image} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';
import {Login} from 'commonsui';
import {AuthContext} from '../../context/auth-context';
import {MetaTagsContext} from '../../context/meta';

import styles from '../register/css/Register.module.scss';

const LoginContainer = (props) => {
	const {
		isAuthenticated,
		login,
		loginState: {error},
		setAuthModalToShow,
	} = useContext(AuthContext);
	const {setDefaultMetaTags} = useContext(MetaTagsContext);
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		setDefaultMetaTags();
	}, [setDefaultMetaTags]);

	useEffect(() => {
		if (isAuthenticated) {
			if (location.state) {
				const {from} =
					location.state && location.state.from !== 'forgotpassword' ? location.state : {from: {pathname: '/'}};
				history.replace(from);
			} else if (location.pathname === '/login' || location.pathname === '/forgotpassword') {
				history.replace('/');
			}
			setAuthModalToShow(null);
		}
		// eslint-disable-next-line
	}, [isAuthenticated, location.pathname]);

	const handleLogin = (values, actions) => {
		login(values.Username, values.Password, values.RememberMe, actions);
	};

	const handleForgotPassword = () => {
		if (props.isModal) {
			setAuthModalToShow({modal: 'forgotPassword'});
		} else {
			history.push('/forgotPassword');
		}
	};

	return (
		<Row>
			<Col
				sm={!props.isModal ? {span: 8, offset: 2} : null}
				md={!props.isModal ? {span: 6, offset: 3} : null}
				lg={!props.isModal ? {span: 4, offset: 4} : null}
			>
				<Card className={props.isModal ? 'shadow-none' : null}>
					<div className={styles.header}>
						<Image src="https://s3.amazonaws.com/images.frontierview.com/fv3/fv-fn-logo.png" alt="FrontierView" />
					</div>
					<Card.Body className={`px-5 pb-5`}>
						<Login
							authError={error ? error.message || error : null}
							onLogin={handleLogin}
							onForgotPassword={handleForgotPassword}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default LoginContainer;
