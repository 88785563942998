import {useLazyQuery} from '@apollo/client';
import {useCallback, useEffect, useState} from 'react';

import {useDebouncedCallback} from 'use-debounce';
import {DEFAULT_CONTENT_SEARCH} from '../queries/search';
import {ContentFilters, ContentTypes, contentTypeToID, EventFilters} from '../util/contentType';
import {useRecordClearSearch, useRecordSearch} from './analytics';
import useFilters from './useFilters';

const options = Object.values(ContentFilters);
const eventOptions = EventFilters;
const sortOptions = [
	{value: 'relevancy', label: 'Sort By Relevance'},
	{value: 'date', label: 'Sort By Date'},
];
const multiFilter = false;

export default function useContent({numResults = 20, initialFilters, searchQS, useEventFilters = false}) {
	const [searchText, setSearchText] = useState(null);
	const [listItems, setListItems] = useState(null);
	const [totalItems, setTotalItems] = useState(null);
	const [page, setPage] = useState(0);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const [selectedSortFilter, setSelectedSortFilter] = useState(sortOptions[0]);
	const [popularSearches, setPopularSearches] = useState([]);
	const [filters, {addFilter, removeFilter}] = useFilters({
		qs: searchQS,
		initialFilters: initialFilters || null,
	});
	const [search, {fetchMore, loading: searchLoading, error: searchError, data: searchData}] = useLazyQuery(
		DEFAULT_CONTENT_SEARCH,
		{
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only',
			nextFetchPolicy: 'cache-first',
		}
	);
	const recordSearch = useRecordSearch();
	const recordClearSearch = useRecordClearSearch();

	useEffect(() => {
		if (!searchError && !searchLoading && searchData) {
			setPopularSearches(searchData.search.PopularSearches);
			setListItems(searchData.search.Results);
			setTotalItems(searchData.search.Total);
		}
	}, [searchData, searchError, searchLoading]);

	const loadMoreContent = (numToShow) => {
		setPage(page + 1);
		const variables = buildFilters(filters);
		fetchMore({
			variables: {...variables, numResults: numResults, pageNum: page + 1},
			updateQuery: (prev, {fetchMoreResult}) => {
				if (!fetchMoreResult) return prev;
				return {
					search: {
						...prev.search,
						Results: [...prev.search.Results, ...fetchMoreResult.search.Results],
					},
				};
			},
		});
	};

	const buildFilters = (filtersObj) => {
		let variables = {
			numResults,
			pageNum: 0,
		};
		if (filtersObj.searchText) {
			variables.searchText = filtersObj.searchText;
		}
		if (filtersObj.filter) {
			let filterMap = {
				reports: false,
				multimedia: false,
				// news: false,
				insightBites: false,
			};
			let filterArr = filtersObj.filter.split(',');
			if (multiFilter) {
				for (let filterName in filterArr) {
					filterMap[filterArr[filterName]] = true;
				}
			} else {
				filterMap[filterArr[0]] = true;
				if (filterArr[0] === 'multimedia') {
					// Include reports with videos
					filterMap.reports = true;
					variables.onlyReportVideos = true;
					variables.multimediaTypeIDs = [
						ContentTypes[contentTypeToID.MULTIMEDIA].VIDEO,
						ContentTypes[contentTypeToID.MULTIMEDIA].WEBINAR,
					];
				}
			}
			if (!multiFilter && !useEventFilters) {
				variables.selectedIndexFilter = filtersObj.filter;
			}
			variables.indices = filterMap;
		} else {
			variables.indices = filtersObj.indices;
		}
		if (filtersObj.marketIDs) {
			variables.marketIDs = filtersObj.marketIDs;
		} else {
			variables.marketIDs = null;
		}
		if (filtersObj.bookmarked) {
			variables.bookmarked = filtersObj.bookmarked === '1';
		} else {
			variables.bookmarked = null;
		}
		if (filtersObj.featured) {
			variables.featured = filtersObj.featured === '1';
		} else {
			variables.featured = null;
		}
		if (filtersObj.hubID) {
			variables.hubID = filtersObj.hubID;
		} else {
			variables.hubID = null;
		}
		if (filtersObj.upcomingOnly) {
			variables.upcomingOnly = filtersObj.upcomingOnly;
		} else {
			variables.upcomingOnly = null;
		}
		if (filtersObj.eventDateTimeframe) {
			variables.eventDateTimeframe = filtersObj.eventDateTimeframe;
		} else {
			// set filter to be null when not in use. i ran into a strange apollo bug
			// where the passed variables are merged into the previous variables.
			// so if a variable exists in one fetch and then in the next fetch it doesn't,
			// the variable will still be there in the merged variables
			variables.eventDateTimeframe = null;
		}
		if (filtersObj.sortBy) {
			variables.sortBy = filtersObj.sortBy;
		} else {
			variables.sortBy = null;
		}
		if (filtersObj.analystContactIDs) {
			variables.analystContactIDs = filtersObj.analystContactIDs;
		} else {
			variables.analystContactIDs = null;
		}
		if (filtersObj.keywordIDs) {
			variables.keywordIDs = filtersObj.keywordIDs;
		} else {
			variables.keywordIDs = null;
		}
		if (filtersObj.reportIDs) {
			variables.reportIDs = filtersObj.reportIDs;
		} else {
			variables.reportIDs = null;
		}
		if (filtersObj.multimediaIDs) {
			variables.multimediaIDs = filtersObj.multimediaIDs;
		} else {
			variables.multimediaIDs = null;
		}
		// if (filtersObj.newsIDs) {
		// 	variables.newsIDs = filtersObj.newsIDs;
		// } else {
		// 	variables.newsIDs = null;
		// }
		if (filtersObj.liveEventIDs) {
			variables.liveEventIDs = filtersObj.liveEventIDs;
		} else {
			variables.liveEventIDs = null;
		}
		if (filtersObj.insightBiteIDs) {
			variables.insightBiteIDs = filtersObj.insightBiteIDs;
		} else {
			variables.insightBiteIDs = null;
		}
		return variables;
	};

	useEffect(() => {
		if (filters) {
			const variables = buildFilters(filters);
			search({
				variables,
			});
			if (!!filters.searchText) {
				recordSearch(filters.searchText);
			}
		}
		// eslint-disable-next-line
	}, [filters]);

	useEffect(() => {
		if (filters.searchText !== searchText) {
			setSearchText(filters.searchText || '');
		}
		// eslint-disable-next-line
	}, [filters.searchText]);

	useEffect(() => {
		if (filters.filter) {
			const filterArray = filters.filter.split(',');
			if (multiFilter) {
				setSelectedFilter(
					options.filter((option) => {
						return filterArray.find((filter) => option.value === filter);
					})
				);
			} else {
				setSelectedFilter(options.find((option) => option.value === filterArray[0]));
			}
		} else {
			setSelectedFilter(null);
		}
	}, [filters.filter]);

	useEffect(() => {
		if (filters.sortBy) {
			const sortFilter = sortOptions.find((sortOption) => sortOption.value === filters.sortBy);
			if (sortFilter && filters.searchText && filters.searchText !== '') {
				setSelectedSortFilter(sortFilter);
			} else {
				removeFilter('sortBy');
			}
		}
	}, [filters.sortBy, filters.searchText, removeFilter]);

	const {callback: updateSearchTextInFilters} = useDebouncedCallback((searchText) => {
		setPage(0);
		setListItems([]);
		if (searchText !== '') {
			addFilter('searchText', searchText);
		} else {
			removeFilter('searchText');
		}
	}, 500);

	const filtersUpdated = (selectedOption) => {
		setPage(0);
		setListItems([]);
		setSelectedFilter(selectedOption);
		if (!useEventFilters) {
			if (selectedOption === null) {
				removeFilter('filter');
			} else if (multiFilter) {
				if (selectedOption.length > 0) {
					addFilter('filter', selectedOption.map((option) => option.value).join(','));
				} else {
					removeFilter('filter');
				}
			} else {
				addFilter('filter', selectedOption.value);
			}
		} else {
			if (selectedOption === null) {
				removeFilter('eventDateTimeframe');
			} else {
				addFilter('eventDateTimeframe', selectedOption.value);
			}
		}
	};

	const sortFiltersUpdated = (selectedOption) => {
		setPage(0);
		setListItems([]);
		setSelectedSortFilter(selectedOption);
		if (selectedOption === null) {
			setSelectedSortFilter(sortOptions[0]);
			removeFilter('sortBy');
		} else if (selectedOption.value !== sortOptions[0].value) {
			addFilter('sortBy', selectedOption.value);
		} else {
			removeFilter('sortBy');
		}
	};

	const setShowBookmarks = useCallback(
		(showBookmarks) => {
			if (showBookmarks) {
				addFilter('bookmarked', '1');
			} else {
				removeFilter('bookmarked');
			}
		},
		[addFilter, removeFilter]
	);

	const setShowFeatured = useCallback(
		(showFeatured) => {
			if (showFeatured) {
				addFilter('featured', '1');
			} else {
				removeFilter('featured');
			}
		},
		[addFilter, removeFilter]
	);

	return {
		items: listItems,
		totalItems: totalItems,
		popularSearches,
		error: searchError,
		loading: searchLoading,
		filtersUpdated,
		selectedFilter,
		updateSearchTextInFilters,
		recordClearSearch,
		loadMoreContent,
		sortOptions,
		selectedSortFilter,
		sortFiltersUpdated,
		filterOptions: useEventFilters ? eventOptions : options,
		filters,
		addFilter,
		removeFilter,
		searchText,
		setSearchText,
		setShowBookmarks,
		setShowFeatured,
	};
}
