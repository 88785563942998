import React, {createContext, useState} from 'react';

export const AnalyticsContext = createContext({
	localSessionAnalyticID: null,
	setLocalSessionAnalyticID: () => {},
});

const AnalyticsProvider = (props) => {
	const [localSessionAnalyticID, setLocalSessionAnalyticID] = useState(null);

	return (
		<AnalyticsContext.Provider value={{localSessionAnalyticID, setLocalSessionAnalyticID}}>
			{props.children}
		</AnalyticsContext.Provider>
	);
};

export default AnalyticsProvider;
