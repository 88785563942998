import React, {useContext, useState} from 'react';
import {Route, useLocation, useParams} from 'react-router-dom';

import {AuthContext} from '../context/auth-context';
import MetaData from '../components/meta/MetaTags';
import SharedLinkWrapper from './SharedLinkWrapper';
import CenteredSpinner from '../components/loading/CenteredSpinner';

const PublicPrivateRoute = (props) => {
	const {isAuthenticated, initialAuthChecked, hasFV3Access} = useContext(AuthContext);
	const [redirectToPublic, setRedirectToPublic] = useState(false);
	const location = useLocation();
	const params = useParams();

	let metaKey;
	if (props.metaKey) {
		metaKey = props.metaKey.replace(':id', params.id);
	} else {
		metaKey = location.state && location.state.background ? location.state.background.pathname : location.pathname;
	}

	const {PublicComponent, PrivateComponent, ...passThroughProps} = props;
	if (!initialAuthChecked) {
		return <CenteredSpinner />;
	}
	if (!isAuthenticated || !hasFV3Access || redirectToPublic) {
		return (
			<MetaData key={metaKey} {...passThroughProps}>
				<Route render={(props) => <PublicComponent {...props} />} {...passThroughProps} />
			</MetaData>
		);
	} else {
		return (
			<MetaData key={metaKey} {...passThroughProps}>
				<Route
					render={(props) => (
						<SharedLinkWrapper {...props}>
							<PrivateComponent {...props} setRedirectToPublic={setRedirectToPublic} />
						</SharedLinkWrapper>
					)}
					{...passThroughProps}
				/>
			</MetaData>
		);
	}
};

export default PublicPrivateRoute;
