import {gql} from '@apollo/client';

export const NEW_AND_NOTEWORTHIES = gql`
	query NewAndNoteworthies {
		newAndNoteworthies {
			ID
			Title
			Subtitle
			Summary
			MultimediaID
			ReportID
			ExternalURL
			Slug
			Assets {
				DownloadURL
			}
		}
	}
`;
