import React from 'react';
import Tour from 'reactour';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

import './css/HomeTour.css';

export default function HomeTour(props) {
	const disableBody = (target) => disableBodyScroll(target);
	const enableBody = (target) => enableBodyScroll(target);

	return (
		<Tour
			{...props}
			onAfterOpen={disableBody}
			onBeforeClose={enableBody}
			disableInteraction={true}
			steps={[
				{
					selector: '.search-bar',
					content: () => (
						<div>
							<h4>Search for specific keywords</h4>
							<div>
								Our powerful search functionality helps you find exactly what you need. Try searching for “China”,
								“elections” or “COVID-19” to browse relevant and timely content
							</div>
						</div>
					),
				},
				{
					selector: '.search-filter-select',
					content: () => (
						<div>
							<h4>Navigate through our insights</h4>
							<div>
								Narrow your search results by filtering for analyst commentary, insight bites, reports, or multimedia
							</div>
						</div>
					),
				},
				{
					selector: '.bookmark-content',
					content: () => (
						<div>
							<h4>Save your favorites</h4>
							<div>
								Save your favorite insights with just one tap to create your own personal library of research,
								high-impact charts, and analyst commentary
							</div>
						</div>
					),
				},
				{
					selector: '.share-content',
					content: () => (
						<div>
							<h4>Share with your peers</h4>
							<div>Quickly share the content that powers your business insights with anyone who needs it</div>
						</div>
					),
				},
				{
					selector: '.markets-dropdown',
					content: () => (
						<div>
							<h4>Explore market pages</h4>
							<div>
								Your one-stop-shop for accessing our view on the market, key forecast revisions, short-term data, and
								all market-related content
							</div>
						</div>
					),
					position: 'bottom',
				},
				{
					selector: 'nav div[name="Settings"]',
					content: () => (
						<div>
							<h4>Follow your favorite markets</h4>
							<div>Flag the markets that matter most to you for easy access</div>
						</div>
					),
					position: 'bottom',
				},
				{
					selector: '.hubs-dropdown',
					content: () => (
						<div>
							<h4>Learn about important topics</h4>
							<div>
								Hubs with focused coverage on topics and macro events impacting business, such as Vaccination and
								Reopening, US-China Tensions, and Brexit
							</div>
						</div>
					),
					position: 'bottom',
				},
				{
					content: () => (
						<div>
							<h4>Stay in the know, on the go</h4>
							<p>
								Download our mobile app, which puts our daily curated news and expert analysis in the palm of your hand
							</p>
							<div className="text-center">
								<a href="https://fv.works/ios" target="_blank" rel="noopener noreferrer">
									<img
										className="m-2"
										alt="iOS App Store Link"
										width="150"
										src="https://s3.amazonaws.com/images.frontierview.com/fv3/app-icons/ios.png"
									/>
								</a>
								<a href="https://fv.works/android" target="_blank" rel="noopener noreferrer">
									<img
										className="m-2"
										width="150"
										alt="Google Play Store Link"
										src="https://s3.amazonaws.com/images.frontierview.com/fv3/app-icons/android.png"
									/>
								</a>
							</div>
						</div>
					),
				},
			]}
			maskSpace={3}
		/>
	);
}
