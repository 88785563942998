import React, {useState} from 'react';
import {env} from '../../util/environment';

import {faCloudDownloadAlt, faFileImage, faFilePdf, faFilePowerpoint} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {Button, OverlayTrigger} from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {contentTypeIDToURLIdentifier, contentTypeToID} from '../../util/contentType';

import styles from './css/Links.module.scss';

import {useRecordDownload} from '../../hooks/analytics';
import UpdatingPopover from './UpdatingPopover';

function formatFilename(title, extension) {
	return `FrontierView_${title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_${moment().format('MMM-DD')}.${extension}`;
}

const DownloadLinks = (props) => {
	const {
		item: {ID: itemID, __typename: itemType, Title: itemTitle, ContentTypeID: contentTypeID},
		detailsView,
		popoverPlacement,
	} = props;

	const [showPopover, setShowPopover] = useState(false);

	const recordDownload = useRecordDownload({
		resourceID: itemID,
		resourceType: itemType,
	});

	const handlePdfDownload = (event, asset) => {
		if (!asset) {
			toast('An error occurred while downloading. Please try again', {
				type: 'error',
			});
			return;
		}
		recordDownload({description: 'PDF'});
		const filename = formatFilename(itemTitle, asset.Extension || asset.Content);
		downloadFile(buildDownloadURL(asset), filename);
		togglePopover();
	};

	const handlePptDownload = (event, asset) => {
		if (!asset) {
			toast('An error occurred while downloading. Please try again', {
				type: 'error',
			});
			return;
		}
		recordDownload({description: 'PPT'});

		const filename = formatFilename(itemTitle, asset.Extension || asset.Content);
		downloadFile(buildDownloadURL(asset), filename);
		togglePopover();
	};

	const handleImgDownload = (event, asset) => {
		if (!asset) {
			toast('An error occurred while downloading. Please try again', {
				type: 'error',
			});
			return;
		}
		recordDownload({description: 'JPEG'});
		const filename = formatFilename(itemTitle, 'jpeg');
		downloadFile(buildDownloadURL(asset), filename);
		togglePopover();
	};

	const downloadFile = (url, filename) => {
		const params = {};
		if (contentTypeID === contentTypeToID.REPORT) {
			params.credentials = 'include';
		} else {
			const headers = new Headers();
			headers.append('pragma', 'no-cache');
			headers.append('cache-control', 'no-cache');
			params.headers = headers;
		}

		fetch(url, params).then((response) => {
			response.blob().then((blob) => {
				let url = window.URL.createObjectURL(blob);
				let a = document.createElement('a');
				a.href = url;
				a.download = filename;
				a.click();
			});
		});
	};

	const buildDownloadURL = (asset) => {
		if (contentTypeID === contentTypeToID.REPORT) {
			return `${env.REACT_APP_API_DOMAIN}/api/assets/${contentTypeIDToURLIdentifier[contentTypeID]}/${asset.ID}`;
		} else {
			return asset.DownloadURL;
		}
	};

	const pdfAsset = props.item.Assets.find((asset) => asset.Extension === 'pdf' || asset.Content === 'pdf');
	const pptAsset = props.item.Assets.find((asset) => asset.Extension === 'pptx' || asset.Content === 'pptx');
	const imgAsset = props.item.Assets.find(
		(asset) => asset.Content && (asset.Content.toLowerCase() === 'jpg' || asset.Content.toLowerCase() === 'png')
	);

	const buttonClasses = [styles.dropdown, 'px-2'];
	if (/*itemType === 'NewsArticle' || */ itemType === 'InsightBite' || detailsView) {
		buttonClasses.push('text-muted');
	} else {
		buttonClasses.push('text-white');
	}

	const togglePopover = () => {
		setShowPopover(!showPopover);
	};

	const showDownloadIcon =
		(itemType === 'Report' && (pdfAsset || pptAsset)) ||
		(itemType === 'InsightBite' && imgAsset) ||
		(itemType === 'LiveEvent' && (pdfAsset || pptAsset));

	const renderIcons = () => {
		if (itemType === 'Report') {
			return (
				<>
					<span title="Download PDF">
						<Button className={styles.pptButton} onClick={(event) => handlePdfDownload(event, pdfAsset)}>
							<FontAwesomeIcon size={detailsView ? 'lg' : '1x'} icon={faFilePdf} fixedWidth />
						</Button>
					</span>
					<span title="Download PowerPoint">
						<Button className={styles.pptButton} onClick={(event) => handlePptDownload(event, pptAsset)}>
							<FontAwesomeIcon size={detailsView ? 'lg' : '1x'} icon={faFilePowerpoint} fixedWidth />
						</Button>
					</span>
				</>
			);
		} else if (itemType === 'InsightBite') {
			return (
				<span title="Download Image">
					<Button className={styles.pptButton} onClick={(event) => handleImgDownload(event, imgAsset)}>
						<FontAwesomeIcon icon={faFileImage} fixedWidth />
					</Button>
				</span>
			);
		} else if (itemType === 'LiveEvent') {
			return (
				<>
					{pdfAsset ? (
						<span title="Download PDF">
							<Button className={styles.pptButton} onClick={(event) => handlePdfDownload(event, pdfAsset)}>
								<FontAwesomeIcon size={detailsView ? 'lg' : '1x'} icon={faFilePdf} fixedWidth />
							</Button>
						</span>
					) : null}
					{pptAsset ? (
						<span title="Download PowerPoint">
							<Button className={styles.pptButton} onClick={(event) => handlePptDownload(event, pptAsset)}>
								<FontAwesomeIcon size={detailsView ? 'lg' : '1x'} icon={faFilePowerpoint} fixedWidth />
							</Button>
						</span>
					) : null}
				</>
			);
		}
		return null;
	};

	const popover = (
		<UpdatingPopover id={`downloadPopup-${itemType}${itemID}`}>
			<div>{renderIcons()}</div>
		</UpdatingPopover>
	);

	return showDownloadIcon ? (
		<OverlayTrigger
			trigger="click"
			placement={popoverPlacement || 'top'}
			rootClose={true}
			onToggle={(show) => setShowPopover(show)}
			show={showPopover}
			overlay={popover}
		>
			<Button variant="link" className={buttonClasses.join(' ')} title="Download">
				<FontAwesomeIcon size={detailsView ? 'lg' : '1x'} icon={faCloudDownloadAlt} fixedWidth />
			</Button>
		</OverlayTrigger>
	) : null;
};

export default DownloadLinks;
