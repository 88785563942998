import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './css/Loading.module.css';

const CardLoader = () => {
	return (
		<ContentLoader height={450} speed={2} className={styles.loader} backgroundColor="#ffffff" foregroundColor="#e6e1e1">
			<circle cx="44" cy="343" r="41" />
			<rect x="110" y="325" rx="4" ry="4" width="100" height="15" />
			<rect x="110" y="350" rx="4" ry="4" width="50" height="10" />
			<rect x="0" y="40" rx="5" ry="5" width="500" height="245" />
			<rect x="0" y="0" rx="0" ry="0" width="330" height="25" />
		</ContentLoader>
	);
};

export default CardLoader;
