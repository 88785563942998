import React from 'react';
import {Dropdown} from 'react-bootstrap';

import {formatIndicatorName} from '../../util/indicators';
import styles from './css/KeyForecastRevisionChart.module.scss';
import dropdownStyles from './css/IndicatorDropdown.module.scss';

const IndicatorDropdown = (props) => {
	const {indicators, selectedIndicator, changeIndicator} = props;

	if (indicators.list.length === 1) {
		return <p className={styles.indicatorItalic}>{formatIndicatorName(indicators.map[selectedIndicator])}</p>;
	}

	return (
		<Dropdown>
			<Dropdown.Toggle className={dropdownStyles.wrapDropdown} size="sm" variant="secondary">
				{formatIndicatorName(indicators.map[selectedIndicator])}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{indicators.list
					.filter((ind) => {
						return ind.ID !== selectedIndicator;
					})
					.map((ind) => {
						return (
							<Dropdown.Item key={ind.ID} onClick={() => changeIndicator(ind.ID)}>
								{formatIndicatorName(ind)}
							</Dropdown.Item>
						);
					})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default React.memo(IndicatorDropdown);
