import {gql} from '@apollo/client';

export const MARKETS = gql`
	query Markets($MarketIDs: [Int!]!) {
		markets(marketIDs: $MarketIDs) {
			ID
			Name
		}
	}
`;

export const HEADER_MARKET_REGIONS = gql`
	query HeaderMarketRegions {
		headerMarketRegions {
			Name
			Slug
			HubID
			Children {
				ID
				Name
				Code
				Popularity
			}
		}
	}
`;

export const REGISTRATION_MARKETS = gql`
	query RegistrationMarkets {
		registrationMarkets {
			ID
			Name
		}
	}
`;

export const LATEST_MARKET_TRENDS = gql`
	query LatestMarketTrends($MarketID: Int!) {
		latestMarketTrends(marketID: $MarketID) {
			Total
			MarketTrends {
				MarketID
				MarketName
				AnalystName
				AnalystID
				FSGView
				Subtitle
				IndicatorID
				IndicatorContent
				MarketTrends
				LookingAhead
				KeyDevelopments
				Trend
				ContextDate
			}
		}
	}
`;
