import React, {useMemo} from 'react';
import propTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlayCircle} from '@fortawesome/free-regular-svg-icons';

import Link from '../analytics/Link';
import {restrictSharing, updatedContentDate} from '../../util/helpers';
import {AssetType, ContentFilters, contentTypeToID} from '../../util/contentType';
import CMSLink from '../links/CMSLink';
import ActionIcons from '../links/ActionIcons';

import styles from './css/Card.module.css';
import gradientStyles from './css/ReportCardGradients.module.scss';

const ReportCard = (props) => {
	const {item: report, tourClasses, isShowingBookmarksOnly, filters} = props;
	const location = useLocation();

	const pathname = `/report/${report.ID}/${report.Slug}`;
	const isRestrictedContent = restrictSharing(report);

	const getReportImageURL = (assets) => {
		for (let x in assets) {
			if (assets[x].AssetTypeID === 3) {
				return assets[x].DownloadURL;
			}
		}
		return null;
	};

	const showVideoIcon = useMemo(() => {
		for (const asset of report.Assets) {
			if (asset.AssetTypeID === AssetType[contentTypeToID.REPORT].VIDEO_URL) {
				return !filters || (filters && filters.filter !== ContentFilters[contentTypeToID.REPORT].value);
			}
		}
		return false;
	}, [report, filters]);

	let gradientClass = ``;
	let stackClass = ``;
	let reportImageURL = '';
	const cutoffDate = moment.utc('2021-01-25 00:00:00', 'YYYY-MM-DD HH:mm:ss');
	if (report.ReportSeries) {
		gradientClass = gradientStyles[`report-image-${report.ReportSeries.ID}`];
		stackClass = gradientStyles[`stacked--${report.ReportSeries.ID}-down`];
	} else if (moment.utc(report.FirstApprovedAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isBefore(cutoffDate)) {
		gradientClass = gradientStyles[`gradient${report.ReportType.ID}`];
		stackClass = '';
	} else {
		reportImageURL = getReportImageURL(report.Assets);
		gradientClass = '';
		stackClass = '';
	}

	const updatedDate = updatedContentDate(report);

	return (
		// bootstrap's purple-gradient is a fallback in case we add a new report type
		// and haven't added a gradient for it
		<Card
			border="light"
			className={`rounded-0 shadow ${styles.cardContainer} ${stackClass}`}
			style={{
				marginBottom: '25px',
			}}
		>
			<div
				className={`${gradientClass}`}
				style={
					reportImageURL !== ''
						? {
								backgroundImage: `url(${reportImageURL})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center center',
								backgroundRepeat: 'no-repeat',
						  }
						: {}
				}
			>
				<Card.Body className={`text-white`}>
					<Card.Title>
						<h3>
							<CMSLink item={report} color="white">
								<Link
									to={{pathname: pathname, state: {background: location, showVideo: showVideoIcon}}}
									resourceID={report.ID}
									resourceType="Report"
									className="text-white"
									description={isShowingBookmarksOnly ? 'bookmarks card' : 'card'}
								>
									{showVideoIcon ? (
										<span>
											{report.Title} <FontAwesomeIcon icon={faPlayCircle} size="lg" />
										</span>
									) : (
										report.Title
									)}
								</Link>
							</CMSLink>
						</h3>
					</Card.Title>
					<Card.Text>{report.Subtitle}</Card.Text>
					<Card.Text className="mb-0">
						<Link
							resourceID={report.Author.ContactID}
							resourceType="Analyst"
							description="analyst page"
							to={`/analyst/${report.Author.Username}`}
							rel="noopener noreferrer"
							className="text-white font-weight-bold"
						>
							{report.Author.Name}
						</Link>
					</Card.Text>
					<Card.Text className="font-weight-light mb-0">
						{moment.utc(report.FirstApprovedAt).format('D MMMM YYYY')}
					</Card.Text>
					{updatedDate ? (
						<Card.Text className="mb-0">Updated {moment.utc(updatedDate).format('D MMMM YYYY')}</Card.Text>
					) : null}
				</Card.Body>
				<div className="p-3">
					<ActionIcons
						item={report}
						isBookmarked={report.IsBookmarked}
						tourClasses={tourClasses}
						showContactAnalystButton={true}
						floatRight={true}
						noShare={isRestrictedContent}
						showContentIcons={true}
					/>
				</div>
			</div>
		</Card>
	);
};

ReportCard.propTypes = {
	report: propTypes.shape({
		Title: propTypes.string.isRequired,
		FirstApprovedAt: propTypes.string,
		PublishedAt: propTypes.string,
		Summary: propTypes.string,
	}),
};

export default React.memo(ReportCard);
