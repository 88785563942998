import {useEffect, useContext, useCallback, useMemo} from 'react';
import {useMutation} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import {RECORD_ANALYTIC, RECORD_PUBLIC_ANALYTIC} from '../mutations/analytics';
import {AnalyticsContext} from '../context/analytics';
import {AuthContext} from '../context/auth-context';
import {env} from '../util/environment';
import usePrevious from './usePrevious';

export const useRecordClick = ({resourceID, resourceType, description}) => {
	const location = useLocation();

	const [recordClick] = useMutation(RECORD_ANALYTIC, {
		variables: {
			action: 'CLICK',
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
			originPage: location.pathname,
		},
	});

	return recordClick;
};

export const useRecordClickWithVariables = ({resourceID, resourceType, description}) => {
	const location = useLocation();

	const variables = useMemo(() => {
		return {
			action: 'CLICK',
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
			originPage: location.pathname,
		};
	}, [description, location.pathname, resourceID, resourceType]);

	const [recordClick] = useMutation(RECORD_ANALYTIC);

	const modifiedRecord = useCallback(
		(updatedVariables) => {
			recordClick({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[recordClick, variables]
	);

	return modifiedRecord;
};

export const useRecordPublicClickWithVariables = ({resourceID, resourceType, description}) => {
	const location = useLocation();

	const variables = useMemo(() => {
		return {
			action: 'CLICK',
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
			originPage: location.pathname,
		};
	}, [description, location.pathname, resourceID, resourceType]);

	const [recordClick] = useMutation(RECORD_PUBLIC_ANALYTIC);

	const modifiedRecord = useCallback(
		(updatedVariables) => {
			recordClick({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[recordClick, variables]
	);

	return modifiedRecord;
};

export const useRecordPublicClick = ({resourceID, resourceType, description}) => {
	const location = useLocation();

	const [recordClick] = useMutation(RECORD_PUBLIC_ANALYTIC, {
		variables: {
			action: 'CLICK',
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
			originPage: location.pathname,
		},
	});

	return recordClick;
};

export const useRecordFilterChange = () => {
	const location = useLocation();

	const variables = useMemo(() => {
		return {
			action: 'FILTER_CHANGE',
			originPage: location.pathname,
		};
	}, [location.pathname]);

	const [record] = useMutation(RECORD_ANALYTIC);

	const modifiedRecord = useCallback(
		(updatedVariables) => {
			record({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[variables, record]
	);

	return modifiedRecord;
};

export const useRecordSortFilterChange = () => {
	const location = useLocation();

	const variables = useMemo(() => {
		return {
			action: 'SORT_FILTER_CHANGE',
			originPage: location.pathname,
		};
	}, [location.pathname]);

	const [record] = useMutation(RECORD_ANALYTIC);

	const modifiedRecord = useCallback(
		(updatedVariables) => {
			record({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[variables, record]
	);

	return modifiedRecord;
};

export const useRecordDownload = ({resourceID, resourceType, description} = {}) => {
	const location = useLocation();

	const variables = useMemo(() => {
		return {
			action: 'DOWNLOAD',
			originPage: location.pathname,
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
		};
	}, [description, location.pathname, resourceID, resourceType]);

	const [record] = useMutation(RECORD_ANALYTIC);

	const modifiedRecord = useCallback(
		(updatedVariables) => {
			record({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[variables, record]
	);

	return modifiedRecord;
};

export const useRecordPublicDownload = ({resourceID, resourceType, description} = {}) => {
	const location = useLocation();

	const variables = useMemo(() => {
		return {
			action: 'DOWNLOAD',
			originPage: location.pathname,
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
		};
	}, [description, location.pathname, resourceID, resourceType]);

	const [record] = useMutation(RECORD_PUBLIC_ANALYTIC);

	const modifiedRecord = useCallback(
		(updatedVariables) => {
			record({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[variables, record]
	);

	return modifiedRecord;
};

export const useRecordLoad = ({resourceID, resourceType, description}, deferred) => {
	const location = useLocation();
	const {isAuthenticated} = useContext(AuthContext);

	const [recordLoad] = useMutation(RECORD_ANALYTIC, {
		variables: {
			action: 'LOAD',
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
			originPage: location.pathname,
		},
	});

	useEffect(() => {
		if (!deferred) {
			if (isAuthenticated) {
				recordLoad();
			}
		}
	}, [isAuthenticated, recordLoad, deferred]);

	const modifiedRecordLoad = useCallback(
		(updatedVariables) => {
			recordLoad({
				variables: {
					...updatedVariables,
				},
			});
		},
		[recordLoad]
	);
	return modifiedRecordLoad;
};

export const useLazyRecordLoad = ({resourceID, resourceType, description} = {}) => {
	const location = useLocation();

	const variables = useMemo(() => {
		return {
			action: 'LOAD',
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
			originPage: location.pathname,
		};
	}, [description, location.pathname, resourceID, resourceType]);

	const [recordLoad] = useMutation(RECORD_ANALYTIC, {
		variables,
	});

	const modifiedRecordLoad = useCallback(
		(updatedVariables) => {
			recordLoad({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[recordLoad, variables]
	);

	return modifiedRecordLoad;
};

export const useRecordPublicLoad = ({resourceID, resourceType, description}, deferred) => {
	const location = useLocation();

	const [recordLoad] = useMutation(RECORD_PUBLIC_ANALYTIC, {
		variables: {
			action: 'LOAD',
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
			originPage: location.pathname,
		},
	});

	useEffect(() => {
		if (!deferred) {
			recordLoad();
		}
	}, [deferred, recordLoad]);

	const modifiedRecordLoad = useCallback(
		(updatedVariables) => {
			recordLoad({
				variables: {
					...updatedVariables,
				},
			});
		},
		[recordLoad]
	);
	return modifiedRecordLoad;
};

export const useLazyRecordPublicLoad = ({resourceID, resourceType, description} = {}) => {
	const location = useLocation();

	const variables = useMemo(() => {
		return {
			action: 'LOAD',
			resourceID: resourceID ? resourceID.toString() : null,
			resourceType,
			description,
			originPage: location.pathname,
		};
	}, [description, location.pathname, resourceID, resourceType]);

	const [recordLoad] = useMutation(RECORD_PUBLIC_ANALYTIC, {
		variables,
	});

	const modifiedRecordLoad = useCallback(
		(updatedVariables) => {
			recordLoad({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[recordLoad, variables]
	);

	return modifiedRecordLoad;
};

export const useRecordApplicationLoad = () => {
	const {setLocalSessionAnalyticID} = useContext(AnalyticsContext);

	const [recordLoad, {data}] = useMutation(RECORD_ANALYTIC, {
		variables: {
			action: 'APP_LOAD',
			description: 'application',
		},
	});

	useEffect(() => {
		if (data) {
			setLocalSessionAnalyticID(data.recordAnalytic.ID);
		}
		// eslint-disable-next-line
	}, [data]);

	return recordLoad;
};

export const useRecordPublicApplicationLoad = () => {
	const {setLocalSessionAnalyticID} = useContext(AnalyticsContext);

	const [recordLoad, {data}] = useMutation(RECORD_PUBLIC_ANALYTIC, {
		variables: {
			action: 'APP_LOAD',
			description: 'application',
		},
	});

	useEffect(() => {
		if (data) {
			setLocalSessionAnalyticID(data.recordPublicAnalytic.ID);
		}
		// eslint-disable-next-line
	}, [data]);

	return recordLoad;
};

export const useRecordApplicationUnload = () => {
	const {localSessionAnalyticID, setLocalSessionAnalyticID} = useContext(AnalyticsContext);

	const [recordUnload] = useMutation(RECORD_ANALYTIC);

	const doRecordUnload = useCallback(() => {
		if (localSessionAnalyticID) {
			recordUnload({
				variables: {
					action: 'APP_UNLOAD',
					description: localSessionAnalyticID,
				},
			});
			setLocalSessionAnalyticID(null);
		}
		// eslint-disable-next-line
	}, [localSessionAnalyticID]);

	return doRecordUnload;
};

export const useRecordSearch = () => {
	const location = useLocation();
	const [doRecordSearch] = useMutation(RECORD_ANALYTIC);

	return (searchTerm) => {
		doRecordSearch({
			variables: {
				action: 'SEARCH',
				description: searchTerm,
				originPage: location.pathname,
			},
		});
	};
};

export const useRecordClearSearch = () => {
	const location = useLocation();
	const [recordClearSearch] = useMutation(RECORD_ANALYTIC, {
		variables: {action: 'CLEAR_SEARCH', originPage: location.pathname},
	});

	return recordClearSearch;
};

export const useRecordPopularSearch = () => {
	const location = useLocation();
	const [recordPopularSearch] = useMutation(RECORD_ANALYTIC);

	return (popularSearch) => {
		recordPopularSearch({
			variables: {
				action: 'POPULAR_SEARCH',
				description: popularSearch,
				originPage: location.pathname,
			},
		});
	};
};

export const useRecordUpdateSettings = () => {
	const location = useLocation();
	const [recordUpdateSettings] = useMutation(RECORD_ANALYTIC);

	const variables = useMemo(() => {
		return {
			action: 'UPDATE_SETTINGS',
			originPage: location.pathname,
		};
	}, [location.pathname]);

	const modifiedRecordUpdateSettings = useCallback(
		(updatedVariables) => {
			recordUpdateSettings({
				variables: {
					...variables,
					...updatedVariables,
				},
			});
		},
		[recordUpdateSettings, variables]
	);

	return modifiedRecordUpdateSettings;
};

export const useLazyRecordFollowedMarket = ({marketID}) => {
	const location = useLocation();
	const [recordUpdateFollowedMarket] = useMutation(RECORD_ANALYTIC);

	const variables = useMemo(() => {
		return {
			originPage: location.pathname,
			description: marketID.toString(),
		};
	}, [location.pathname, marketID]);

	const modifiedRecordUpdateFollowedMarket = useCallback(
		(follow) => {
			recordUpdateFollowedMarket({
				variables: {
					action: `${follow ? '' : 'UN'}FOLLOWED_MARKET`,
					...variables,
				},
			});
		},
		[recordUpdateFollowedMarket, variables]
	);

	return modifiedRecordUpdateFollowedMarket;
};

export const useLazyRecordFollowedHub = ({hubID}) => {
	const location = useLocation();
	const [recordUpdateFollowedHub] = useMutation(RECORD_ANALYTIC);

	const variables = useMemo(() => {
		return {
			originPage: location.pathname,
			description: hubID.toString(),
		};
	}, [hubID, location.pathname]);

	const modifiedRecordUpdateFollowedHub = useCallback(
		(follow) => {
			recordUpdateFollowedHub({
				variables: {
					action: `${follow ? '' : 'UN'}FOLLOWED_HUB`,
					...variables,
				},
			});
		},
		[recordUpdateFollowedHub, variables]
	);
	return modifiedRecordUpdateFollowedHub;
};

export const useSendApplicationUnloadBeacon = () => {
	const location = useLocation();
	const {localSessionAnalyticID} = useContext(AnalyticsContext);

	const prepareAndSendUnloadBeacon = useCallback(
		(e) => {
			e.preventDefault();
			const blob = new Blob(
				[
					JSON.stringify({
						originPage: location.pathname,
						analyticID: localSessionAnalyticID,
					}),
				],
				{
					type: 'text/plain; charset=UTF-8',
				}
			);
			window.navigator.sendBeacon(`${env.REACT_APP_API_DOMAIN}/unload`, blob);
		},
		[location, localSessionAnalyticID]
	);

	const prevFunction = usePrevious(prepareAndSendUnloadBeacon);

	useEffect(() => {
		window.removeEventListener('unload', prevFunction);
		window.addEventListener('unload', prepareAndSendUnloadBeacon);
	}, [localSessionAnalyticID, prepareAndSendUnloadBeacon, prevFunction]);
};
