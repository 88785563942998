import React, {useContext} from 'react';
import {Route, Redirect, useLocation} from 'react-router-dom';

import {AuthContext} from '../context/auth-context';
import MetaData from '../components/meta/MetaTags';
import CenteredSpinner from '../components/loading/CenteredSpinner';

const ProtectedRoute = (props) => {
	const {isAuthenticated, initialAuthChecked, userData, isFreeUser, isVerified, hasMarketsSelected} = useContext(
		AuthContext
	);
	const location = useLocation();

	const metaKey = location.state && location.state.background ? location.state.background.pathname : location.pathname;

	if (!initialAuthChecked) {
		return <CenteredSpinner />;
	}

	if (!isAuthenticated) {
		return <Redirect to={location.pathname !== '/' ? `/login?referring_url=${location.pathname}` : '/'} />;
	}

	if (isFreeUser) {
		if (!isVerified) {
			if (location.pathname !== '/unverified') {
				return <Redirect to={{pathname: '/unverified', state: {from: location}}} />;
			} else {
				return <Route {...props} />;
			}
		} else if (!hasMarketsSelected) {
			if (location.pathname !== '/selectmarkets') {
				return <Redirect to={{pathname: '/selectmarkets', state: {from: location}}} />;
			} else {
				return <Route {...props} />;
			}
		}
	}

	if (userData && !userData.Entitlements.Web && location.pathname !== '/') {
		return <Redirect to={{pathname: '/', state: {from: location}}} />;
	} else if (userData && userData.Entitlements.Web) {
		return (
			<MetaData key={metaKey} {...props}>
				<Route {...props} />
			</MetaData>
		);
	} else {
		return <CenteredSpinner />;
	}
};

export default ProtectedRoute;
