import {useLocation, matchPath} from 'react-router-dom';
import {contentTypeIDToURLIdentifier} from '../util/contentType';
import {URL_TO_TYPE_MAP, TYPE_TO_URL_MAP, TYPE_ENUM} from '../util/urls';

export const useCreateShareLink = ({itemID, slug, contentTypeID}) => {
	const location = useLocation();
	const backgroundPath =
		location.state && location.state.background ? location.state.background.pathname : location.pathname;
	const params = new URLSearchParams();
	const shareLink = `${window.location.origin}/${contentTypeIDToURLIdentifier[contentTypeID]}/${itemID}/${slug}`;

	// Back navigation link will default to '/' if no other path match is made ('/' is last in the map)
	for (const path in URL_TO_TYPE_MAP) {
		const match = matchPath(backgroundPath, {path});
		if (match) {
			const type = URL_TO_TYPE_MAP[path];
			const {id, slug, marketName, username} = match.params;

			params.append('btype', type);

			if (type === TYPE_ENUM.MARKET) {
				params.append('marketName', marketName);
			} else if (type === TYPE_ENUM.HUB) {
				params.append('id', id);
				params.append('slug', slug);
			} else if (type === TYPE_ENUM.ANALYST) {
				params.append('username', username);
			}

			break;
		}
	}

	const searchParams = params.toString();
	return shareLink + (searchParams ? `?${searchParams}` : '');
};

export const useParseSharedLink = () => {
	const currentURL = new URL(window.location);
	const params = currentURL.searchParams;
	const type = params.get('btype');

	if (!(type && TYPE_TO_URL_MAP[type])) {
		return [null, null];
	}

	params.delete('btype');
	let backURL = '/';

	if (type === TYPE_ENUM.MARKET) {
		const marketName = params.get('marketName');
		if (marketName) {
			backURL = `/${type}/${marketName}`;
			params.delete('marketName');
		}
	} else if (type === TYPE_ENUM.HUB) {
		const [id, slug] = [params.get('id'), params.get('slug')];
		if (id && slug) {
			backURL = `/${type}/${id}/${slug}`;
			params.delete('id');
			params.delete('slug');
		}
	} else if (type === TYPE_ENUM.ANALYST) {
		const username = params.get('username');
		backURL = `/${type}/${username}`;
		params.delete('username');
	}

	const searchParams = params.toString();
	const cleansedPath = currentURL.pathname + (searchParams ? `?${searchParams}` : '');
	return [backURL, cleansedPath];
};
