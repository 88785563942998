import React, {useCallback, useState} from 'react';
import {trimText} from '../util/helpers';

export const MetaTagsContext = React.createContext({
	metaTags: {},
	setMetaTags: () => {},
	setDefaultMetaTags: () => {},
	// setAnalystCommentaryMetaTags: () => {},
	setReportMetaTags: () => {},
	setMultimediaMetaTags: () => {},
	setInsightBiteMetaTags: () => {},
	setHubMetaTags: () => {},
	setMarketMetaTags: () => {},
	modalOpen: {},
	setModalOpen: () => {},
});

const defaultImage = 'https://s3.amazonaws.com/images.frontierview.com/fv3/fv-fn-feature-image.png';
const DEFAULT_META_TAGS = {
	title: 'Market Intelligence Built for Business',
	description: 'Market Intelligence Built for Business',
	imageURL: defaultImage,
	published: null,
	author: null,
	url: window.location.origin,
};
const INITIAL_META_STATE = DEFAULT_META_TAGS;
const descriptionMaxLength = 120;

const MetaTagsContextProvider = (props) => {
	const [metaTagState, setMetaTagState] = useState(INITIAL_META_STATE);
	const [modalOpen, setModalOpen] = useState(false);

	const setMetaTagValues = useCallback(
		({title, description, imageURL, published, author, url}) => {
			const image = imageURL || defaultImage;
			const formattedTitle = title + ' | FrontierView';
			const formattedDescription = trimText(description, descriptionMaxLength);
			setMetaTagState({
				title: formattedTitle,
				description: formattedDescription,
				imageURL: image,
				published,
				author,
				url,
			});
		},
		[setMetaTagState]
	);

	const setDefaultMetaTags = useCallback(() => {
		setMetaTagState(DEFAULT_META_TAGS);
	}, [setMetaTagState]);

	// const setAnalystCommentaryMetaTags = useCallback(
	// 	(news) => {
	// 		if (news) {
	// 			setMetaTagValues({
	// 				title: news.Title,
	// 				description: news.Blurb,
	// 				imageURL: news.WSJImageURL,
	// 				published: news.PublishedAt,
	// 				author: news.Submitter.Name,
	// 				url: window.location.href,
	// 			});
	// 		} else {
	// 			setDefaultMetaTags();
	// 		}
	// 	},
	// 	[setMetaTagValues, setDefaultMetaTags]
	// );

	const setReportMetaTags = useCallback(
		(report) => {
			if (report) {
				const asset = report.Assets.find(function (asset) {
					return asset.AssetTypeID === 3;
				});
				setMetaTagValues({
					title: report.Title,
					description: report.Subtitle,
					imageURL: asset ? asset.DownloadURL : null,
					published: report.PublishedAt,
					author: report.Author.Name,
					url: window.location.href,
				});
			} else {
				setDefaultMetaTags();
			}
		},
		[setMetaTagValues, setDefaultMetaTags]
	);

	const setMultimediaMetaTags = useCallback(
		(multimedia) => {
			if (multimedia) {
				setMetaTagValues({
					title: multimedia.Title,
					description: multimedia.Subtitle,
					imageURL: null,
					published: multimedia.PublishedAt,
					author: multimedia.Uploader.Name,
					url: window.location.href,
				});
			} else {
				setDefaultMetaTags();
			}
		},
		[setMetaTagValues, setDefaultMetaTags]
	);

	const setInsightBiteMetaTags = useCallback(
		(insightBite) => {
			if (insightBite) {
				const asset = insightBite.Assets.find(function (asset) {
					return asset.AssetTypeID === 1;
				});
				setMetaTagValues({
					title: insightBite.Title,
					description: insightBite.Analysis,
					imageURL: asset ? asset.DownloadURL : null,
					published: insightBite.PublishedAt,
					author: insightBite.Author.Name,
					url: window.location.href,
				});
			} else {
				setDefaultMetaTags();
			}
		},
		[setMetaTagValues, setDefaultMetaTags]
	);

	const setHubMetaTags = useCallback(
		(hub) => {
			if (hub) {
				setMetaTagValues({
					title: hub.Title,
					description: hub.Description,
					url: window.location.href,
				});
			} else {
				setDefaultMetaTags();
			}
		},
		[setMetaTagValues, setDefaultMetaTags]
	);

	const setMarketMetaTags = useCallback(
		(market) => {
			if (market) {
				setMetaTagValues({
					title: market.Name,
					url: window.location.href,
				});
			} else {
				setDefaultMetaTags();
			}
		},
		[setMetaTagValues, setDefaultMetaTags]
	);

	const setLiveEventMetaTags = useCallback(
		(liveEvent) => {
			if (liveEvent) {
				setMetaTagValues({
					title: liveEvent.Title,
					description: liveEvent.Analysis,
					imageURL: null,
					published: liveEvent.PublishedAt,
					author: liveEvent.Submitter.Name,
					url: window.location.href,
				});
			} else {
				setDefaultMetaTags();
			}
		},
		[setMetaTagValues, setDefaultMetaTags]
	);

	return (
		<MetaTagsContext.Provider
			value={{
				metaTags: metaTagState,
				setMetaTags: setMetaTagValues,
				setDefaultMetaTags,
				// setAnalystCommentaryMetaTags,
				setReportMetaTags,
				setMultimediaMetaTags,
				setInsightBiteMetaTags,
				setHubMetaTags,
				setMarketMetaTags,
				setLiveEventMetaTags,
				modalOpen,
				setModalOpen,
			}}
		>
			{props.children}
		</MetaTagsContext.Provider>
	);
};

export default MetaTagsContextProvider;
