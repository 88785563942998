import {gql} from '@apollo/client';

export const REDIRECT = gql`
	subscription {
		redirect {
			To
		}
	}
`;

export const PROFILE_UPDATE = gql`
	subscription profileUpdate {
		profileUpdate {
			User {
				ID
				FirstName
				LastName
				IsAnonymous
				Timezone
				ResearchAlertFrequency
				#NewsAlertFrequency
				FollowedMarkets
			}
		}
	}
`;
