export const UserRoles = {
	GRANDEST_POOBAH: 4,
	GRAND_POOBAH: 1,
	ADMIN: 5,
	MANAGER: 2,
	CORPORATE_ACCOUNTS_PAYABLE: 3,
	DEVELOPER: 6,
	NOTIFICATIONS: 7,
	SALESFORCE_USER: 8,
	AQBS: 9,
	DASHBOARD_APPROVER: 10,
	FORECAST: 11,
	ALPHA_TESTER: 12,
	MARKETING: 13,
	APPROVER: 14,
};

export const DataAdminRole = {
	required: [UserRoles.ADMIN],
	override: [UserRoles.GRANDEST_POOBAH, UserRoles.GRAND_POOBAH],
};
export const ForecastAdminRole = {
	required: [UserRoles.FORECAST],
	override: [UserRoles.GRANDEST_POOBAH, UserRoles.GRAND_POOBAH],
};
export const AlphaTesterRole = {
	required: [UserRoles.ALPHA_TESTER],
	override: [UserRoles.GRANDEST_POOBAH, UserRoles.GRAND_POOBAH],
};
export const ManagerRole = {
	required: [UserRoles.MANAGER, UserRoles.ADMIN],
	override: [UserRoles.GRANDEST_POOBAH, UserRoles.GRAND_POOBAH],
};

export const ApproverRole = {
	required: [UserRoles.APPROVER],
	override: [UserRoles.GRANDEST_POOBAH, UserRoles.GRAND_POOBAH],
};

const hasRole = (role, userRoles) => {
	for (let userRole of userRoles) {
		userRole = parseInt(userRole, 10);
		if (role.override.includes(userRole) || role.required.includes(userRole)) {
			return true;
		}
	}
	return false;
};

export const hasFVRole = (role, userData) => {
	const userRoles = userData && userData.Entitlements && userData.Entitlements.Roles ? userData.Entitlements.Roles : [];
	return hasRole(role, userRoles);
};

export const hasCMSRole = (role, userData) => {
	const userRoles =
		userData && userData.Entitlements && userData.Entitlements.CMSRoles ? userData.Entitlements.CMSRoles : [];
	return hasRole(role, userRoles);
};
