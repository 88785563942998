import React, {Fragment, useState, useContext, useEffect} from 'react';
import {useMutation} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestion} from '@fortawesome/free-solid-svg-icons';

import {useRecordLoad, useRecordClick} from '../../hooks/analytics';

import ScrollingContent from '../../components/search/ScrollingContent';
import NewAndNoteworthies from '../../components/newAndNoteworthies/NewAndNoteworthies';
import HomeTour from '../../components/tour/HomeTour';
import {AuthContext} from '../../context/auth-context';
import {MetaTagsContext} from '../../context/meta';
import {TOUR_VIEWED} from '../../mutations/auth';
import {INITIAL_AUTH_CHECK} from '../../queries/auth';

import styles from './css/Home.module.scss';

const Home = (props) => {
	const {setDefaultMetaTags, modalOpen} = useContext(MetaTagsContext);
	const {showFavorites} = props;
	useRecordLoad({description: showFavorites ? 'favorites' : 'home'});
	const {userData} = useContext(AuthContext);
	const [isTourOpen, setIsTourOpen] = useState(false);

	const recordOpenTourClick = useRecordClick({description: 'open tour'});

	const [tourViewed] = useMutation(TOUR_VIEWED, {refetchQueries: [{query: INITIAL_AUTH_CHECK}]});

	const handleLoad = () => {
		if (!userData.FV3WebTourViewedAt) {
			setTimeout(() => {
				setIsTourOpen(true);
			}, 300);
		}
	};

	useEffect(() => {
		if (!modalOpen) {
			setDefaultMetaTags();
		}
	}, [modalOpen, setDefaultMetaTags]);

	const initialFilters = showFavorites ? [{key: 'bookmarked', value: '1'}] : null;
	return (
		<Fragment>
			<HomeTour
				isOpen={isTourOpen}
				onRequestClose={() => {
					if (!userData.FV3WebTourViewedAt) {
						tourViewed();
					}
					setIsTourOpen(false);
				}}
			/>
			<div className={`${styles.fullWidth} ${styles.newAndNoteworthiesBanner}`}>
				<NewAndNoteworthies />
			</div>
			<div
				onClick={() => {
					setIsTourOpen(true);
					recordOpenTourClick();
				}}
				className={`${styles.seeTour} cursor-pointer`}
			>
				<FontAwesomeIcon className="text-white" icon={faQuestion} />
			</div>
			<ScrollingContent
				colSizes={{xl: 4, md: 6}}
				searchQS={!showFavorites}
				isFixedSearch={true}
				showPopularSearches={true}
				initialFilters={initialFilters}
				includeAutosuggestions={true}
				showMarketAutosuggestions={true}
				showMarket={true}
				onLoad={handleLoad}
			/>
		</Fragment>
	);
};

export default Home;
