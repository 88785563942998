import React from 'react';
import {Card, Row} from 'react-bootstrap';

import styles from './css/Card.module.css';

const FNItemCard = (props) => {
	const {item} = props;
	// const [imageLoaded, setImageLoaded] = useState(!item.image.normal.url);

	// const pathname = `/news/${item.ID}/${item.Slug}`;

	// useEffect(() => {
	// 	if (item.image.normal.url) {
	// 		const img = new Image();
	// 		img.src = item.image.normal.url;
	// 		img.onload = () => setImageLoaded(true);
	// 	}
	// 	// eslint-disable-next-line
	// }, []);

	return (
		<Card border="light" className={'rounded-0 shadow ' + styles.cardContainer} style={{marginBottom: '25px'}}>
			{/*height set below to fix IE 11 bug*/}
			<Card.Body style={{height: '100%'}}>
				<Card.Title>
					<h4>{item.title}</h4>
				</Card.Title>
				{/* {item.image ? (
					<Link
						resourceID={item.id}
						resourceType="NewsArticle"
						to={{pathname: pathname, state: {background: location}}}
					>
						<div className={styles.newsImage}>
							{imageLoaded ? (
								<BootstrapImage src={item.image.normal.url} fluid />
							) : (
								<div className={styles.newsImageLoader}></div>
							)}
						</div>
					</Link>
				) : null} */}
				<Card.Text className="mt-3">{item.summary ?? 'Summary Here'}</Card.Text>
				<Row>
					{/* <UserBadge
						user={item.Submitter}
						contentDate={item.SubmittedAt}
						updatedContentDate={updatedContentDate(news)}
					/> */}
				</Row>
			</Card.Body>
		</Card>
	);
};

export default React.memo(FNItemCard);
