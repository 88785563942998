import React, {Fragment, useMemo, useContext} from 'react';
import MetaTags from 'react-meta-tags';

import {MetaTagsContext} from '../../context/meta';

const MetaData = (props) => {
	const {metaTags} = useContext(MetaTagsContext);

	const metaTagsToRender = useMemo(() => {
		return (
			<MetaTags>
				<title>{metaTags.title}</title>
				<meta property="og:site_name" content="FrontierView" />
				<meta property="og:title" content={metaTags.title} />
				<meta name="description" content={metaTags.description} />
				<meta property="og:description" content={metaTags.description} />
				<meta property="og:image" content={metaTags.imageURL} />
				<meta property="article:published_time" content={metaTags.published} />
				<meta property="article:author" content={metaTags.author} />
				<meta property="og:url" content={metaTags.url} />
				<meta name="twitter:title" content={metaTags.title} />
				<meta name="twitter:description" content={metaTags.description} />
				<meta name="twitter:image" content={metaTags.imageURL} />
			</MetaTags>
		);
	}, [metaTags]);

	return (
		<Fragment>
			{metaTagsToRender}
			{props.children}
		</Fragment>
	);
};

export default MetaData;
