import React, {useState, useEffect, useContext, Fragment} from 'react';
import {Row, Col, Card, Image} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {ForgotPassword} from 'commonsui';
import {AuthContext} from '../../context/auth-context';
import {MetaTagsContext} from '../../context/meta';

import {FORGOT_PASSWORD} from '../../mutations/auth';

import styles from '../register/css/Register.module.scss';

export default function ForgotPasswordContainer(props) {
	const {setAuthModalToShow} = useContext(AuthContext);
	const {setDefaultMetaTags} = useContext(MetaTagsContext);
	const [submit, {data, error}] = useMutation(FORGOT_PASSWORD);
	const [formActions, setFormActions] = useState(null);
	const history = useHistory();

	const handleSubmit = ({email}, actions) => {
		submit({variables: {email}});
		setFormActions(actions);
	};

	useEffect(() => {
		setDefaultMetaTags();
	}, [setDefaultMetaTags]);

	useEffect(() => {
		if (data) {
			formActions.setSubmitting(false);
		}
	}, [data, formActions]);

	return (
		<Fragment>
			<Row>
				<Col
					sm={!props.isModal ? {span: 8, offset: 2} : null}
					md={!props.isModal ? {span: 6, offset: 3} : null}
					lg={!props.isModal ? {span: 4, offset: 4} : null}
				>
					<Card className={props.isModal ? 'shadow-none' : null}>
						<div className={styles.header}>
							<Image src="https://s3.amazonaws.com/images.frontierview.com/fv3/fv-fn-logo.png" alt="FrontierView" />
						</div>
						<Card.Body>
							<ForgotPassword
								onSubmit={handleSubmit}
								data={data}
								error={error}
								onClickLogin={() => {
									if (props.isModal) {
										setAuthModalToShow({modal: 'login'});
									} else {
										history.push('/login');
									}
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
}
