import {gql} from '@apollo/client';

export const HUB = gql`
	query Hub($id: Int!) {
		hub(id: $id) {
			ID
			Title
			Description
			Markets {
				ID
			}
			Slug
			Keywords {
				ID
				Value
			}
			CMSURL
			TypeID
			OAItems {
				firstRun
				headline
				id
				ID
				idFull
				image {
					caption
					normal {
						height
						url
						width
					}
					thumbnail {
						height
						url
						width
					}
				}
				mainCountry
				readTime
				summaryMini
				type
			}
			FNItems {
				id
				ID
				created_at
				updated_at
				external_id
				locality
				session
				chamber
				title
				summary
				bill_type
				last_scraped_at
				version
				max_version_exported
				entity_type
				entity_id
				entity_version
				score
				topic_id
				topic_version
				active
				additional_scores
			}
			FeaturedReports {
				ID
			}
			FeaturedMultimedia {
				ID
			}
			#FeaturedNews {
			#	ID
			#}
			FeaturedInsightBites {
				ID
			}
			FeaturedLiveEvents {
				ID
			}
			FeaturedReportSeries {
				ID
			}
		}
	}
`;

export const HUBS = gql`
	query Hubs {
		hubs {
			ID
			Title
			Description
			Slug
			TypeID
			Markets {
				ID
			}
			Keywords {
				ID
				Value
			}
			CMSURL
		}
	}
`;
