import {gql} from '@apollo/client';

export const DEFAULT_CONTENT_SEARCH = gql`
	query Search(
		$numResults: Int!
		$pageNum: Int = 0
		$searchText: String
		$marketIDs: [Int!]
		$keywordIDs: [Int!]
		$reportTypeIDs: [Int!]
		$multimediaTypeIDs: [Int!]
		$insightBiteTypeIDs: [Int!]
		$analystContactIDs: [String!]
		$bookmarked: Boolean
		$hubID: Int
		$upcomingOnly: Boolean
		$eventDateTimeframe: String
		$reportIDs: [Int!]
		$multimediaIDs: [Int!]
		$liveEventIDs: [Int!]
		$liveEventTypeIDs: [Int!] = [1, 2]
		$insightBiteIDs: [Int!]
		#$newsIDs: [Int!]
		$indices: SearchIndices = {
			reports: true
			multimedia: true
			#news: true
			insightBites: true
			hubs: true
			liveEvents: false
		}
		$sortBy: String
		$onlyReportVideos: Boolean
		$onlyLiveEventVideos: Boolean
		$selectedIndexFilter: String
		$featured: Boolean
	) {
		search(
			params: {
				indices: $indices
				filters: {
					all: {
						marketIDs: $marketIDs
						analystContactIDs: $analystContactIDs
						bookmarked: $bookmarked
						keywordIDs: $keywordIDs
						hubID: $hubID
						featured: $featured
					}
					reports: {typeIDs: $reportTypeIDs, ids: $reportIDs, onlyReportVideos: $onlyReportVideos}
					multimedia: {ids: $multimediaIDs, typeIDs: $multimediaTypeIDs}
					#news: {ids: $newsIDs}
					insightBites: {ids: $insightBiteIDs, typeIDs: $insightBiteTypeIDs}
					liveEvents: {
						ids: $liveEventIDs
						upcomingOnly: $upcomingOnly
						eventDateTimeframe: $eventDateTimeframe
						hasVideoURL: $onlyLiveEventVideos
						liveEventTypeIDs: $liveEventTypeIDs
					}
				}
				searchText: $searchText
				combineResults: true
				includeSuggestion: false
				page: $pageNum
				perPage: $numResults
				sort: $sortBy
				selectedIndexFilter: $selectedIndexFilter
			}
		) {
			Total
			Results {
				... on Report {
					ID
					Title
					Slug
					Subtitle
					FirstApprovedAt
					PublishedAt
					IsBookmarked
					Assets {
						Extension
						ReportID
						ID
						AssetTypeID
						DownloadURL
					}
					Markets {
						ID
						Name
					}
					Author {
						Name
						ContactID
						Username
						AvatarURL
					}
					ContentTypeID
					ReportType {
						ID
					}
					ReportSeries {
						ID
						Name
					}
					Accounts {
						ID
						Name
					}
					CMSURL
				}
				... on Multimedia {
					ID
					CreatedAt
					FirstApprovedAt
					PublishedAt
					Title
					Slug
					Subtitle
					ContentTypeID
					IsBookmarked
					Assets {
						MultimediaID
						Content
						Duration
						ID
						AssetTypeID
						FileSize
					}
					Uploader {
						ContactID
						Name
						Username
						AvatarURL
					}
					CMSURL
				}
				#... on NewsArticle {
				#	ID
				#	Title
				#	Slug
				#	Blurb
				#	WSJImageURL
				#	SubmittedAt
				#	PublishedAt
				#	Submitter {
				#		Name
				#		AvatarURL
				#		Username
				#		ContactID
				#	}
				#	URL
				#	ContentTypeID
				#	IsBookmarked
				#	Source {
				#		Name
				#	}
				#	CMSURL
				#}
				... on InsightBite {
					ID
					Title
					Slug
					Subtitle
					CreatedAt
					FirstApprovedAt
					PublishedAt
					AssetItems {
						AssetNumber
						Assets {
							InsightBiteID
							ID
							AssetTypeID
							DownloadURL
							Content
						}
						Chart {
							AssetNumber
							GoogleSheetID
							InsightBiteID
							Type {
								ID
								Name
							}
							XAxisTitle
							YAxisTitle
							ZAxisTitle
							Title
							Subtitle
							Source
							FrequencyID
							StartDate
							EndDate
							Datapoints {
								Value
								IsForecast
								SourceID
								JobIndicatorMarket
								ValueDate
							}
						}
						Indicators {
							ID
							Name
							Units
						}
						Type {
							ID
							Name
						}
						TypeID
					}
					Assets {
						InsightBiteID
						ID
						AssetTypeID
						DownloadURL
						Content
					}
					TypeID
					IsBookmarked
					StartDate
					EndDate
					FrequencyID
					Chart {
						GoogleSheetID
						Type {
							ID
							Name
						}
						XAxisTitle
						YAxisTitle
						Title
						Subtitle
						Source
					}
					Indicators {
						ID
						Name
						Units
					}
					Markets {
						ID
						Name
					}
					Datapoints {
						Value
						IsForecast
						SourceID
						JobIndicatorMarket
						ValueDate
					}
					Author {
						Name
						ContactID
						AvatarURL
						Username
					}
					ContentTypeID
					CMSURL
				}
				... on Hub {
					ID
					Title
					Description
					Markets {
						ID
						Name
					}
					FirstApprovedAt
					Slug
					CMSURL
				}
				... on LiveEvent {
					ID
					Title
					Description
					CreatedAt
					PublishedAt
					LiveEventType {
						ID
						Name
					}
					Participants {
						ParticipantType
						User {
							ContactID
							Name
							Username
						}
					}
					Assets {
						LiveEventID
						Content
						ID
						AssetTypeID
						DownloadURL
					}
					EventDate
					Slug
					CMSURL
					VideoURL
					ContentTypeID
					IsBookmarked
				}
			}
			PopularSearches
		}
	}
`;

// export const NEWS_DETAILS = gql`
// 	query NewsDetails($id: String!) {
// 		newsDetails(id: $id) {
// 			ID
// 			Title
// 			Slug
// 			Blurb
// 			WSJImageURL
// 			SubmittedAt
// 			PublishedAt
// 			URL
// 			ContentTypeID
// 			IsBookmarked
// 			Submitter {
// 				Name
// 				AvatarURL
// 				Username
// 				ContactID
// 				Linkedin
// 			}
// 			RelatedReports {
// 				ID
// 				Title
// 				Slug
// 				ContentTypeID
// 			}
// 			RelatedMultimedia {
// 				ID
// 				Title
// 				Slug
// 				ContentTypeID
// 			}
// 			RelatedNews {
// 				ID
// 				Title
// 				Slug
// 				ContentTypeID
// 			}
// 			RelatedInsightBites {
// 				ID
// 				Title
// 				Slug
// 				ContentTypeID
// 			}
// 			RelatedLiveEvents {
// 				ID
// 				Title
// 				Slug
// 				ContentTypeID
// 			}
// 			Source {
// 				Name
// 			}
// 			CMSURL
// 		}
// 	}
// `;

export const SEARCH_AUTOCOMPLETE = gql`
	query SearchAutocomplete(
		$searchText: String!
		$marketIDs: [Int!]
		$keywordIDs: [Int!]
		$analystContactIDs: [String!]
		$hubID: Int
		$liveEventTypeIDs: [Int!] = [1, 2]
		$indices: SearchIndices = {
			reports: true
			multimedia: true
			#news: true
			insightBites: true
			hubs: true
			liveEvents: true
		}
	) {
		searchAutocomplete(
			params: {
				indices: $indices
				filters: {
					all: {marketIDs: $marketIDs, analystContactIDs: $analystContactIDs, keywordIDs: $keywordIDs, hubID: $hubID}
					liveEvents: {liveEventTypeIDs: $liveEventTypeIDs}
				}
				searchText: $searchText
				combineResults: true
				includeSuggestion: true
				page: 0
				perPage: 5
			}
		) {
			ID
			Title
			Analyst {
				ID
				Name
				Username
			}
			ContentTypeID
			Path
		}
	}
`;

export const REPORT_DETAILS = gql`
	query ReportDetails($id: String!) {
		reportDetails(id: $id) {
			ID
			Title
			Slug
			Subtitle
			Summary
			FirstApprovedAt
			PublishedAt
			IsBookmarked
			Markets {
				ID
				Name
			}
			Author {
				Name
				AvatarURL
				Username
				ContactID
				Linkedin
			}
			Assets {
				Extension
				ReportID
				ID
				AssetTypeID
				DownloadURL
				Content
			}
			ReportSlides {
				ImageURL
			}
			RelatedReports {
				ID
				Title
				Slug
				ContentTypeID
			}
			RelatedMultimedia {
				ID
				Title
				Slug
				ContentTypeID
			}
			#RelatedNews {
			#	ID
			#	Title
			#	Slug
			#	ContentTypeID
			#}
			RelatedInsightBites {
				ID
				Title
				Slug
				ContentTypeID
			}
			RelatedLiveEvents {
				ID
				Title
				Slug
				ContentTypeID
			}
			ReportSeries {
				ID
				Name
			}
			ReportSeriesDate
			RelatedReportsInSeries {
				ID
				Title
				ReportSeriesDate
				Slug
				ContentTypeID
			}
			Accounts {
				ID
				Name
			}
			ReportType {
				ID
				Name
			}
			ContentTypeID
			CMSURL
		}
	}
`;

export const MULTIMEDIA_DETAILS = gql`
	query MultimediaDetails($id: String!) {
		multimediaDetails(id: $id) {
			ID
			CreatedAt
			Title
			Slug
			Subtitle
			FirstApprovedAt
			PublishedAt
			IsBookmarked
			Markets {
				ID
				Name
			}
			Uploader {
				ContactID
				Name
				AvatarURL
				Username
				Linkedin
			}
			Participants {
				User {
					ContactID
					Name
					AvatarURL
					Username
					Linkedin
				}
				ParticipantType
			}
			Assets {
				MultimediaID
				Content
				Duration
				ID
				AssetTypeID
				FileSize
			}
			RelatedReports {
				ID
				Title
				Slug
				ContentTypeID
			}
			RelatedMultimedia {
				ID
				Title
				Slug
				ContentTypeID
			}
			#RelatedNews {
			#	ID
			#	Title
			#	Slug
			#	ContentTypeID
			#}
			RelatedInsightBites {
				ID
				Title
				Slug
				ContentTypeID
			}
			RelatedLiveEvents {
				ID
				Title
				Slug
				ContentTypeID
			}
			MultimediaType {
				Name
			}
			Podcast {
				ID
				URL
				Duration
			}
			Video {
				ID
				URL
			}
			Webinar {
				ID
				URL
			}
			ContentTypeID
			CMSURL
		}
	}
`;

export const INSIGHT_BITE_DETAILS = gql`
	query InsightBiteDetails($id: String!) {
		insightBiteDetails(id: $id) {
			ID
			Title
			Subtitle
			Slug
			Analysis
			TypeID
			StartDate
			EndDate
			FrequencyID
			AssetItems {
				AssetNumber
				Assets {
					InsightBiteID
					ID
					AssetTypeID
					DownloadURL
					Content
				}
				Chart {
					AssetNumber
					GoogleSheetID
					InsightBiteID
					Type {
						ID
						Name
					}
					XAxisTitle
					YAxisTitle
					ZAxisTitle
					Title
					Subtitle
					Source
					FrequencyID
					StartDate
					EndDate
					Datapoints {
						Value
						IsForecast
						SourceID
						JobIndicatorMarket
						ValueDate
					}
				}
				Indicators {
					ID
					Name
					Units
				}
				Type {
					ID
					Name
				}
				TypeID
			}
			Chart {
				GoogleSheetID
				Type {
					ID
					Name
				}
				XAxisTitle
				YAxisTitle
				Title
				Subtitle
				Source
			}
			IsBookmarked
			CreatedAt
			FirstApprovedAt
			PublishedAt
			Indicators {
				ID
				Name
				Units
			}
			Datapoints {
				Value
				IsForecast
				SourceID
				JobIndicatorMarket
				ValueDate
			}
			Markets {
				ID
				Name
			}
			Author {
				Name
				AvatarURL
				Username
				ContactID
				Linkedin
			}
			Assets {
				InsightBiteID
				ID
				AssetTypeID
				DownloadURL
				Content
			}
			RelatedReports {
				ID
				Title
				Slug
				ContentTypeID
			}
			RelatedMultimedia {
				ID
				Title
				Slug
				ContentTypeID
			}
			#RelatedNews {
			#	ID
			#	Title
			#	Slug
			#	ContentTypeID
			#}
			RelatedInsightBites {
				ID
				Title
				Slug
				ContentTypeID
			}
			RelatedLiveEvents {
				ID
				Title
				Slug
				ContentTypeID
			}
			ContentTypeID
			CMSURL
		}
	}
`;

export const RANDOM_INSIGHT_BITE_DETAILS = gql`
	query RandomInsightBiteDetails {
		randomInsightBiteDetails {
			ID
			Title
			Subtitle
			Slug
			Analysis
			TypeID
			StartDate
			EndDate
			FrequencyID
			Chart {
				GoogleSheetID
				Type {
					ID
					Name
				}
				XAxisTitle
				YAxisTitle
				Title
				Source
			}
			IsBookmarked
			CreatedAt
			PublishedAt
			Indicators {
				ID
				Name
				Units
			}
			Datapoints {
				Value
				IsForecast
				SourceID
				JobIndicatorMarket
				ValueDate
			}
			Markets {
				ID
				Name
			}
			Author {
				Name
				AvatarURL
				Username
				ContactID
				Linkedin
			}
			Assets {
				InsightBiteID
				ID
				AssetTypeID
				DownloadURL
				Content
			}
			RelatedReports {
				ID
				Title
				Slug
				ContentTypeID
			}
			RelatedMultimedia {
				ID
				Title
				Slug
				ContentTypeID
			}
			#RelatedNews {
			#	ID
			#	Title
			#	Slug
			#	ContentTypeID
			#}
			RelatedInsightBites {
				ID
				Title
				Slug
				ContentTypeID
			}
			RelatedLiveEvents {
				ID
				Title
				Slug
				ContentTypeID
			}
			ContentTypeID
			CMSURL
		}
	}
`;

export const CONTENT_META = gql`
	query ContentMeta($resourceID: Int!, $contentTypeID: Int!) {
		contentMeta(resourceID: $resourceID, contentTypeID: $contentTypeID) {
			Title
			Description
			Author
			ImageURL
			PublishedAt
		}
	}
`;

export const PUBLIC_REPORT_DETAILS = gql`
	query PublicReportDetails($id: String!) {
		publicReportDetails(id: $id) {
			ID
			Slug
			Title
			Subtitle
			Summary
			FirstApprovedAt
			PublishedAt
			Markets {
				ID
				Name
			}
			Author {
				Name
				AvatarURL
				Username
				ContactID
				Linkedin
			}
			Assets {
				Extension
				ReportID
				ID
				AssetTypeID
				DownloadURL
				Content
			}
			ReportSlides {
				ImageURL
			}
			ReportType {
				ID
				Name
			}
			ContentTypeID
		}
	}
`;

export const PUBLIC_MULTIMEDIA_DETAILS = gql`
	query PublicMultimediaDetails($id: String!) {
		publicMultimediaDetails(id: $id) {
			ID
			Title
			Slug
			Subtitle
			FirstApprovedAt
			PublishedAt
			Markets {
				ID
				Name
			}
			Uploader {
				Name
				AvatarURL
				Username
				ContactID
				Linkedin
			}
			Participants {
				User {
					Name
					AvatarURL
					Username
					Linkedin
				}
				ParticipantType
			}
			Assets {
				MultimediaID
				Content
				Duration
				ID
				AssetTypeID
				FileSize
			}
			MultimediaType {
				Name
			}
			Podcast {
				ID
				URL
				Duration
			}
			Video {
				ID
				URL
			}
			Webinar {
				ID
				URL
			}
			ContentTypeID
		}
	}
`;

// export const PUBLIC_NEWS_DETAILS = gql`
// 	query PublicNewsDetails($id: String!) {
// 		publicNewsDetails(id: $id) {
// 			ID
// 			Title
// 			Slug
// 			Blurb
// 			WSJImageURL
// 			SubmittedAt
// 			PublishedAt
// 			URL
// 			ContentTypeID
// 			Submitter {
// 				Name
// 				AvatarURL
// 				Username
// 				ContactID
// 				Linkedin
// 			}
// 			Source {
// 				Name
// 			}
// 		}
// 	}
// `;

export const PUBLIC_INSIGHT_BITE_DETAILS = gql`
	query PublicInsightBiteDetails($id: String!) {
		publicInsightBiteDetails(id: $id) {
			ID
			Title
			Subtitle
			Slug
			Analysis
			TypeID
			StartDate
			EndDate
			FrequencyID
			AssetItems {
				AssetNumber
				Assets {
					InsightBiteID
					ID
					AssetTypeID
					DownloadURL
					Content
				}
				Chart {
					AssetNumber
					GoogleSheetID
					InsightBiteID
					Type {
						ID
						Name
					}
					XAxisTitle
					YAxisTitle
					ZAxisTitle
					Title
					Subtitle
					Source
					FrequencyID
					StartDate
					EndDate
					Datapoints {
						Value
						IsForecast
						SourceID
						JobIndicatorMarket
						ValueDate
					}
				}
				Indicators {
					ID
					Name
					Units
				}
				Type {
					ID
					Name
				}
				TypeID
			}
			Chart {
				GoogleSheetID
				Type {
					ID
					Name
				}
				XAxisTitle
				YAxisTitle
				Title
				Subtitle
				Source
			}
			CreatedAt
			FirstApprovedAt
			PublishedAt
			Indicators {
				ID
				Name
				Units
			}
			Markets {
				ID
				Name
			}
			Author {
				Name
				AvatarURL
				Username
				ContactID
				Linkedin
			}
			Assets {
				InsightBiteID
				ID
				AssetTypeID
				DownloadURL
				Content
			}
			ContentTypeID
			Datapoints {
				Value
				IsForecast
				SourceID
				JobIndicatorMarket
				ValueDate
			}
		}
	}
`;

export const PUBLIC_RANDOM_INSIGHT_BITE_DETAILS = gql`
	query PublicRandomInsightBiteDetails {
		publicRandomInsightBiteDetails {
			ID
			Title
			Subtitle
			Slug
			Analysis
			TypeID
			StartDate
			EndDate
			FrequencyID
			AssetItems {
				AssetNumber
				Assets {
					InsightBiteID
					ID
					AssetTypeID
					DownloadURL
					Content
				}
				Chart {
					AssetNumber
					GoogleSheetID
					InsightBiteID
					Type {
						ID
						Name
					}
					XAxisTitle
					YAxisTitle
					ZAxisTitle
					Title
					Subtitle
					Source
					FrequencyID
					StartDate
					EndDate
				}
				Indicators {
					ID
					Name
					Units
				}
				Type {
					ID
					Name
				}
				TypeID
			}
			Chart {
				GoogleSheetID
				Type {
					ID
					Name
				}
				XAxisTitle
				YAxisTitle
				Title
				Source
			}
			CreatedAt
			PublishedAt
			Indicators {
				ID
				Name
				Units
			}
			Markets {
				ID
				Name
			}
			Author {
				Name
				AvatarURL
				Username
				ContactID
				Linkedin
			}
			Assets {
				InsightBiteID
				ID
				AssetTypeID
				DownloadURL
				Content
			}
			ContentTypeID
			Datapoints {
				Value
				IsForecast
				SourceID
				JobIndicatorMarket
				ValueDate
			}
		}
	}
`;
