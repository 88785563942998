import React from 'react';
import {Image, Media} from 'react-bootstrap';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import Link from '../analytics/Link';

import styles from './css/UserBadge.module.scss';

const UserBadge = (props) => {
	const {user, contentDate, updatedContentDate, disableLinks} = props;
	const onlyName = !contentDate && !updatedContentDate;
	return (
		// Width set here to fix IE 11 issue
		<Media style={{width: '100%'}}>
			<Image alt="User Avatar" src={user.AvatarURL} className="mx-3" style={{width: '4rem'}} roundedCircle />
			<Media.Body className={`my-auto px-0 ${onlyName ? 'd-flex align-items-center mb-0' : null}`}>
				<h5 className={styles.analystName}>
					{disableLinks ? (
						<span>{user.Name}</span>
					) : (
						<Link
							resourceID={user.ContactID}
							resourceType="Analyst"
							description="analyst page"
							to={`/analyst/${user.Username}`}
							rel="noopener noreferrer"
						>
							{user.Name}
						</Link>
					)}
					{user.Linkedin ? (
						<a
							target="_blank"
							rel="noopener noreferrer"
							className="ml-2"
							href={`https://linkedin.com/in/${user.Linkedin}`}
						>
							<FontAwesomeIcon color="#0077b5" icon={faLinkedin} size="1x" />
						</a>
					) : null}
				</h5>
				{contentDate ? <small className="text-muted">{moment.utc(contentDate).format('D MMMM YYYY')}</small> : null}
				<br />
				{updatedContentDate ? (
					<small className="text-muted">
						<span className="text-dark">Updated</span> {moment.utc(updatedContentDate).format('D MMMM YYYY')}
					</small>
				) : null}
			</Media.Body>
		</Media>
	);
};

export default React.memo(UserBadge);
