import React, {useMemo} from 'react';
import {Card} from 'react-bootstrap';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar, faPlayCircle} from '@fortawesome/free-regular-svg-icons';
import {useLocation} from 'react-router-dom';

import Link from '../analytics/Link';
import CMSLink from '../links/CMSLink';
import useLiveEventStatus from '../../hooks/useLiveEventStatus';
import ActionIcons from '../links/ActionIcons';

import styles from './css/Card.module.css';
import {trimText} from '../../util/helpers';

export default function LiveEventCard(props) {
	const {item: liveEvent, tourClasses, hubType} = props;
	const pathname = `/liveEvent/${liveEvent.ID}/${liveEvent.Slug}`;

	const location = useLocation();

	const {isPast, hasVideo, hasPDF, showAsReport} = useLiveEventStatus(liveEvent, hubType);

	const imageURL = useMemo(() => {
		if (!liveEvent.Assets) {
			return null;
		}
		const imageAsset = liveEvent.Assets.find((asset) => asset.AssetTypeID === 3);
		return imageAsset ? imageAsset.DownloadURL : null;
	}, [liveEvent]);

	const parentheticalText = useMemo(() => {
		if (isPast && hasVideo && hasPDF) {
			return 'Event Recording and Deck';
		} else if (isPast && hasVideo) {
			return 'Event Recording';
		} else if (isPast && hasPDF) {
			return 'Event Deck';
		} else {
			return liveEvent.LiveEventType.Name;
		}
	}, [isPast, hasVideo, liveEvent, hasPDF]);

	return (
		<Card border="light" className={'rounded-0 shadow ' + styles.cardContainer} style={{marginBottom: '25px'}}>
			<div
				style={
					imageURL
						? {
								backgroundImage: `url(${imageURL})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center center',
								backgroundRepeat: 'no-repeat',
						  }
						: {}
				}
			>
				<Card.Body className={imageURL ? 'text-white' : null} style={{minHeight: 204}}>
					<Card.Title>
						<h3>
							<CMSLink item={liveEvent} color={imageURL ? 'white' : null}>
								<Link
									to={{pathname: pathname, state: {background: location}}}
									resourceID={liveEvent.ID}
									resourceType="LiveEvent"
									className={imageURL ? 'text-white' : null}
								>
									{showAsReport ? (
										<span>
											{liveEvent.Title} ({parentheticalText}) <FontAwesomeIcon icon={faPlayCircle} size="lg" />
										</span>
									) : (
										<span>
											{liveEvent.Title} ({parentheticalText})
										</span>
									)}
								</Link>
							</CMSLink>
						</h3>
					</Card.Title>
					<Link
						to={{pathname: pathname, state: {background: location}}}
						resourceID={liveEvent.ID}
						resourceType="LiveEvent"
						className={imageURL ? 'text-white' : null}
					>
						{isPast ? (
							<div>
								<p>{trimText(liveEvent.Description, 100)}</p>
								<div className="card-text">{moment.utc(liveEvent.EventDate).format('D MMMM YYYY')}</div>
							</div>
						) : (
							<span>Learn more {moment().isBefore(moment(liveEvent.EventDate)) ? 'and Register' : ''}</span>
						)}
					</Link>
				</Card.Body>
				{!showAsReport ? (
					<div className={styles.footerOverlay}>
						<div className="d-flex align-items-center text-white">
							{isPast ? (
								<>
									{hasVideo ? (
										<span>Watch the replay</span>
									) : hasPDF ? (
										<span>View the presentation</span>
									) : (
										<span>Replay not yet available</span>
									)}
								</>
							) : (
								<>
									<FontAwesomeIcon icon={faCalendar} size="2x" />
									<span className="ml-3">{moment(liveEvent.EventDate).format('LLL')}</span>
								</>
							)}
						</div>
					</div>
				) : (
					<div className="p-3">
						<ActionIcons
							item={liveEvent}
							isBookmarked={liveEvent.IsBookmarked}
							tourClasses={tourClasses}
							showContactAnalystButton={true}
							floatRight={true}
							showContentIcons={true}
						/>
					</div>
				)}
			</div>
		</Card>
	);
}
