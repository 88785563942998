import {gql} from '@apollo/client';

export const POPUP = gql`
	query {
		popup {
			ID
			Name
			Message
		}
	}
`;
