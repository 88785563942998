import React, {useContext} from 'react';
import {Modal} from 'react-bootstrap';

import {AuthContext} from '../../context/auth-context';

import Login from '../../pages/login/Login';
import Register from '../../pages/register/Register';
import ForgotPassword from '../../pages/forgotPassword/ForgotPassword';

import styles from './css/AuthModalWrapper.module.css';

export default function AuthModalWrapper(props) {
	const {authModalToShow, setAuthModalToShow} = useContext(AuthContext);

	const onHideModal = () => setAuthModalToShow(null);
	const modalName = authModalToShow && authModalToShow.modal ? authModalToShow.modal : null;

	return (
		<Modal
			show={!!modalName}
			onHide={onHideModal}
			animation={false}
			className={styles.modal}
			dialogClassName="auth-modal"
		>
			{modalName === 'login' ? (
				<Login isModal={true} />
			) : modalName === 'register' ? (
				<Register isModal={true} />
			) : modalName === 'forgotPassword' ? (
				<ForgotPassword isModal={true} />
			) : null}
		</Modal>
	);
}
