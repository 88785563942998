export const HubTypes = {
	REGION: 1,
	HEALTHCARE: 2,
	THOUGHT_LEADERSHIP: 3,
	LIVE_EVENT: 4,
	PHARMA: 5,
	MED_TECH: 6,
};

export const SpecialHubs = {
	LIVE_EVENT: 17,
};
