import {ApolloClient, HttpLink, split} from '@apollo/client';
import {InMemoryCache} from '@apollo/client/cache';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {env} from './util/environment';
// import {concatPagination} from '@apollo/client/utilities';

const cache = new InMemoryCache({
	possibleTypes: {
		Content: ['Report', 'Multimedia', 'InsightBite', 'Hub', 'LiveEvent'],
	},
	typePolicies: {
		Ad: {
			keyFields: ['ID'],
		},
		MarketTrend: {
			keyFields: ['ContextDate', 'MarketID'],
		},
		Event: {
			keyFields: ['ID'],
		},
		Hub: {
			keyFields: ['ID'],
		},
		Market: {
			keyFields: ['ID'],
		},
		ReportType: {
			keyFields: ['ID'],
		},
		Author: {
			keyFields: ['Username'],
		},
		User: {
			keyFields: ['ID'],
		},
		Analyst: {
			keyFields: ['Username'],
		},
		NewAndNoteworthy: {
			keyFields: ['ID'],
		},
		// NewsArticle: {
		// 	keyFields: ['ID'],
		// },
		Report: {
			keyFields: ['ID'],
		},
		Multimedia: {
			keyFields: ['ID'],
		},
		InsightBite: {
			keyFields: ['ID'],
		},
		LiveEvent: {
			keyFields: ['ID'],
		},
		Query: {
			fields: {
				analystDetailsByUsername: (_, {args, toReference}) => {
					return toReference({__typename: 'Analyst', Username: args.analystUsername});
				},
			},
		},
	},
});

const httpLink = new HttpLink({
	uri: `${env.REACT_APP_API_DOMAIN}/graphql`,
	credentials: 'include',
});

const wsLink = new WebSocketLink({
	uri: env.REACT_APP_WS_ENDPOINT,
	options: {
		reconnect: true,
		lazy: true,
	},
});

const link = split(
	({query}) => {
		const definition = getMainDefinition(query);
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	wsLink,
	httpLink
);

const client = new ApolloClient({
	connectToDevTools: env.NODE_ENV !== 'production',
	credentials: true,
	cache,
	link,
});

export {cache, client as default};
