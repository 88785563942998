import {useMutation} from '@apollo/client';
import React, {useMemo, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {contentTypeToID as ContentTypes} from '../../util/contentType';

import {useRecordClick} from '../../hooks/analytics';
import ContactForm from './ContactForm';
import styles from './css/ContactAnalyst.module.scss';

import {SEND_ANALYST_MESSAGE} from '../../mutations/notification';

const ContactAnalyst = (props) => {
	const {item, overrideShow, overrideSet} = props;

	const recordContactAnalystClick = useRecordClick({
		resourceID: props.item.ID,
		resourceType: props.item.__typename,
		description: 'contact analyst',
	});

	const recordSubmitMessageClick = useRecordClick({
		resourceID: props.item.ID,
		resourceType: props.item.__typename,
		description: 'send contact analyst message',
	});

	const analyst = useMemo(() => {
		if (item.ContentTypeID === ContentTypes.REPORT || item.ContentTypeID === ContentTypes.INSIGHT_BITE) {
			return item.Author;
		} else if (item.ContentTypeID === ContentTypes.MULTIMEDIA) {
			if (item.Participants) {
				let participant = item.Participants.find((participant) => participant.ParticipantType === 'Moderator');
				if (!participant) {
					participant = item.Participants.find((participant) => participant.ParticipantType === 'Featured');
				}
				return participant ? participant.User : item.Uploader;
			} else {
				return item.Uploader;
			}
			// } else if (item.ContentTypeID === ContentTypes.NEWS_ARTICLE) {
			// 	return item.Submitter;
		} else if (item.ContentTypeID === ContentTypes.LIVE_EVENT) {
			if (item.Participants) {
				let participant = item.Participants.find((participant) => participant.ParticipantType === 'Moderator');
				if (!participant) {
					participant = item.Participants.find((participant) => participant.ParticipantType === 'Speaker');
				}
				return participant ? participant.User : item.Submitter;
			} else {
				return item.Submitter;
			}
		} else {
			return null;
		}
	}, [item]);

	const [show, setShow] = useState(false);
	const setShowToUse = overrideSet || setShow;

	const [sendMessage, {loading}] = useMutation(SEND_ANALYST_MESSAGE, {
		onCompleted() {
			setShowToUse(false);
			recordSubmitMessageClick();
			toast('Successfully sent message!', {type: 'success'});
		},
		onError() {
			toast('Error sending message.', {type: 'error'});
		},
	});

	const handleSubmit = (values) => {
		sendMessage({
			variables: {
				analystUserID: analyst.ContactID,
				message: values.message,
				relatedContent: {
					resourceID: item.ID,
					contentTypeID: item.ContentTypeID,
				},
			},
		});
	};

	return (
		<div>
			<span
				className="text-primary cursor-pointer"
				onClick={() => {
					setShowToUse(true);
					recordContactAnalystClick();
				}}
			>
				{props.children}
			</span>
			<Modal
				show={overrideShow !== undefined ? overrideShow : show}
				onHide={() => {
					setShowToUse(false);
				}}
				className={styles.contactModal}
			>
				<ContactForm
					onSubmit={handleSubmit}
					loading={loading}
					setShow={overrideSet || setShow}
					analyst={analyst}
					relatedContent={item}
				/>
			</Modal>
		</div>
	);
};

export default ContactAnalyst;
