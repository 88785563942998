import {faComment} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Button} from 'react-bootstrap';

import {contentTypeToID} from '../../util/contentType';
import ContactAnalyst from '../contact/ContactAnalyst';
import BookmarkContent from '../search/BookmarkContent';
import DownloadLinks from './DownloadLinks';
import PrintButton from './PrintButton';
import SocialShareLinks from './SocialShareLinks';

import styles from './css/Links.module.scss';

export default function ActionIcons(props) {
	const {
		detailsView,
		item,
		noShare,
		tourClasses = {},
		isBookmarked,
		showContentIcons,
		pathname,
		hideBookmarkButton = false,
		popoverPlacement,
		showContactAnalystButton = false,
		floatRight = false,
	} = props;

	const [showContactAnalystDialog, setShowContactAnalystDialog] = useState(false);

	const buttonClasses = [styles.button, 'px-2'];
	if (
		// item.ContentTypeID === contentTypeToID.NEWS_ARTICLE ||
		item.ContentTypeID === contentTypeToID.INSIGHT_BITE ||
		detailsView
	) {
		buttonClasses.push('text-muted');
	} else {
		buttonClasses.push('text-white');
	}

	return (
		<div className={`${styles.actionLinks} clearfix`}>
			<span className={floatRight ? 'float-right' : null}>
				<SocialShareLinks
					detailsView={detailsView}
					link={pathname}
					item={item}
					noShare={noShare}
					tourClasses={tourClasses}
					popoverPlacement={popoverPlacement}
				/>
				{!hideBookmarkButton ? (
					<BookmarkContent
						contentTypeID={item.ContentTypeID}
						resourceID={item.ID}
						isBookmarked={isBookmarked}
						isCard={!detailsView}
						tourClasses={tourClasses}
					/>
				) : null}
				{showContentIcons ? (
					<DownloadLinks
						detailsView={detailsView}
						link={props.pathname}
						item={props.item}
						popoverPlacement={popoverPlacement}
					/>
				) : null}
				{showContentIcons ? <PrintButton detailsView={detailsView} item={props.item} /> : null}
				{showContactAnalystButton ? (
					<>
						<Button
							variant="link"
							title="Contact analyst"
							className={buttonClasses.join(' ')}
							onClick={() => setShowContactAnalystDialog(true)}
						>
							<FontAwesomeIcon size={detailsView ? 'lg' : '1x'} icon={faComment} fixedWidth />
						</Button>
						<ContactAnalyst
							item={item}
							overrideShow={showContactAnalystDialog}
							overrideSet={setShowContactAnalystDialog}
						/>
					</>
				) : null}
			</span>
		</div>
	);
}
