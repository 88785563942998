import React from 'react';
import propTypes from 'prop-types';
import {Card} from 'react-bootstrap';
import moment from 'moment';

import Link from '../analytics/Link';
import CMSLink from '../links/CMSLink';
import {updatedContentDate} from '../../util/helpers';
import ActionIcons from '../links/ActionIcons';

import styles from './css/Card.module.css';

import {useLocation} from 'react-router-dom';

const MultimediaCard = (props) => {
	const {item: multimedia, tourClasses, isShowingBookmarksOnly} = props;
	const location = useLocation();

	const pathname = `/multimedia/${multimedia.ID}/${multimedia.Slug}`;
	const updatedDate = updatedContentDate(multimedia);

	return (
		<Card
			border="light"
			className={'rounded-0 orange-gradient shadow ' + styles.cardContainer}
			style={{
				marginBottom: '25px',
			}}
		>
			<Card.Body className="text-white">
				<Card.Title>
					<h3>
						<CMSLink item={multimedia} color="white">
							<Link
								to={{pathname: pathname, state: {background: location}}}
								resourceID={multimedia.ID}
								resourceType="Multimedia"
								className="text-white"
								description={isShowingBookmarksOnly ? 'bookmarks card' : 'card'}
							>
								{multimedia.Title}
							</Link>
						</CMSLink>
					</h3>
				</Card.Title>
				<Card.Text>{multimedia.Subtitle}</Card.Text>
				<Card.Text>
					<small>{moment.utc(multimedia.FirstApprovedAt).format('D MMMM YYYY')}</small>
				</Card.Text>
				{updatedDate ? (
					<Card.Text>
						<small>
							<span className="font-weight-bold">Updated</span> {moment.utc(updatedDate).format('D MMM YYYY')}
						</small>
					</Card.Text>
				) : null}
			</Card.Body>
			<div className="p-3">
				<ActionIcons
					item={multimedia}
					isBookmarked={multimedia.IsBookmarked}
					tourClasses={tourClasses}
					showContactAnalystButton={true}
					floatRight={true}
				/>
			</div>
		</Card>
	);
};

MultimediaCard.propTypes = {
	multimedia: propTypes.shape({
		Title: propTypes.string.isRequired,
		FirstApprovedAt: propTypes.string,
		PublishedAt: propTypes.string,
		Summary: propTypes.string,
	}),
};

export default React.memo(MultimediaCard);
