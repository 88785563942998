import React, {useCallback} from 'react';
import {Carousel, Col} from 'react-bootstrap';
import {useQuery} from '@apollo/client';

import Link from '../analytics/Link';

import {NEW_AND_NOTEWORTHIES} from '../../queries/newAndNoteworthies';

import styles from './css/NewAndNoteworthies.module.scss';

import {useLocation} from 'react-router-dom';

const NewAndNoteworthies = (props) => {
	const location = useLocation();
	const {loading, data} = useQuery(NEW_AND_NOTEWORTHIES);

	const renderItems = useCallback(() => {
		let items;
		if (data && data.newAndNoteworthies) {
			items = data.newAndNoteworthies;
			return items.map((item, i) => {
				const imageURL = item.Assets && item.Assets.length > 0 && item.Assets[0].DownloadURL;
				const bannerStyle = {
					backgroundImage: `url(${imageURL})`,
				};
				let to = null;
				let resourceID = null;
				let resourceType = null;
				let href = null;
				let target = null;
				let rel = null;
				if (!item.ExternalURL) {
					const pathname = item.ReportID
						? `/report/${item.ReportID}/${item.Slug}`
						: `/multimedia/${item.MultimediaID}/${item.Slug}`;
					to = {pathname: pathname, state: {background: location}};
					resourceID = item.ReportID ? item.ReportID : item.MultimediaID ? item.MultimediaID : null;
					resourceType = item.ReportID ? 'Report' : item.MultimediaID ? 'Multimedia' : null;
				} else {
					href = item.ExternalURL;
					target = '_blank';
					rel = 'noopener noreferrer';
				}

				return (
					<Carousel.Item key={`NaN-${item.ID}`}>
						<Link
							to={to}
							resourceID={resourceID}
							resourceType={resourceType}
							description={'new and noteworthy'}
							className="text-white"
							href={href}
							target={target}
							rel={rel}
						>
							<div className={styles.banner} style={bannerStyle}></div>
							<Col lg={6} md={10} sm={12}>
								<Carousel.Caption className={styles.caption}>
									<h3>{item.Title}</h3>
									<h5>{item.Subtitle}</h5>
								</Carousel.Caption>
							</Col>
						</Link>
					</Carousel.Item>
				);
			});
		}
	}, [data, location]);

	if (loading) {
		return null;
	} else if (data && !loading) {
		return (
			<Carousel controls={false} className={styles.carousel}>
				{renderItems()}
			</Carousel>
		);
	} else {
		return "Oops, we've encountered a problem... Please wait a few moments and try refreshing your page. If the problem continues, please contact us.";
	}
};

export default React.memo(NewAndNoteworthies);
