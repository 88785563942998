import {useEffect, useContext} from 'react';
import {useApolloClient} from '@apollo/client';
import {AuthContext} from '../context/auth-context';
import {PROFILE_UPDATE} from '../subscriptions/auth';

const useProfileSubscription = (listener) => {
	const client = useApolloClient();
	const {isAuthenticated} = useContext(AuthContext);

	useEffect(() => {
		let subscription = null;
		if (isAuthenticated) {
			const observer = client.subscribe({query: PROFILE_UPDATE});
			subscription = observer.subscribe(listener);
		} else if (!isAuthenticated) {
			if (subscription) {
				subscription.unsubscribe();
			}
		}
		return () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		};
	}, [isAuthenticated, client, listener]);
};

export default useProfileSubscription;
