import React from 'react';
import {useMutation} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBookmark} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';

import {BOOKMARK_CONTENT, UNBOOKMARK_CONTENT} from '../../mutations/bookmark';
import {contentTypeIDToType} from '../../util/contentType';
import {useRecordClick} from '../../hooks/analytics';

import styles from './css/BookmarkContent.module.scss';

export default function BookmarkContent(props) {
	const {resourceID, contentTypeID, isBookmarked, isCard, tourClasses} = props;

	const [bookmarkContent] = useMutation(BOOKMARK_CONTENT, {
		variables: {contentTypeID, resourceID},
		optimisticResponse: {
			__typename: 'Mutation',
			bookmarkContent: {
				__typename: contentTypeIDToType[contentTypeID],
				ID: resourceID,
				ContentTypeID: contentTypeID,
				IsBookmarked: true,
			},
		},
	});
	const [unbookmarkContent] = useMutation(UNBOOKMARK_CONTENT, {
		variables: {contentTypeID, resourceID},
		optimisticResponse: {
			__typename: 'Mutation',
			unbookmarkContent: {
				__typename: contentTypeIDToType[contentTypeID],
				ID: resourceID,
				ContentTypeID: contentTypeID,
				IsBookmarked: false,
			},
		},
	});

	const recordBookmarkClick = useRecordClick({
		resourceID,
		resourceType: contentTypeIDToType[contentTypeID],
		description: 'bookmark',
	});
	const recordUnbookmarkClick = useRecordClick({
		resourceID,
		resourceType: contentTypeIDToType[contentTypeID],
		description: 'unbookmark',
	});

	const unfilledColorClass =
		isCard && (contentTypeID === 1 || contentTypeID === 2 || contentTypeID === 8) ? 'text-white' : 'text-muted';

	if (isBookmarked) {
		return (
			<Button
				variant="link"
				onClick={() => {
					unbookmarkContent();
					recordUnbookmarkClick();
				}}
				title="Unbookmark this content"
				className={`${styles.unbookmark} text-primary px-2 ${tourClasses.bookmark || null}`}
			>
				<FontAwesomeIcon size={!isCard ? 'lg' : '1x'} icon={faBookmark} />
			</Button>
		);
	} else {
		return (
			<Button
				variant="link"
				onClick={() => {
					bookmarkContent();
					recordBookmarkClick();
				}}
				title="Bookmark this content"
				className={`${styles.bookmark} ${unfilledColorClass} px-2 ${
					tourClasses && tourClasses.bookmark ? tourClasses.bookmark : null
				}`}
			>
				<FontAwesomeIcon size={!isCard ? 'lg' : '1x'} icon={faBookmark} />
			</Button>
		);
	}
}
