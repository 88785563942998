import React from 'react';
import {Button} from 'react-bootstrap';
import {useRecordPopularSearch} from '../../hooks/analytics';

import styles from './css/PopularSearches.module.scss';

const PopularSearches = (props) => {
	const {setSearchText, updateSearchTextInFilters, updateSortFilters, popularSearches} = props;
	const recordPopularSearch = useRecordPopularSearch();

	const popularSearchTexts = popularSearches.map((searchText) => {
		return (
			<Button
				variant="outline-primary"
				key={`search_${searchText}`}
				className={`text-nowrap ${styles.popularSearch}`}
				onClick={() => {
					setSearchText(searchText);
					updateSearchTextInFilters(searchText);
					recordPopularSearch(searchText);
					updateSortFilters(null);
				}}
			>
				{searchText}
			</Button>
		);
	});

	return (
		<div className={`d-flex flex-row flex-wrap mt-4 mb-1 mt-sm-0`}>
			<h5 className="text-nowrap p-1">Try searching for:</h5>
			{popularSearchTexts}
		</div>
	);
};

export default PopularSearches;
