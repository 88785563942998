import {useContext, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {env} from '../../util/environment';
import {AuthContext} from '../../context/auth-context';
import CenteredSpinner from '../../components/loading/CenteredSpinner';

function logInUserThroughLandingPage() {
	const searchParams = new URLSearchParams({
		referring_client_id: env.REACT_APP_CLIENT_ID,
	});
	window.location.href = `${env.REACT_APP_LANDING_PAGE_DOMAIN}/login?${searchParams.toString()}`;
}

function logInUser(referringUrl) {
	window.location.href = `${env.REACT_APP_API_DOMAIN}/api/auth/login${
		referringUrl ? `?referring_url=${referringUrl}` : ''
	}`;
}

/**
 * @param useLandingPage if true, login will redirect to landing page login
 */
export default function KeycloakLogin({useLandingPage}) {
	const {isAuthenticated} = useContext(AuthContext);
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (!isAuthenticated) {
			const params = new URLSearchParams(location.search);
			const referringUrl = params.get('referring_url');
			if (useLandingPage) {
				logInUserThroughLandingPage();
			} else {
				logInUser(referringUrl);
			}
		}
	}, [isAuthenticated, useLandingPage, location]);

	if (isAuthenticated) {
		history.push('/');
	}

	return (
		<div className="bg-light-gray h-screen flex justify-center items-center">
			<div className="flex justify-center items-center h-screen">
				<CenteredSpinner />
			</div>
		</div>
	);
}
