import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import {Card, Row, Image as BootstrapImage} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';

import {useRecordDownload} from '../../hooks/analytics';
import UserBadge from '../user/UserBadge';
import Link from '../analytics/Link';
import InsightBiteChart from '../chart/InsightBiteChart';
import {updatedContentDate} from '../../util/helpers';
import ActionIcons from '../links/ActionIcons';

import styles from './css/Card.module.css';
import InsightBiteGeneralDataChart from '../chart/InsightBiteGeneralDataChart';
import CMSLink from '../links/CMSLink';

const InsightBiteCard = (props) => {
	const {item: insightBite, tourClasses, isShowingBookmarksOnly} = props;
	const location = useLocation();
	const [imageLoaded, setImageLoaded] = useState(
		insightBite.Assets && insightBite.Assets.length > 0 ? !insightBite.Assets[0].DownloadURL : null
	);
	const recordDownload = useRecordDownload({resourceID: insightBite.ID, resourceType: 'InsightBite'});

	const pathname = `/insightBite/${insightBite.ID}/${insightBite.Slug}`;

	const subtitleCSS = ['mt-3'];

	useEffect(() => {
		if (insightBite.Assets && insightBite.Assets.length > 0 && insightBite.Assets[0].DownloadURL) {
			const img = new Image();
			img.src = insightBite.Assets[0].DownloadURL;
			img.onload = () => setImageLoaded(true);
		}
		// eslint-disable-next-line
	}, [insightBite.Assets]);

	let insightBiteContent = null;
	if (
		insightBite.TypeID === 1 &&
		insightBite.Assets &&
		insightBite.Assets.length > 0 &&
		insightBite.Assets[0].AssetTypeID === 1
	) {
		insightBiteContent = (
			<Link
				resourceID={insightBite.ID}
				resourceType="InsightBite"
				to={{pathname: pathname, state: {background: location}}}
			>
				<div className={styles.newsImage}>
					{imageLoaded ? (
						<BootstrapImage width="100%" src={insightBite.Assets[0].DownloadURL} fluid />
					) : (
						<div className={styles.newsImageLoader}></div>
					)}
				</div>
			</Link>
		);
	} else if (insightBite.TypeID === 2) {
		insightBiteContent = (
			<InsightBiteChart
				indicators={insightBite.Indicators}
				markets={insightBite.Markets}
				datapoints={insightBite.Datapoints}
				startDate={insightBite.StartDate}
				endDate={insightBite.EndDate}
				frequencyID={insightBite.FrequencyID}
				recordDownloadAnalytic={recordDownload}
				chartInfo={insightBite.Chart}
				height="300px"
			></InsightBiteChart>
		);
	} else if (insightBite.TypeID === 3) {
		insightBiteContent = (
			<InsightBiteGeneralDataChart
				recordDownloadAnalytic={recordDownload}
				chartInfo={insightBite.Chart}
				showLegend={false}
				height="300px"
			/>
		);
	}
	return (
		<Card
			border="light"
			className={'rounded-0 shadow ' + styles.cardContainer}
			style={{
				marginBottom: '25px',
			}}
		>
			{/*height set below to fix IE 11 bug*/}
			<Card.Body style={{height: '100%'}}>
				<Card.Title>
					<h3>
						<CMSLink item={insightBite}>
							<Link
								to={{pathname: pathname, state: {background: location}}}
								resourceID={insightBite.ID}
								resourceType="InsightBite"
								description={isShowingBookmarksOnly ? 'bookmarks card' : 'card'}
							>
								{insightBite.Title}
							</Link>
						</CMSLink>
					</h3>
				</Card.Title>
				{insightBiteContent}
				<Card.Text className={subtitleCSS.join(' ')}>{insightBite.Subtitle}</Card.Text>
				<Row>
					<UserBadge
						user={insightBite.Author}
						contentDate={insightBite.FirstApprovedAt}
						updatedContentDate={updatedContentDate(insightBite)}
					/>
				</Row>
			</Card.Body>
			<div className="p-3">
				<ActionIcons
					item={insightBite}
					isBookmarked={insightBite.IsBookmarked}
					tourClasses={tourClasses}
					showContactAnalystButton={true}
					floatRight={true}
					showContentIcons={true}
				/>
			</div>
		</Card>
	);
};

InsightBiteCard.propTypes = {
	insightBite: propTypes.shape({
		Title: propTypes.string.isRequired,
		FirstApprovedAt: propTypes.string,
		Summary: propTypes.string,
	}),
};

export default React.memo(InsightBiteCard);
