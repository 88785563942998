import React, {useContext} from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';

import {AuthContext} from '../../context/auth-context';
import {ApproverRole, hasCMSRole} from '../../util/entitlements';
import styles from './css/Links.module.scss';

const CMSLink = (props) => {
	const {item} = props;
	const {userData} = useContext(AuthContext);

	if (hasCMSRole(ApproverRole, userData)) {
		return (
			<div>
				<span>{props.children}</span>
				<a target="_blank" rel="noreferrer noopener" href={item.CMSURL}>
					<Button className={styles.noVerticalPadding} variant="link" title="View this content in CMS">
						<FontAwesomeIcon color={props.color} size={'1x'} icon={faExternalLinkAlt} fixedWidth />
					</Button>
				</a>
			</div>
		);
	}
	return props.children;
};

export default CMSLink;
