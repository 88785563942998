import React, {useState, useEffect, useCallback} from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsOptions from './highcharts.options';

import {formatIndicatorName} from '../../util/indicators';
import Moment from 'moment';

HighchartsExporting(Highcharts);

const LineChart = (props) => {
	const {
		datapoints,
		indicators,
		selectedIndicator,
		markets,
		revisions,
		legend,
		frequency,
		filename,
		recordDownloadAnalytic,
		height,
		source,
		chartInfo,
	} = props;
	const [formattedDatapoints, setFormattedDatapoints] = useState(null);
	const [chartOptions, setChartOptions] = useState();

	const getSeriesStyle = (index) => {
		const colors = [
			'104B5F',
			'27B6EB',
			'FFA118',
			'6610F2',
			'368650',
			'7ED2F2',
			'FFE300',
			'9D1D56',
			'818180',
			'000000',
			'F17738',
			'E42525',
			'94C73E',
			'F3C7DB',
			'A16CF1',
			'FF9A07',
			'006AB4',
			'EF20EF',
			'28EAB3',
		];
		const seriesOption = {color: '#' + colors[index % colors.length]};
		if (index >= colors.length) {
			seriesOption.dashStyle = 'Dash';
		}
		return seriesOption;
	};

	const formatDatapoints = useCallback(
		({datapoints, marketMap, yearMap, indicatorMap, legend: {showRevision, showMarket, showIndicator}, revisions}) => {
			const revisionsNameMap = {};
			for (let x = 0; x < revisions.length; x++) {
				let revisionName = `${x} mo. prior`;
				if (x === 0) {
					revisionName = 'Latest';
				}
				revisionsNameMap[revisions[x].ID] = revisionName;
			}
			const seriesMap = {};
			let colorIndex = 0;
			for (let i = 0; i < datapoints.length; i++) {
				const item = datapoints[i].JobIndicatorMarket;
				if (seriesMap[item]) {
					seriesMap[item].data.push({
						x: yearMap[datapoints[i].ValueDate],
						y: parseFloat(datapoints[i].Value),
						isForecast: datapoints[i].IsForecast,
					});
				} else {
					const meta = datapoints[i].JobIndicatorMarket.split('_');
					const [revision, indicator, market] = meta;
					const nameParts = [];
					if (showRevision) {
						nameParts.push(revisionsNameMap[revision]);
					}
					if (showMarket) {
						nameParts.push(marketMap[market].Name);
					}
					if (showIndicator) {
						nameParts.push(formatIndicatorName(indicatorMap[indicator]));
					}
					const series = {
						revision,
						indicator,
						market,
						id: `${revision}_${indicator}_M.${market}`,
						data: [
							{
								x: yearMap[datapoints[i].ValueDate],
								y: parseFloat(datapoints[i].Value),
								isForecast: datapoints[i].IsForecast,
							},
						],
						name: nameParts.join(' - '),
						yAxis: 0,
						zIndex: 1,
						visible: true,
						...getSeriesStyle(colorIndex),
					};
					colorIndex++;
					seriesMap[item] = series;
				}
			}
			return seriesMap;
		},
		[]
	);

	const mapYears = useCallback((datapoints) => {
		const unique = [...new Set(datapoints.map((item) => item.ValueDate))].sort();
		let indexMap = {};
		for (let x = 0; x < unique.length; x++) {
			indexMap[unique[x]] = x;
		}
		return indexMap;
	}, []);

	useEffect(() => {
		const chartSettings = highchartsOptions();
		let yearMap = mapYears(datapoints);
		let formattedData = formatDatapoints({
			datapoints,
			marketMap: markets.map,
			yearMap,
			indicatorMap: indicators.map,
			revisions: revisions ? revisions.list : [],
			legend: legend,
		});
		setFormattedDatapoints(formattedData);

		setChartOptions({
			...chartSettings,
			title: {
				...chartSettings.title,
				text: chartInfo ? chartInfo.Title : '',
			},
			subtitle: {
				...chartSettings.subtitle,
				text:
					chartInfo && chartInfo.Subtitle
						? chartInfo.Subtitle
						: indicators.list.length === 1
						? `${selectedIndicator.Name} (${selectedIndicator.Units})`
						: '',
			},
			series: Object.values(formattedData),
			tooltip: {
				formatter: function () {
					let parse = frequency === 2 ? 'YYYY-QQ' : 'YYYY-MM';
					let display = 'YYYY';
					if (frequency === 2) {
						display = 'YYYY-[Q]Q';
					} else if (frequency === 3) {
						display = 'YYYY-MMM';
					}
					const date = Moment(this.key, parse);
					const formattedDate = date.format(display);
					return (
						'<span style="font-size: 10px">' +
						formattedDate +
						'</span><br/>' +
						this.series.name +
						': <b>' +
						Highcharts.numberFormat(this.y, 1) +
						(this.point.isForecast ? 'f' : '') +
						'</b><br/>'
					);
				},
			},
			credits: {
				...chartSettings.credits,
				text: 'Source: ' + (source || chartSettings.credits.text),
			},
			xAxis: {
				...chartSettings.xAxis,
				categories: Object.keys(yearMap),
				labels: {
					formatter: function () {
						let parse = frequency === 2 ? 'YYYY-QQ' : 'YYYY-MM';
						let display = frequency === 2 ? '[Q]Q' : 'MMM';
						const date = Moment(this.value, parse);
						if (date.month() === 0) {
							return date.format('YYYY');
						}
						return date.format(display);
					},
				},
			},
			exporting: {
				menuItemDefinitions: {
					downloadPNG: {
						onclick: function () {
							this.exportChart({type: 'image/png', filename});
							recordDownloadAnalytic({description: 'PNG'});
						},
					},
					downloadJPEG: {
						onclick: function () {
							this.exportChart({type: 'image/jpeg', filename});
							recordDownloadAnalytic({description: 'JPEG'});
						},
					},
					downloadPDF: {
						onclick: function () {
							this.exportChart({type: 'application/pdf', filename});
							recordDownloadAnalytic({description: 'PDF'});
						},
					},
					downloadSVG: {
						onclick: function () {
							this.exportChart({type: 'image/svg', filename});
							recordDownloadAnalytic({description: 'SVG'});
						},
					},
				},
			},
		});
	}, [
		formatDatapoints,
		datapoints,
		mapYears,
		indicators,
		revisions,
		markets,
		legend,
		frequency,
		recordDownloadAnalytic,
		filename,
		source,
		chartInfo,
		selectedIndicator,
	]);

	if (!formattedDatapoints) {
		return 'Loading...';
	}
	return (
		<HighchartsReact
			containerProps={{style: {height: height || '400px'}}}
			highcharts={Highcharts}
			options={chartOptions}
		></HighchartsReact>
	);
};

export default React.memo(LineChart);
