import {gql} from '@apollo/client';

export const POST_CONTACT_ANALYST = gql`
	mutation PostContactAnalyst(
		$Title: String!
		$ContentTypeID: Int!
		$ResourceID: Int!
		$AnalystID: String!
		$AnalystName: String!
		$Message: String!
	) {
		sendContactAnalyst(
			Title: $Title
			ContentTypeID: $ContentTypeID
			ResourceID: $ResourceID
			AnalystID: $AnalystID
			AnalystName: $AnalystName
			Message: $Message
		) {
			IDs
		}
	}
`;

export const SEND_ACCOUNT_MANAGER_MESSAGE = gql`
	mutation SendAccountManagerMessage($message: String!) {
		sendAccountManagerMessage(message: $message)
	}
`;

export const SEND_ANALYST_MESSAGE = gql`
	mutation SendAnalystMessage($analystUserID: String!, $message: String!, $relatedContent: RelatedContentInput) {
		sendAnalystMessage(analystUserID: $analystUserID, message: $message, relatedContent: $relatedContent)
	}
`;

export const SEND_ACCOUNT_MANAGER_VERT_REQUEST = gql`
	mutation SendAccountManagerVERTNotification($liveEventID: Int!) {
		sendAccountManagerVERTNotification(liveEventID: $liveEventID)
	}
`;

const defaultExports = {POST_CONTACT_ANALYST};
export default defaultExports;
