import {gql} from '@apollo/client';

export const BOOKMARK_CONTENT = gql`
	mutation BookmarkContent($contentTypeID: Int!, $resourceID: Int!) {
		bookmarkContent(contentTypeId: $contentTypeID, resourceId: $resourceID) {
			... on Report {
				ID
				ContentTypeID
				IsBookmarked
			}
			... on Multimedia {
				ID
				ContentTypeID
				IsBookmarked
			}
			# ... on NewsArticle {
			# 	ID
			# 	ContentTypeID
			# 	IsBookmarked
			# }
			... on InsightBite {
				ID
				ContentTypeID
				IsBookmarked
			}
			... on LiveEvent {
				ID
				ContentTypeID
				IsBookmarked
			}
		}
	}
`;

export const UNBOOKMARK_CONTENT = gql`
	mutation UnbookmarkContent($contentTypeID: Int!, $resourceID: Int!) {
		unbookmarkContent(contentTypeId: $contentTypeID, resourceId: $resourceID) {
			... on Report {
				ID
				ContentTypeID
				IsBookmarked
			}
			... on Multimedia {
				ID
				ContentTypeID
				IsBookmarked
			}
			# ... on NewsArticle {
			# 	ID
			# 	ContentTypeID
			# 	IsBookmarked
			# }
			... on InsightBite {
				ID
				ContentTypeID
				IsBookmarked
			}
			... on LiveEvent {
				ID
				ContentTypeID
				IsBookmarked
			}
		}
	}
`;
