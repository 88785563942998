import {clone} from 'lodash';
import React, {useCallback} from 'react';
import {Col} from 'react-bootstrap';
import Masonry from 'react-masonry-component';

import ReportCard from './ReportCard';
// import NewsCard from './NewsCard';
import Error from '../error/Error';
import FNItemCard from './FNItemCard';
import HubCard from './HubCard';
import InsightBiteCard from './InsightBiteCard';
import LiveEventCard from './LiveEventCard';
import LoadingCard from './LoadingCard';
import MarketCard from './MarketCard';
import MultimediaCard from './MultimediaCard';
import OAItemCard from './OAItemCard';

const NUM_LOADING_CARDS = 20;

const components = {
	Report: ReportCard,
	// NewsArticle: NewsCard,
	Multimedia: MultimediaCard,
	InsightBite: InsightBiteCard,
	Market: MarketCard,
	Hub: HubCard,
	MarketTrend: MarketCard,
	LiveEvent: LiveEventCard,
	OAItem: OAItemCard,
	FNItem: FNItemCard,
	Loading: LoadingCard,
};

const CardMasonry = (props) => {
	const {
		filters,
		loading,
		error,
		items,
		totalItems,
		colSizes,
		hideComments,
		isShowingBookmarksOnly,
		noResultsText = 'No Results Found!',
		hubType,
	} = props;

	const renderCards = useCallback(() => {
		let itemsCopy = items ? clone(items) : [];
		if (loading) {
			const count = [...Array(NUM_LOADING_CARDS).keys()];
			itemsCopy = itemsCopy.concat(
				count.map((i) => {
					return {__typename: 'Loading', ID: i};
				})
			);
		} else if (totalItems === 0 && items) {
			return (
				<Col md={6}>
					<p>{noResultsText}</p>
				</Col>
			);
		}
		return itemsCopy.map((item, i) => {
			const CardComponent = components[item.__typename];
			if (!CardComponent) return null;
			return (
				<Col {...colSizes} key={`card_${item.__typename}_${item.ID}`}>
					<CardComponent
						filters={filters}
						item={item}
						tourClasses={i === 0 ? {share: 'share-content', bookmark: 'bookmark-content'} : {}}
						hideComments={hideComments}
						isShowingBookmarksOnly={isShowingBookmarksOnly}
						hubType={hubType}
					/>
				</Col>
			);
		});
		// eslint-disable-next-line
	}, [items, loading]);

	if (error) {
		return <Error />;
	}

	return (
		<React.Fragment>
			<Masonry enableResizableChildren options={{transitionDuration: 0}}>
				{renderCards()}
			</Masonry>
		</React.Fragment>
	);
};

export default React.memo(CardMasonry);
