import {useEffect, useContext, useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {useHistory} from 'react-router-dom';

import {AuthContext} from '../context/auth-context';
import {REDIRECT} from '../subscriptions/auth';

export default function useHotRedirect(options = {}) {
	const {disabled} = options;
	const {refetchCurrentUser} = useContext(AuthContext);
	const history = useHistory();
	const [redirectTo, setRedirectTo] = useState(null);
	const client = useApolloClient();

	useEffect(() => {
		const observer = client.subscribe({query: REDIRECT});

		const subscription = observer.subscribe(({data}) => {
			setRedirectTo(data.redirect.To);
		});

		return () => subscription.unsubscribe();
	}, [client, setRedirectTo]);

	useEffect(() => {
		if (redirectTo && !disabled) {
			refetchCurrentUser().then(() => {
				history.replace(redirectTo);
			});
		}
	}, [redirectTo, history, refetchCurrentUser, disabled]);
}
