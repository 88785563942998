import React, {useState, useCallback, useMemo} from 'react';
import LineChart from '../chart/LineChart';
import {Col, Row} from 'react-bootstrap';

import styles from './css/KeyForecastRevisionChart.module.scss';
import IndicatorDropdown from './IndicatorDropdown';

const InsightBiteChart = (props) => {
	const {
		indicators,
		markets,
		datapoints,
		startDate,
		endDate,
		height,
		frequencyID,
		recordDownloadAnalytic,
		chartInfo,
	} = props;

	const [selectedIndicatorID, setSelectedIndicator] = useState(indicators[0].ID);

	const formattedIndicators = useMemo(() => {
		const mapped = indicators.reduce((map, obj) => {
			map[obj.ID] = obj;
			return map;
		}, {});
		return {
			list: indicators,
			map: mapped,
		};
	}, [indicators]);

	const formattedMarkets = useMemo(() => {
		const mapped = markets.reduce((map, obj) => {
			map[obj.ID] = obj;
			return map;
		}, {});
		return {
			list: markets,
			map: mapped,
		};
	}, [markets]);

	const filteredDatapoints = useMemo(() => {
		return datapoints.filter((datapoint) => {
			const indicatorID = parseInt(datapoint.JobIndicatorMarket.split('_')[1], 10);
			return indicatorID === selectedIndicatorID;
		});
	}, [datapoints, selectedIndicatorID]);

	const changeIndicator = useCallback((id) => {
		setSelectedIndicator(id);
	}, []);

	return (
		<div className={'my-4'}>
			{formattedIndicators.list.length > 1 || (chartInfo && chartInfo.Subtitle) ? (
				<Row className={styles.chartHeader}>
					<Col md={12}>
						<IndicatorDropdown
							indicators={formattedIndicators}
							selectedIndicator={selectedIndicatorID}
							changeIndicator={changeIndicator}
						/>
					</Col>
				</Row>
			) : null}
			<Row>
				<Col md={12} style={{height: height}}>
					<LineChart
						indicators={formattedIndicators}
						selectedIndicator={formattedIndicators.map[selectedIndicatorID]}
						markets={formattedMarkets}
						startDate={startDate}
						endDate={endDate}
						datapoints={filteredDatapoints}
						legend={{showRevision: false, showMarket: true, showIndicator: false}}
						frequency={frequencyID}
						recordDownloadAnalytic={recordDownloadAnalytic}
						height={height}
						chartInfo={chartInfo}
					></LineChart>
				</Col>
			</Row>
		</div>
	);
};

export default React.memo(InsightBiteChart);
