(function () {
	const exports = function (Moment, Highcharts, HighchartsExporting, HighchartsData, HighchartsMore) {
		const ChartIDToType = {
			1: 'line',
			2: 'bar',
			3: 'column',
			4: 'area',
			5: 'scatter',
			6: 'bubble',
			7: 'stacked bar',
			8: 'stacked column',
		};

		const ChartTypeToID = {
			LINE: 1,
			BAR: 2,
			COLUMN: 3,
			AREA: 4,
			SCATTER: 5,
			BUBBLE: 6,
			STACKED_BAR: 7,
			STACKED_COLUMN: 8,
		};

		const seriesMappings = {
			line: null,
			bar: null,
			column: null,
			scatter: [
				{
					x: 0,
					y: 1,
					name: 2,
					category: 3,
					xAxisLabel: 4,
					yAxisLabel: 5,
					source: 6,
				},
			],
			bubble: [
				{
					x: 0,
					y: 1,
					z: 2,
					name: 3,
					category: 4,
					xAxisLabel: 5,
					yAxisLabel: 6,
					zAxisLabel: 7,
					source: 8,
				},
			],
		};

		const dateFormats = {
			mmddyyyy: {
				regex: /^\d{1,2}\/\d{1,2}\/\d{4}$/,
				format: 'M/D/YYYY',
			},
			mmddyy: {
				regex: /^\d{1,2}\/\d{1,2}\/\d{2}$/,
				format: 'M/D/YY',
			},
			mmmddyyyy: {
				regex: /^[a-zA-Z]{2,} \d{1,2}, \d{4}$/,
				format: 'MMM D, YYYY',
			},
			mmmyy: {
				regex: /^[a-zA-Z]{2,}-\d{2}$/,
				format: 'MMM-YY',
			},
			ddmmmyy: {
				regex: /^\d{1,2}-[a-zA-Z]{2,}-\d{2}$/,
				format: 'D-MMM-YY',
			},
			ddmmmyyyy: {
				regex: /^\d{1,2}-[a-zA-Z]{2,}-\d{4}/,
				format: 'D-MMM-YYYY',
			},
			'yyyy-mm-dd': {
				regex: /^\d{4}-\d{1,2}-\d{1,2}/,
				format: 'YYYY-M-D',
			},
			'mm-dd-yyyy': {
				regex: /^\d{1,2}-\d{1,2}-\d{4}/,
				format: 'M-D-YYYY',
			},
		};

		function getLeftYAxis(yAxis) {
			for (let x = 0; x < yAxis.length; x++) {
				if (!yAxis[x].opposite) {
					return yAxis[x];
				}
			}
		}

		function categoriesIntoSeries(data) {
			const rows = data.series[0].data;
			data.series = [];
			for (let i = 0; i < rows.length; i++) {
				const cat = rows[i].category;
				let catExists = false;
				for (let j = 0; j < data.series.length; j++) {
					if (data.series[j].name === cat) {
						// Add a data point to existing series
						data.series[j].data.push(rows[i]);
						catExists = true;
					}
				}
				if (!catExists) {
					// When category is encountered for the first time, create a series
					data.series.push({
						name: cat,
						data: [rows[i]],
						visible: true,
					});
				}
			}
		}

		// https://github.com/jonschlinkert/is-number/blob/master/index.js
		function isNumber(num) {
			if (typeof num === 'number') {
				return num - num === 0;
			}
			if (typeof num === 'string' && num.trim() !== '') {
				return Number.isFinite ? Number.isFinite(+num) : isFinite(+num);
			}
			return false;
		}

		function getSeriesStyle(index) {
			const colors = [
				'104B5F',
				'27B6EB',
				'FFA118',
				'6610F2',
				'368650',
				'7ED2F2',
				'FFE300',
				'9D1D56',
				'818180',
				'000000',
				'F17738',
				'E42525',
				'94C73E',
				'F3C7DB',
				'A16CF1',
				'FF9A07',
				'006AB4',
				'EF20EF',
				'28EAB3',
			];

			const seriesOption = {
				color: '#' + colors[index % colors.length],
			};
			if (index >= colors.length) {
				seriesOption.dashStyle = 'Dash';
			}
			return seriesOption;
		}

		function defaultChartOptions() {
			return {
				chart: {
					type: 'line',
					reflow: true,
					zoomType: 'xy',
					backgroundColor: '#ffffff',
					style: {
						fontFamily: 'Open Sans, Calibri, Arial, Helvetica, sans-serif',
					},
				},
				colors: ['#104b5f', '#27B6EB', '#ff9a07', '#6610f2', '#368650'],
				credits: {
					style: {
						color: '#000000',
					},
					text: 'FrontierView',
					href: '',
					width: '200',
					textOverflow: 'ellipsis',
				},
				exporting: {
					buttons: {
						exportButton: {
							enabled: false,
						},
					},
					chartOptions: {
						legend: {
							itemHiddenStyle: {
								color: 'none',
								display: 'none',
							},
							itemStyle: {
								textOverflow: undefined,
								width: 220,
							},
							width: 600,
							itemWidth: 300,
						},
					},
				},
				labels: {
					style: {
						color: '#000000',
					},
				},
				legend: {
					itemStyle: {
						fontWeight: 'normal',
						color: '#000000',
					},
				},
				navigation: {
					buttonOptions: {
						enabled: true,
					},
				},
				plotOptions: {
					series: {
						marker: {
							enabled: false,
						},
						enableMouseTracking: true,
						animation: false,
						dataLabels: {
							enabled: false,
							formatter: function () {
								return "'" + this.value.toString();
							},
							color: '#000000',
							allowOverlap: true,
						},
					},
					column: {
						animation: false,
					},
				},
				title: {
					text: '',
					style: {
						fontSize: '16px',
						color: '#000000',
					},
					align: 'center',
				},
				subtitle: {
					text: '',
					align: 'center',
					style: {
						fontSize: '1.2em',
						fontStyle: 'italic',
					},
				},
				tooltip: {
					style: {
						color: '#000000',
					},
				},
				xAxis: {
					allowDecimals: false,
					tickLength: 0,
					labels: {
						style: {
							color: '#000000',
						},
					},
				},
				yAxis: {
					title: {
						enabled: false,
					},
					gridLineColor: 'transparent',
					allowDecimals: false,
					lineWidth: 0,
					lineColor: '#000',
				},
			};
		}

		function createHighchartOptions({
			chartTypeID,
			source,
			title,
			subtitle,
			xAxisTitle,
			yAxisTitle,
			zAxisTitle,
			sheetID,
			showLegend,
			chartInstance,
			apiKey,
			setData,
		}) {
			const defaultOptions = new defaultChartOptions();

			if (chartTypeID) {
				defaultOptions.chart.type = ChartIDToType[chartTypeID];
				defaultOptions.legend.enabled = showLegend;

				if (chartTypeID === ChartTypeToID.STACKED_BAR) {
					defaultOptions.chart.type = ChartIDToType[ChartTypeToID.BAR];
					defaultOptions.plotOptions.series.stacking = 'normal';
				} else if (chartTypeID === ChartTypeToID.STACKED_COLUMN) {
					defaultOptions.chart.type = ChartIDToType[ChartTypeToID.COLUMN];
					defaultOptions.plotOptions.column.stacking = 'normal';
				}

				defaultOptions.tooltip.useHTML = true;
				defaultOptions.tooltip.formatter = function (tooltip) {
					if (chartTypeID === ChartTypeToID.SCATTER || chartTypeID === ChartTypeToID.BUBBLE) {
						let zLabel = '';
						if (chartTypeID === ChartTypeToID.BUBBLE) {
							zLabel = `
								<tr>
									<th>${zAxisTitle || this.point.zAxisLabel || 'z'}:</th>
									<td>${this.point.z}</td>
								</tr>
							`;
						}
						return `
								<table>
									<tr>
										<th colspan="2">
											<h5>${this.point.name}</h5>
										</th>
									</tr>
									<tr>
										<th>${xAxisTitle || this.point.xAxisLabel || 'x'}:</th>
										<td>${this.point.x}</td>
									</tr>
									<tr>
										<th>${yAxisTitle || this.point.yAxisLabel || 'y'}:</th>
										<td>${this.point.y}</td>
									</tr>
									${zLabel}
								</table>
							`;
					}
					return tooltip.defaultFormatter.call(this, tooltip);
				};
				defaultOptions.plotOptions.series.connectNulls = true;
				defaultOptions.plotOptions.series.marker.enabled =
					chartTypeID === ChartTypeToID.BUBBLE || chartTypeID === ChartTypeToID.SCATTER;
			}

			if (source) {
				defaultOptions.credits.text = source;
				defaultOptions.credits.enabled = true;
			}

			if (title) {
				defaultOptions.title.text = title;
			}

			if (subtitle) {
				defaultOptions.subtitle.text = subtitle;
			}

			if (yAxisTitle) {
				defaultOptions.yAxis.title.text = yAxisTitle;
				defaultOptions.yAxis.title.enabled = true;
			}

			if (chartTypeID && sheetID) {
				defaultOptions.data = {
					googleSpreadsheetKey: sheetID,
					googleAPIKey: apiKey,
					googleSpreadsheetRange: 'Sheet1',
					seriesMapping: seriesMappings[ChartIDToType[chartTypeID]],
					parseDate: function (val) {
						let format = null;
						let keys = Object.keys(dateFormats);
						for (let x = 0; x < keys.length; x++) {
							if (dateFormats[keys[x]].regex.test(val)) {
								format = dateFormats[keys[x]].format;
								break;
							}
						}
						if (format) {
							return Moment.utc(val, format).toDate().getTime();
						} else {
							return val;
						}
					},
					parsed: function (data) {
						// sometimes when a chart is rerendering, highcharts sometimes turns values into strings (or doesn't properly turn them into numbers?)
						// this is just going through the data making sure values that shouldn't be strings aren't
						for (let i = 0; i < data.length; i++) {
							for (let j = 0; j < data.length; j++) {
								if (isNumber(data[i][j])) {
									data[i][j] = parseFloat(data[i][j]);
								} else if (data[i][j] === 'null') {
									data[i][j] = null;
								}
							}
						}
					},
					complete: function (data) {
						const chart = chartInstance.chart;
						if (chartTypeID === ChartTypeToID.SCATTER || chartTypeID === ChartTypeToID.BUBBLE) {
							let pointWithAxisInfo = {};
							if (
								data &&
								data.series &&
								data.series.length > 0 &&
								data.series[0].data &&
								data.series[0].data.length > 0
							) {
								pointWithAxisInfo = data.series[0].data[0];
								for (let x = 0; x < data.series[0].data.length; x++) {
									data.series[0].data[x].xAxisLabel = xAxisTitle || pointWithAxisInfo.xAxisLabel;
									data.series[0].data[x].yAxisLabel = yAxisTitle || pointWithAxisInfo.yAxisLabel;
									if (chartTypeID === ChartTypeToID.BUBBLE) {
										data.series[0].data[x].zAxisLabel = zAxisTitle || pointWithAxisInfo.zAxisLabel;
									}
								}
							}
							categoriesIntoSeries(data);
							if (pointWithAxisInfo.yAxisLabel) {
								getLeftYAxis(chart.yAxis).setTitle(
									{
										text: yAxisTitle || pointWithAxisInfo.yAxisLabel,
										enabled: true,
									},
									true
								);
							}
							if (pointWithAxisInfo.xAxisLabel) {
								chart.xAxis[0].setTitle(
									{
										text: xAxisTitle || pointWithAxisInfo.xAxisLabel,
										enabled: true,
									},
									true
								);
							}
							if (pointWithAxisInfo.source) {
								chart.addCredits({
									enabled: true,
								});
								chart.credits.update({
									text: 'Source: ' + (source || pointWithAxisInfo.source),
								});
							}
						} else {
							if (data && data.series && data.series.length > 0) {
								const indicesToRemove = {};
								for (let x = 0; x < data.series.length; x++) {
									if (data.series[x].name === 'source') {
										chart.addCredits({
											enabled: true,
										});
										chart.credits.update({
											text: 'Source: ' + (source || data.series[x].data[0][1]),
										});
										indicesToRemove[x] = true;
									}
									if (data.series[x].name === 'yAxisLabel') {
										getLeftYAxis(chart.yAxis).setTitle(
											{
												text: yAxisTitle || data.series[x].data[0][1],
												enabled: true,
											},
											true
										);
										indicesToRemove[x] = true;
									}
								}
								for (let x = data.series.length - 1; x >= 0; x--) {
									if (indicesToRemove.hasOwnProperty(x)) {
										data.series.splice(x, 1);
									}
								}
								if (chartTypeID === ChartTypeToID.LINE) {
									for (let x = 0; x < data.series.length; x++) {
										Object.assign(data.series[x], getSeriesStyle(x));
									}
								}
							}
						}
					},
				};
			}

			return defaultOptions;
		}

		function buildHighCharts() {
			HighchartsMore(Highcharts);
			HighchartsExporting(Highcharts);
			HighchartsData(Highcharts);
			return Highcharts;
		}

		return {
			createHighchartOptions,
			buildHighCharts,
			ChartTypeToID,
			ChartIDToType,
			seriesMappings,
			dateFormats,
			defaultChartOptions,
			getSeriesStyle,
			categoriesIntoSeries,
		};
	};
	/* eslint-disable */
	if (typeof define === 'function' && define.amd) {
		define([
			'moment',
			'highcharts',
			'highcharts/modules/exporting',
			'highcharts/modules/data',
			'highcharts/highcharts-more',
		], function (Moment, Highcharts, HighchartsExporting, HighchartsData, HighchartsMore) {
			'use strict';
			return exports(Moment, Highcharts, HighchartsExporting, HighchartsData, HighchartsMore);
		});
		/* eslint-enable */
	} else if (typeof module !== 'undefined' && module.exports) {
		const Moment = require('moment');
		const Highcharts = require('highcharts');
		const HighchartsExporting = require('highcharts/modules/exporting');
		const HighchartsData = require('highcharts/modules/data');
		const HighchartsMore = require('highcharts/highcharts-more');

		module.exports = exports(Moment, Highcharts, HighchartsExporting, HighchartsData, HighchartsMore);
	}
}.call(this));
