import {gql} from '@apollo/client';

import {UserFragment} from '../fragments/user';

export const LOGIN = gql`
	mutation Login($email: String!, $password: String!, $remember: Boolean!) {
		login(email: $email, password: $password, remember: $remember) {
			success
			userData {
				...UserFragmentFields
			}
			loginLock {
				expiresAt
			}
		}
	}
	${UserFragment}
`;

export const LOGOUT = gql`
	mutation {
		logout
	}
`;

export const RESET_PASSWORD = gql`
	mutation ResetPassword($token: String!, $newPassword: String!) {
		resetPassword(token: $token, newPassword: $newPassword)
	}
`;

export const REGISTER = gql`
	mutation Register($email: String!, $password: String!) {
		register(email: $email, password: $password) {
			success
			userData {
				...UserFragmentFields
			}
		}
	}
	${UserFragment}
`;

export const VERIFY_EMAIL = gql`
	mutation VerifyEmail($token: String!) {
		verifyEmail(token: $token)
	}
`;

export const REGISTER_MARKETS = gql`
	mutation RegisterMarkets($marketIDs: [Int!]!, $tosAgree: Boolean!, $timezone: String) {
		registerMarkets(marketIDs: $marketIDs, tosAgree: $tosAgree, timezone: $timezone)
	}
`;

export const RESEND_VERIFICATION_EMAIL = gql`
	mutation {
		resendVerificationEmail
	}
`;

export const UPDATE_PROFILE_SETTINGS = gql`
	mutation UpdateProfileSettings(
		$firstName: String
		$lastName: String
		$timezone: String
		$researchAlertFrequency: String!
		#$newsAlertFrequency: String!
		$retrospectiveAlertFrequency: String!
		$upcomingWebinarsAlertFrequency: String!
		$productMarketAlertFrequency: String!
		$followedMarkets: [Int!]
	) {
		updateProfileSettings(
			firstName: $firstName
			lastName: $lastName
			timezone: $timezone
			researchAlertFrequency: $researchAlertFrequency
			#newsAlertFrequency: $newsAlertFrequency
			retrospectiveAlertFrequency: $retrospectiveAlertFrequency
			upcomingWebinarsAlertFrequency: $upcomingWebinarsAlertFrequency
			productMarketAlertFrequency: $productMarketAlertFrequency
			followedMarkets: $followedMarkets
		)
	}
`;

export const UPDATE_FOLLOWED_MARKETS = gql`
	mutation UpdateFollowedMarkets($followedMarkets: [Int!]!) {
		updateFollowedMarkets(followedMarkets: $followedMarkets)
	}
`;

export const UPDATE_FOLLOWED_HUBS = gql`
	mutation UpdateFollowedHubs($followedHubs: [Int!]!) {
		updateFollowedHubs(followedHubs: $followedHubs)
	}
`;

export const TOUR_VIEWED = gql`
	mutation {
		webTourViewed
	}
`;

export const REFRESH_SESSION = gql`
	mutation {
		refreshSession {
			...UserFragmentFields
		}
	}
	${UserFragment}
`;

export const UNSUBSCRIBE_ALERT = gql`
	mutation UnsubscribeAlert($emailID: Int!, $alertType: Int!) {
		unsubscribeAlert(emailID: $emailID, alertType: $alertType)
	}
`;

export const FORGOT_PASSWORD = gql`
	mutation ForgotPassword($email: String!) {
		forgotPassword(email: $email)
	}
`;

const defaultExports = {LOGIN, LOGOUT, REGISTER, REFRESH_SESSION};
export default defaultExports;
