import React, {Fragment, useState, useEffect, useMemo, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import {Form, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faExternalLinkSquareAlt} from '@fortawesome/free-solid-svg-icons';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import {useDebounce} from 'use-debounce';

import {HumanReadableContentTypes} from '../../util/contentType';
import {SEARCH_AUTOCOMPLETE} from '../../queries/search';
import {useMarketNameSearch} from '../../hooks/useMarkets';
import Link from '../analytics/Link';

import styles from './css/ScrollingContent.module.scss';
import searchStyles from './css/SearchBar.module.scss';
import './css/SearchBar.css';

const SearchBar = (props) => {
	const {
		setSearchText,
		updateSearchTextInFilters,
		updateSortFilters,
		recordClearSearch,
		searchText,
		filters,
		includeAutosuggestions,
		showMarketAutosuggestions,
		placeholderText,
		content,
	} = props;
	const [inputFocused, setInputFocused] = useState(false);
	const [focusedIdx, setFocusedIdx] = useState(null);
	const history = useHistory();
	const location = useLocation();
	const [doSearch, {data, loading}] = useLazyQuery(SEARCH_AUTOCOMPLETE);
	const autocompleteResults = useMemo(() => (data ? data.searchAutocomplete : []), [data]);

	const [debouncedSearchText] = useDebounce(searchText, 250);
	const [searchMarketsByName, {matchedMarkets}] = useMarketNameSearch({highlight: true});
	const marketFilterSet = useMemo(() => new Set(filters.marketIDs || []), [filters]);

	useEffect(() => {
		if (debouncedSearchText && debouncedSearchText.length >= 2 && includeAutosuggestions) {
			doSearch({variables: {...filters, searchText: debouncedSearchText}});
			searchMarketsByName(debouncedSearchText);
		}
	}, [debouncedSearchText, searchMarketsByName, doSearch, includeAutosuggestions, filters]);

	const highlight = useCallback((fullText, term) => {
		const escapedSearchTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		const highlightRegex = new RegExp(`${escapedSearchTerm}`, 'gi');
		const matches = fullText.match(highlightRegex);
		let highlightedText = fullText;
		if (matches) {
			for (let match of matches) {
				highlightedText = highlightedText.replace(match, `<span class="autosuggest-highlight">${match}</span>`);
			}
		}
		return highlightedText;
	}, []);

	const combinedAutocompleteResults = useMemo(() => {
		const formattedMatchedMarkets = [];
		if (showMarketAutosuggestions) {
			formattedMatchedMarkets.push(
				...matchedMarkets
					.filter((m) => {
						if (marketFilterSet.size > 0) {
							return marketFilterSet.has(m.ID);
						}
						return true;
					})
					.map((m) => {
						return {
							Title: m.Highlighted || m.Name,
							ID: m.ID,
							ContentTypeID: 'market',
							Path: `/market/${m.Name.replace(/ /g, '')}`.toLowerCase(),
						};
					})
			);
		}
		const formattedAutocompleteResults = autocompleteResults
			.slice(0, 5 - formattedMatchedMarkets.length)
			.map((result) => {
				return {
					...result,
					Title: highlight(result.Title, debouncedSearchText),
					Analyst: {
						...result.Analyst,
						Name: highlight(result.Analyst.Name, debouncedSearchText),
					},
				};
			});
		return [...formattedMatchedMarkets, ...formattedAutocompleteResults];
	}, [matchedMarkets, autocompleteResults, marketFilterSet, showMarketAutosuggestions, debouncedSearchText, highlight]);

	const handleKeyDown = (e) => {
		if (!includeAutosuggestions) return;

		const idx = focusedIdx !== null ? focusedIdx : -1;
		if (
			e.key === 'ArrowDown' &&
			combinedAutocompleteResults &&
			focusedIdx < combinedAutocompleteResults.length + Number(!!content)
		) {
			setFocusedIdx(idx + 1);
		} else if (e.key === 'ArrowUp' && combinedAutocompleteResults && focusedIdx > 0) {
			setFocusedIdx(idx - 1);
		} else if (e.key === 'Enter') {
			if (idx === -1 || idx === 0) {
				updateSearchTextInFilters(searchText);
				updateSortFilters(null);
			} else if (idx === combinedAutocompleteResults.length + 1) {
				history.push({pathname: '/', search: `?searchText=${searchText}`});
			} else if (idx > 0 && combinedAutocompleteResults && combinedAutocompleteResults.length > 0) {
				history.push(combinedAutocompleteResults[idx - 1].Path);
			}
			e.target.blur();
		}
	};

	const showDropdown = includeAutosuggestions && searchText && searchText.length >= 2 && inputFocused;

	const buildAutosuggestionLinkObject = (contentTypeID, path) => {
		const linkObj = {pathname: path};
		if (contentTypeID !== 'market' && HumanReadableContentTypes[contentTypeID] !== 'Hub') {
			linkObj.state = {background: location};
		}
		return linkObj;
	};

	const AutoCompleteResult = (result, i) => {
		const resultType = result.ContentTypeID === 'market' ? 'Market' : HumanReadableContentTypes[result.ContentTypeID];
		const hasAnalyst =
			HumanReadableContentTypes[result.ContentTypeID] !== 'Hub' && result.ContentTypeID !== 'market' && result.Analyst;

		return (
			<div
				className={`dropdown-item ${focusedIdx === i + 1 ? searchStyles.autosuggestionFocused : null} d-flex flex-row`}
				key={`autocomplete_${result.ContentTypeID}_${result.ID}`}
			>
				<Link
					resourceID={result.ID}
					resourceType={resultType}
					description="autocomplete result"
					to={buildAutosuggestionLinkObject(result.ContentTypeID, result.Path)}
					className="text-truncate text-dark text-muted mr-1 underline-text"
				>
					{resultType} / <span className="bold" dangerouslySetInnerHTML={{__html: result.Title}} />
				</Link>
				{hasAnalyst ? (
					<Link
						resourceType="Analyst"
						resourceID={result.Analyst.ID}
						description="autocomplete result"
						to={`/analyst/${result.Analyst.Username}`}
						className="text-dark cursor-pointer underline-text"
					>
						<small className="font-italic">
							<span dangerouslySetInnerHTML={{__html: result.Analyst.Name}} />
						</small>
					</Link>
				) : null}
			</div>
		);
	};

	return (
		<Fragment>
			<Form.Group>
				<InputGroup className="search-bar">
					<InputGroup.Prepend>
						<FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />
					</InputGroup.Prepend>
					<Form.Control
						type="text"
						placeholder={placeholderText || 'Search'}
						value={searchText || ''}
						onKeyDown={handleKeyDown}
						onFocus={() => setInputFocused(true)}
						onBlur={() =>
							setTimeout(() => {
								setInputFocused(false);
								setFocusedIdx(null);
							}, 250)
						}
						onChange={(e) => {
							setSearchText(e.target.value);
							if (!includeAutosuggestions) {
								updateSearchTextInFilters(e.target.value);
							}
						}}
						className={styles.searchInput}
					></Form.Control>
					<InputGroup.Append>
						{!!searchText || filters.searchText ? (
							<FontAwesomeIcon
								onClick={() => {
									if (filters.searchText !== searchText) {
										setSearchText(filters.searchText || '');
									} else {
										setSearchText('');
										updateSearchTextInFilters('');
										recordClearSearch();
									}
									updateSortFilters(null);
								}}
								className={`${styles.searchIcon} ${styles.searchClear} text-danger`}
								icon={faTimesCircle}
							/>
						) : null}
					</InputGroup.Append>
				</InputGroup>
				<div className={searchStyles.autosuggestionPlacement}>
					<div className={`${searchStyles.autosuggestionContainer} dropdown-menu ${showDropdown ? 'show' : null}`}>
						<Fragment>
							<div
								className={`dropdown-item cursor-pointer ${
									focusedIdx === 0 ? searchStyles.autosuggestionFocused : null
								}`}
								onClick={() => {
									updateSearchTextInFilters(searchText);
								}}
							>
								Search for <strong>{searchText}</strong> in {content || 'all content'}
							</div>

							{loading ? (
								<Fragment>
									<div className="dropdown-divider" />
									<div className="dropdown-item text-muted">Loading...</div>
								</Fragment>
							) : combinedAutocompleteResults && combinedAutocompleteResults.length > 0 ? (
								<Fragment>
									<div className="dropdown-divider" />
									{combinedAutocompleteResults.map(AutoCompleteResult)}
								</Fragment>
							) : combinedAutocompleteResults && combinedAutocompleteResults.length === 0 ? (
								<Fragment>
									<div className="dropdown-divider" />
									<div className="dropdown-header">No results found.</div>
								</Fragment>
							) : null}

							{content ? (
								<Fragment>
									<div className="dropdown-divider" />
									<Link
										to={{pathname: '/', search: `?searchText=${searchText}`}}
										className={`d-flex align-items-center text-dark text-decoration-none dropdown-item cursor-pointer ${
											focusedIdx === combinedAutocompleteResults.length + 1 ? searchStyles.autosuggestionFocused : null
										}`}
									>
										<span>
											Search for <strong>{searchText}</strong> in all content
										</span>
										<FontAwesomeIcon className="mx-2" icon={faExternalLinkSquareAlt} />
									</Link>
								</Fragment>
							) : null}
						</Fragment>
					</div>
				</div>
			</Form.Group>
		</Fragment>
	);
};

export default React.memo(SearchBar);
