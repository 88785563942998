const regionMap = {
	'Asia Pacific': [14, 45, 101, 102, 110, 117, 133, 174, 217, 238],
	Americas: [232, 39, 11, 31, 44, 48, 142, 173],
	'Middle East & North Africa': [4, 64, 104, 107, 122, 193, 224, 230],
	'Sub-Saharan Africa': [114, 161, 203],
	'Western Europe': [74, 81, 108, 205, 231],
	'Central & Eastern Europe': [99, 113, 176, 181, 182, 229],
};

const marketAliases = {
	Turkey: 'Turkiye',
	turkey: 'Turkiye',
};

module.exports = {
	regionMap,
	marketAliases,
};
