import React, {Fragment} from 'react';

export default function Error({error}) {
	if (error) {
		const serverError = error.graphQLErrors
			? error.graphQLErrors.find((e) => e.extensions.code === 'INTERNAL_SERVER_ERROR')
			: true;

		if (!serverError) {
			return (
				<Fragment>
					{error.graphQLErrors.map((e) => (
						<div className="text-danger">{e.message}</div>
					))}
				</Fragment>
			);
		}
	}

	return (
		<div className="text-danger">
			Oops, we've encountered a problem... Please wait a few moments and try refreshing your page. If the problem
			continues, please contact us.
		</div>
	);
}
