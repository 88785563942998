import {gql} from '@apollo/client';
import moment from 'moment';
import {cache} from '../client';
import {contentTypeIDToType, contentTypeToID, publicContentTypeIDToType} from './contentType';

export function notValidSlidePageNum(pageInt, slides) {
	return pageInt !== null && slides !== null && (isNaN(pageInt) || pageInt > slides.length || pageInt < 1);
}

export function getLoadedSlidePageNum(pageInt, slides) {
	return notValidSlidePageNum(pageInt, slides) ? 1 : pageInt;
}

export function updatedContentDate(content) {
	let date;
	if (
		content.__typename === contentTypeIDToType[contentTypeToID.REPORT] ||
		content.__typename === publicContentTypeIDToType[contentTypeToID.REPORT]
	) {
		date = content.FirstApprovedAt;
	} else if (
		content.__typename === contentTypeIDToType[contentTypeToID.MULTIMEDIA] ||
		content.__typename === publicContentTypeIDToType[contentTypeToID.MULTIMEDIA]
	) {
		date = content.FirstApprovedAt;
		// } else if (
		// 	content.__typename === contentTypeIDToType[contentTypeToID.NEWS_ARTICLE] ||
		// 	content.__typename === publicContentTypeIDToType[contentTypeToID.NEWS_ARTICLE]
		// ) {
		// 	date = content.SubmittedAt;
	} else if (
		content.__typename === contentTypeIDToType[contentTypeToID.INSIGHT_BITE] ||
		content.__typename === publicContentTypeIDToType[contentTypeToID.INSIGHT_BITE]
	) {
		date = content.FirstApprovedAt;
	}
	return content.PublishedAt &&
		(moment(content.PublishedAt).year() !== moment(date).year() ||
			moment(content.PublishedAt).month() !== moment(date).month() ||
			moment(content.PublishedAt).date() !== moment(date).date())
		? content.PublishedAt
		: null;
}

export function trimText(text, length) {
	if (!text) {
		return '';
	}
	if (text.length <= length + 3) {
		return text;
	} else {
		return text.slice(0, length) + '...';
	}
}

export function isCommentAdded(newComment, comments) {
	let commentsToCheck;
	if (newComment.ParentID) {
		const comment = comments.find((c) => newComment.ParentID === c.ID);
		commentsToCheck = comment.Replies;
	} else {
		commentsToCheck = comments;
	}
	return commentsToCheck.some((c) => c.ID === newComment.ID);
}

export function restrictSharing(data) {
	// restrict reports with Accounts
	let ref = data.Accounts;
	if (ref && ref.length > 0) {
		return true;
	}
	return false;
}

const commentFragment = gql`
	fragment myContent on Content {
		Comments {
			ID
			CreatedAt
			NumReplies
			Replies {
				ID
				NumReplies
				Text
				User {
					AvatarURL
					ID
					Name
				}
				UserID
				ParentID
			}
			ParentID
			Text
			UserID
			User {
				AvatarURL
				ID
				Name
			}
		}
	}
`;

const commentRepliesFragment = gql`
	fragment replies on Comment {
		Replies {
			ID
			NumReplies
			Text
			User {
				AvatarURL
				ID
				Name
			}
			UserID
			ParentID
		}
	}
`;

export function appendCommentToContent(client, newComment, contentTypeID, contentID) {
	const contentIdentity = cache.identify({__typename: contentTypeIDToType[contentTypeID], ID: contentID});
	const content = cache.readFragment({
		id: contentIdentity,
		fragment: commentFragment,
	});
	const comments = content.Comments;

	if (newComment.ParentID) {
		const commentIndex = comments.findIndex((c) => newComment.ParentID === c.ID);
		if (!comments[commentIndex]) return;

		const parentCommentIdentity = cache.identify({__typename: 'Comment', ID: newComment.ParentID});
		const parentComment = cache.readFragment({
			id: parentCommentIdentity,
			fragment: commentRepliesFragment,
		});
		const replies = parentComment.Replies;

		const isCommentAdded = replies.some((c) => c.ID === newComment.ID);
		if (!isCommentAdded) {
			cache.writeFragment({
				id: parentCommentIdentity,
				fragment: commentRepliesFragment,
				data: {Replies: [...replies, newComment]},
			});
		}
	} else {
		const isCommentAdded = comments.some((c) => c.ID === newComment.ID);
		if (!isCommentAdded) {
			cache.writeFragment({
				id: contentIdentity,
				fragment: commentFragment,
				data: {Comments: [...comments, newComment]},
			});
		}
	}
}
