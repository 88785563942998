import React, {createContext, useState} from 'react';

export const ShortenedUrlContext = createContext({
	urlMap: {},
	setUrl: () => {},
});

const AuthContextProvider = (props) => {
	const [urlMap, setUrlMap] = useState({});

	const setUrl = (url, shortenedUrl) => {
		setUrlMap((urlMap) => {
			return {...urlMap, [url]: shortenedUrl};
		});
	};

	return <ShortenedUrlContext.Provider value={{urlMap, setUrl}}>{props.children}</ShortenedUrlContext.Provider>;
};

export default AuthContextProvider;
