import React, {useState, useEffect, useContext} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useLazyQuery, useMutation} from '@apollo/client';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import {AuthContext} from '../../context/auth-context';
import {POPUP} from '../../queries/popups';
import {SEE_POPUP} from '../../mutations/popup';

export default function Popup(props) {
	const [fetchPopup, {data}] = useLazyQuery(POPUP);
	const [seePopup] = useMutation(SEE_POPUP);
	const [show, setShow] = useState(false);
	const {isAuthenticated, hasFV3Access, isFreeUser, isVerified, hasMarketsSelected} = useContext(AuthContext);

	useEffect(() => {
		if (isAuthenticated && hasFV3Access && (!isFreeUser || (isFreeUser && isVerified && hasMarketsSelected))) {
			fetchPopup();
		}
	}, [isAuthenticated, hasFV3Access, fetchPopup, isFreeUser, isVerified, hasMarketsSelected]);

	const popup = data ? data.popup : null;

	useEffect(() => {
		if (popup) {
			setShow(true);
		}
	}, [popup]);

	const handleClose = () => {
		seePopup({variables: {id: popup.ID}});
		setShow(false);
	};

	if (popup) {
		return (
			<Modal animation={false} show={show} onHide={handleClose} centered size="lg">
				<Modal.Header closeButton>
					<Modal.Title>{popup.Name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ReactMarkdown linkTarget="_blank" children={popup.Message} remarkPlugins={[remarkGfm]} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		);
	} else {
		return null;
	}
}
