import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';

import {AuthContext} from '../../context/auth-context';
import CenteredSpinner from '../../components/loading/CenteredSpinner';

const Logout = (props) => {
	const history = useHistory();
	const {isAuthenticated, logout} = useContext(AuthContext);
	useEffect(() => {
		if (isAuthenticated) {
			logout();
		} else {
			history.replace('/');
		}
		// eslint-disable-next-line
	}, [isAuthenticated]);
	return (
		<Row>
			<Col className={`d-flex justify-content-center`}>
				<CenteredSpinner />
			</Col>
		</Row>
	);
};

export default Logout;
