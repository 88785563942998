import {useMutation, useQuery} from '@apollo/client';
import {ResetPassword} from 'commonsui';
import React, {useContext, useEffect, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {Link, useHistory, useParams} from 'react-router-dom';
import {MetaTagsContext} from '../../context/meta';

import CenteredSpinner from '../../components/loading/CenteredSpinner';
import {RESET_PASSWORD} from '../../mutations/auth';
import {VERIFY_FORGOT_PASSWORD_TOKEN} from '../../queries/auth';

export default function ResetPasswordContainer(props) {
	const {setDefaultMetaTags} = useContext(MetaTagsContext);
	const {token} = useParams();
	const history = useHistory();
	const {error: verifyError, data: verifyData} = useQuery(VERIFY_FORGOT_PASSWORD_TOKEN, {variables: {token}});
	const [resetPassword, {data: resetData, error: resetError}] = useMutation(RESET_PASSWORD);
	const [formActions, setFormActions] = useState(null);

	useEffect(() => {
		setDefaultMetaTags();
	}, [setDefaultMetaTags]);

	useEffect(() => {
		if (resetData) {
			formActions.setSubmitting(false);
			setTimeout(() => {
				history.push('/login');
			}, 3000);
		}
	}, [resetData, history, formActions]);

	const handleSubmit = ({newPassword}, actions) => {
		resetPassword({variables: {token, newPassword}});
		setFormActions(actions);
	};

	if (verifyError) {
		return (
			<Row>
				<Col sm={{span: 8, offset: 2}} md={{span: 6, offset: 3}} lg={{span: 4, offset: 4}}>
					<Card>
						<Card.Body>
							<p>This link is invalid. It may be incorrect or expired.</p>
							<Link to="/login">Sign In</Link>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	} else if (!verifyData) {
		return <CenteredSpinner />;
	} else {
		return <ResetPassword onSubmit={handleSubmit} data={resetData} error={resetError ? resetError.message : null} />;
	}
}
