import {useMemo} from 'react';
import moment from 'moment';
import {HubTypes} from '../util/hubType';
import {AssetType, contentTypeToID} from '../util/contentType';

export default function useLiveEventStatus(liveEvent, hubType) {
	const isPast = liveEvent ? moment().isAfter(moment(liveEvent.EventDate)) : null;
	const hasVideo = liveEvent ? !!liveEvent.VideoURL : null;
	const hasPPT = useMemo(() => {
		if (!liveEvent) {
			return null;
		}
		for (let asset of liveEvent.Assets) {
			if (asset.AssetTypeID === AssetType[contentTypeToID.LIVE_EVENT].POWERPOINT) {
				return true;
			}
		}
		return false;
	}, [liveEvent]);
	const hasPDF = useMemo(() => {
		if (!liveEvent) {
			return null;
		}
		for (let asset of liveEvent.Assets) {
			if (asset.AssetTypeID === AssetType[contentTypeToID.LIVE_EVENT].PDF) {
				return true;
			}
		}
		return false;
	}, [liveEvent]);

	const showAsReport = liveEvent ? hubType !== HubTypes.LIVE_EVENT && isPast && hasVideo : null;

	return {isPast, hasVideo, hasPPT, hasPDF, showAsReport};
}
