import {gql} from '@apollo/client';

export const RECORD_ANALYTIC = gql`
	mutation RecordAnalytic(
		$resourceID: String
		$resourceType: String
		$action: String
		$description: String
		$originPage: String
	) {
		recordAnalytic(
			ResourceID: $resourceID
			ResourceType: $resourceType
			Action: $action
			Description: $description
			OriginPage: $originPage
		) {
			ID
		}
	}
`;

export const RECORD_PUBLIC_ANALYTIC = gql`
	mutation RecordPublicAnalytic(
		$resourceID: String
		$resourceType: String
		$action: String
		$description: String
		$originPage: String
	) {
		recordPublicAnalytic(
			ResourceID: $resourceID
			ResourceType: $resourceType
			Action: $action
			Description: $description
			OriginPage: $originPage
		) {
			ID
		}
	}
`;
