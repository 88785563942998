import React from 'react';
import {Spinner} from 'react-bootstrap';

import styles from './css/CenteredSpinner.module.scss';

const CenteredSpinner = () => {
	return (
		<div className={styles.centeredContent}>
			<Spinner animation="border" role="status">
				<span className="sr-only">Loading...</span>
			</Spinner>
		</div>
	);
};

export default React.memo(CenteredSpinner);
