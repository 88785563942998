import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../../context/auth-context';
import CenteredSpinner from '../../components/loading/CenteredSpinner';

export default function CheckSessionRedirect() {
	const {isAuthenticated} = useContext(AuthContext);
	const history = useHistory();

	if (isAuthenticated) {
		history.push('/');
	}

	return (
		<div className="bg-light-gray h-screen flex justify-center items-center">
			<div className="flex justify-center items-center h-screen">
				<CenteredSpinner />
			</div>
		</div>
	);
}
