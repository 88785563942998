import React, {useEffect, useMemo} from 'react';
import {Card, Row} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';

import Link from '../analytics/Link';
import styles from './css/Card.module.css';

const OAItemCard = (props) => {
	const {item, isShowingBookmarksOnly} = props;
	const location = useLocation();
	const imageUrl = useMemo(() => {
		if (item.image && item.image.normal && item.image.normal.url) {
			return item.image.normal.url;
		} else if (item.image && item.image.thumbnail && item.image.thumbnail.url) {
			return item.image.thumbnail.url;
		} else {
			return null;
		}
	}, [item.image]);
	// const [imageLoaded, setImageLoaded] = useState(!imageUrl);

	const pathname = `/news/${item.ID}/${item.Slug}`;

	useEffect(() => {
		if (imageUrl) {
			const img = new Image();
			img.src = imageUrl;
			// img.onload = () => setImageLoaded(true);
		}
	}, [imageUrl]);

	return (
		<Card border="light" className={'rounded-0 shadow ' + styles.cardContainer} style={{marginBottom: '25px'}}>
			{/*height set below to fix IE 11 bug*/}
			<Card.Body style={{height: '100%'}}>
				<Card.Title>
					<h3>
						<Link
							resourceID={item.id}
							resourceType="OAItem"
							to={{pathname: pathname, state: {background: location}}}
							description={isShowingBookmarksOnly ? 'bookmarks card' : 'card'}
						>
							{item.headline}
						</Link>
					</h3>
					{/* <NewsSource url={'http://oxan.com'} name={'Oxford Analytica'} /> */}
				</Card.Title>
				{/*item.image ? (
					<Link
						resourceID={item.id}
						resourceType="NewsArticle"
						to={{pathname: pathname, state: {background: location}}}
					>
						<div className={styles.newsImage}>
							{imageLoaded ? <BootstrapImage src={imageUrl} fluid /> : <div className={styles.newsImageLoader}></div>}
						</div>
					</Link>
				) : null*/}
				<Card.Text className="mt-3">{item.summaryMini}</Card.Text>
				<Row>
					{/* <UserBadge
						user={item.Submitter}
						contentDate={item.SubmittedAt}
						updatedContentDate={updatedContentDate(news)}
					/> */}
				</Row>
			</Card.Body>
		</Card>
	);
};

export default React.memo(OAItemCard);
