import React, {useEffect} from 'react';
import {Popover} from 'react-bootstrap';

const UpdatingPopover = React.forwardRef(({popper, children, show: _, ...props}, ref) => {
	useEffect(() => {
		popper.scheduleUpdate();
	}, [children, popper]);

	return (
		<Popover ref={ref} content {...props}>
			{children}
		</Popover>
	);
});

export default UpdatingPopover;
