import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Row, Col, Button, Card} from 'react-bootstrap';
import {useMutation} from '@apollo/client';
import {AuthContext} from '../../context/auth-context';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-regular-svg-icons';

import useHotRedirect from '../../hooks/useHotRedirect';
import {MetaTagsContext} from '../../context/meta';

import {RESEND_VERIFICATION_EMAIL} from '../../mutations/auth';

import Error from '../../components/error/Error';

import styles from './css/Unverified.module.css';

export default function Unverified(props) {
	const history = useHistory();
	const {setDefaultMetaTags} = useContext(MetaTagsContext);
	const {userData} = useContext(AuthContext);
	const [resend, {data, loading, error}] = useMutation(RESEND_VERIFICATION_EMAIL);

	useEffect(() => {
		setDefaultMetaTags();
	}, [setDefaultMetaTags]);

	useEffect(() => {
		if (!data && userData && (userData.VerifiedAt || userData.CreatedOrigin !== 'BART')) {
			history.push('/');
		}
	}, [userData, data, history]);

	useHotRedirect();

	return (
		<React.Fragment>
			<Row>
				<Col sm={{span: 8, offset: 2}} md={{span: 6, offset: 3}} lg={{span: 6, offset: 3}}>
					<Card className="pt-4 pl-4 pr-4 pb-2 text-center">
						<h3 className="mb-4">We've sent you a verification email</h3>
						<h1 className="mb-4">
							<FontAwesomeIcon size="lg" icon={faPaperPlane} />
						</h1>

						<p className="mb-md-0 mb-xs-2">Click the link in your email to confirm your account and get started.</p>
						<p className="mb-0">
							If you can't find the email, check your spam folder or click the button below to resend.
						</p>
						<Button className={styles.resend} variant="link" size="xs" disabled={!!data || loading} onClick={resend}>
							{loading ? 'Resending...' : data ? 'Email Sent' : 'Resend Email'}
						</Button>
						{data ? (
							<p className="text-success">We've sent you a new verification link! Check your email.</p>
						) : error ? (
							<p>
								<Error error={error} />
							</p>
						) : null}
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	);
}
