import React, {useContext, useEffect} from 'react';

import inAppLinkStyles from './css/InAppLink.module.scss';
import Link from '../../components/analytics/Link';
import {useLazyQuery} from '@apollo/client';
import {HUBS} from '../../queries/hub';
import {AuthContext} from '../../context/auth-context';
import {HubTypes} from '../../util/hubType';

export default function WebinarsLink(props) {
	const [fetchHubs, {data}] = useLazyQuery(HUBS);
	const {isAuthenticated} = useContext(AuthContext);
	const hubs = data ? data.hubs : null;

	useEffect(() => {
		if (isAuthenticated) {
			fetchHubs();
		}
	}, [isAuthenticated, fetchHubs]);

	if (hubs) {
		const filteredHubs = hubs.filter((hub) => hub.TypeID === HubTypes.LIVE_EVENT);
		if (filteredHubs.length > 0) {
			const hub = filteredHubs[0];
			return (
				<Link
					className={`${inAppLinkStyles.link} nav-link`}
					title="Events"
					to={`/hub/${hub.ID}/${hub.Slug}`}
					key={`link_nav_events`}
					description="navbar"
					resourceID={hub.ID}
					resourceType="Hub"
				>
					EVENTS
				</Link>
			);
		}
	}
	return null;
}
