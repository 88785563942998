import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'commonsui/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider} from '@apollo/client';
import {CookiesProvider} from 'react-cookie';
import {BrowserRouter} from 'react-router-dom';

import client from './client';
import App from './App';
import AuthProvider from './context/auth-context';
import MetaTagsProvider from './context/meta';
import ShortenerUrlProvider from './context/shortenedUrl';
import AnalyticsProvider from './context/analytics';
import * as heap from './heap';

heap.initialize();

ReactDOM.render(
	<CookiesProvider>
		<ApolloProvider client={client}>
			<AuthProvider>
				<MetaTagsProvider>
					<ShortenerUrlProvider>
						<AnalyticsProvider>
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</AnalyticsProvider>
					</ShortenerUrlProvider>
				</MetaTagsProvider>
			</AuthProvider>
		</ApolloProvider>
	</CookiesProvider>,
	document.getElementById('root')
);
