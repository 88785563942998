import React, {useRef} from 'react';

import {toast} from 'react-toastify';
import {faPrint} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {env} from '../../util/environment';
import {contentTypeIDToURLIdentifier, contentTypeToID} from '../../util/contentType';

import styles from './css/Links.module.scss';

const PrintButton = (props) => {
	const {
		item: {ID: itemID, __typename: itemType, ContentTypeID: contentTypeID},
		detailsView,
	} = props;

	const frameRef = useRef();

	const handlePrint = (event, asset) => {
		if (!asset) {
			toast('An error occurred while retrieving the file. Please try again.', {
				type: 'error',
			});
			return;
		}
		let fileURL;
		if (contentTypeID === contentTypeToID.REPORT) {
			fileURL = `${env.REACT_APP_API_DOMAIN}/api/assets/${contentTypeIDToURLIdentifier[contentTypeID]}/${asset.ID}`;
		} else {
			fileURL = asset.DownloadURL;
		}
		const fileType = asset.Extension || asset.Content;
		const iframe = frameRef.current;
		if (iframe) {
			if (iframe.src === '' || !iframe.src) {
				const params = {};
				if (contentTypeID === contentTypeToID.REPORT) {
					params.credentials = 'include';
				}
				fetch(fileURL, params)
					.then((response) => {
						response
							.arrayBuffer()
							.then((buffer) => {
								const url = window.URL.createObjectURL(new Blob([buffer], {type: `application/${fileType}`}));
								iframe.src = url;
								iframe.onload = () => {
									iframe.contentWindow.focus();
									iframe.contentWindow.print();
								};
							})
							.catch();
					})
					.catch();
			} else {
				iframe.contentWindow.focus();
				iframe.contentWindow.print();
			}
		}
	};

	const pdfAsset = props.item.Assets.find((asset) => asset.Extension === 'pdf' || asset.Content === 'pdf');

	const buttonClasses = [styles.dropdown, 'px-2'];
	if (detailsView) {
		buttonClasses.push('text-muted');
	} else {
		buttonClasses.push('text-white');
	}

	const showPrintIcon = (itemType === 'Report' && pdfAsset) || (itemType === 'LiveEvent' && pdfAsset);

	return showPrintIcon ? (
		<Button
			variant="link"
			className={buttonClasses.join(' ')}
			title="Print"
			onClick={(event) => handlePrint(event, pdfAsset)}
		>
			<FontAwesomeIcon size={detailsView ? 'lg' : '1x'} icon={faPrint} fixedWidth />
			<iframe style={{display: 'none'}} ref={(r) => (frameRef.current = r)} title={`print_${itemType}_${itemID}`} />
		</Button>
	) : null;
};

export default PrintButton;
