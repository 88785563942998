const highchartsOptions = () => {
	return {
		chart: {
			type: 'line',
			reflow: true,
			zoomType: 'xy',
			backgroundColor: '#ffffff',
			style: {
				fontFamily: 'Open Sans, Calibri, Arial, Helvetica, sans-serif',
			},
		},
		colors: ['#104b5f', '#27B6EB', '#ff9a07', '#6610f2', '#368650'],
		credits: {
			style: {
				color: '#000000',
			},
			text: 'FrontierView',
			href: '',
			width: '200',
			textOverflow: 'ellipsis',
		},
		exporting: {
			buttons: {
				exportButton: {
					enabled: false,
				},
			},
			chartOptions: {
				legend: {
					itemHiddenStyle: {
						color: 'none',
						display: 'none',
					},
					itemStyle: {
						textOverflow: undefined,
						width: 220,
					},
					width: 600,
					itemWidth: 300,
				},
			},
		},
		labels: {
			style: {
				color: '#000000',
			},
		},
		legend: {
			itemStyle: {
				fontWeight: 'normal',
				color: '#000000',
			},
		},
		navigation: {
			buttonOptions: {
				enabled: true,
			},
		},
		plotOptions: {
			series: {
				marker: {
					enabled: false,
				},
				enableMouseTracking: true,
				animation: false,
				dataLabels: {
					enabled: false,
					formatter: function () {
						return "'" + this.value.toString();
					},
					color: '#000000',
					allowOverlap: true,
				},
			},
			column: {
				animation: false,
			},
		},
		title: {
			text: '',
			style: {
				fontSize: '16px',
				color: '#000000',
			},
			align: 'center',
		},
		subtitle: {
			text: '',
			align: 'center',
			style: {
				fontSize: '1.2em',
				fontStyle: 'italic',
			},
		},
		tooltip: {
			style: {
				color: '#000000',
			},
		},
		xAxis: {
			allowDecimals: false,
			tickLength: 0,
			labels: {
				style: {
					color: '#000000',
				},
			},
		},
		yAxis: {
			title: {
				enabled: false,
			},
			gridLineColor: 'transparent',
			allowDecimals: false,
			lineWidth: 0,
			lineColor: '#000',
		},
	};
};

export default highchartsOptions;
