import React from 'react';
import {Form} from 'react-bootstrap';

import styles from './css/CenteredCheckbox.module.css';

export default function CenteredCheckbox({onChange, checked, label, id}) {
	return (
		<div className={styles.centeredCheckbox}>
			<Form.Check type="checkbox" onChange={onChange} checked={checked} label={label} id={id} />
		</div>
	);
}
