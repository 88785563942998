import React, {useState, useMemo} from 'react';
import {createHighchartOptions, buildHighCharts} from '../../commons/client/helpers/general_data_chart';
import {env} from '../../util/environment';

import HighchartsReact from 'highcharts-react-official';
import ErrorBoundary from '../misc/ErrorBoundary';

const InsightBiteGeneralDataChart = (props) => {
	const {chartInfo, height, showLegend, recordDownloadAnalytic} = props;
	const {GoogleSheetID: sheetID, Type, Title, Subtitle, Source, XAxisTitle, YAxisTitle, ZAxisTitle} = chartInfo;
	const {ID: chartTypeID} = Type;
	const exportingDefinitions = useMemo(
		() => ({
			menuItemDefinitions: {
				downloadPNG: {
					onclick: function () {
						this.exportChart({type: 'image/png'});
						recordDownloadAnalytic({description: 'PNG'});
					},
				},
				downloadJPEG: {
					onclick: function () {
						this.exportChart({type: 'image/jpeg'});
						recordDownloadAnalytic({description: 'JPEG'});
					},
				},
				downloadPDF: {
					onclick: function () {
						this.exportChart({type: 'application/pdf'});
						recordDownloadAnalytic({description: 'PDF'});
					},
				},
				downloadSVG: {
					onclick: function () {
						this.exportChart({type: 'image/svg'});
						recordDownloadAnalytic({description: 'SVG'});
					},
				},
			},
		}),
		[recordDownloadAnalytic]
	);
	const [chartRendered, setChartRendered] = useState(false);
	const [chartOptions, setChartOptions] = useState(
		createHighchartOptions({
			chartTypeID,
			source: Source,
			title: Title,
			subtitle: Subtitle,
			xAxisTitle: XAxisTitle,
			yAxisTitle: YAxisTitle,
			zAxisTitle: ZAxisTitle,
			sheetID,
			showLegend,
			chartInstance: null,
			apiKey: env.REACT_APP_SHEETS_API_KEY,
		})
	);

	const chartRef = (node) => {
		if (node && node.chart && !chartRendered) {
			setChartOptions(
				createHighchartOptions({
					chartTypeID,
					source: Source,
					title: Title,
					subtitle: Subtitle,
					xAxisTitle: XAxisTitle,
					yAxisTitle: YAxisTitle,
					zAxisTitle: ZAxisTitle,
					sheetID,
					showLegend,
					chartInstance: node,
					apiKey: env.REACT_APP_SHEETS_API_KEY,
				})
			);
			setChartRendered(true);
		}
	};

	return (
		<ErrorBoundary>
			<HighchartsReact
				containerProps={{style: {height: height || '400px'}}}
				highcharts={buildHighCharts()}
				options={{...chartOptions, exporting: {...chartOptions.exporting, ...exportingDefinitions}}}
				ref={(r) => chartRef(r)}
			></HighchartsReact>
		</ErrorBoundary>
	);
};

export default React.memo(InsightBiteGeneralDataChart);
