import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Col, Row, Form, Modal, Button} from 'react-bootstrap';
import {Formik, ErrorMessage, Form as FormikForm} from 'formik';
import * as yup from 'yup';

import styles from './css/ContactAnalyst.module.scss';

const ContactForm = (props) => {
	const {relatedContent, analyst, onSubmit, loading, setShow, hideLove} = props;

	return (
		<Formik
			initialValues={{message: ''}}
			onSubmit={onSubmit}
			enableReinitialize={true}
			validationSchema={yup.object().shape({
				message: yup.string().required('Please enter a message.'),
			})}
		>
			{({handleChange, handleBlur, values, errors, touched, isValid}) => (
				<FormikForm>
					<Modal.Body>
						<Row>
							<Col sm={12}>
								<h4 className="mt-4 text-center">Questions? Comments?</h4>
								<h4 className="text-center">{` \n Get in touch with ${analyst.Name.split(' ')[0]}.`}</h4>
								{!hideLove ? (
									<h6 className={'text-center ' + styles.tagline}>{`Our analysts love to hear from you. Send ${
										analyst.Name.split(' ')[0]
									} a message.`}</h6>
								) : null}
							</Col>
						</Row>
						<Form.Group className={touched.message && errors.message ? 'has-danger' : ''}>
							{relatedContent ? <h6 className={styles.contentTitle}>{`RE: ${relatedContent.Title}`}</h6> : null}
							<Form.Control
								name="message"
								as="textarea"
								rows="5"
								value={values.message}
								placeholder="Enter message"
								onChange={handleChange}
								onBlur={handleBlur}
								className={touched.message && errors.message ? 'is-invalid pt-0' : 'pt-0'}
							/>
							<ErrorMessage component="div" className="invalid-feedback" name="message" />
						</Form.Group>
						<div className={styles.buttonGroup}>
							<Button variant="secondary" onClick={() => setShow(false)}>
								Cancel
							</Button>
							<Button className="ml-3" variant="primary" disabled={loading || !isValid} type="submit">
								{loading ? 'Submitting...' : 'Submit'}
							</Button>
						</div>
					</Modal.Body>
				</FormikForm>
			)}
		</Formik>
	);
};

export default ContactForm;
