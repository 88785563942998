import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useParseSharedLink} from '../hooks/sharedlinks';

const SharedLinkWrapper = (props) => {
	const [backgroundOfSharedLink, cleansedPath] = useParseSharedLink();
	const history = useHistory();

	useEffect(() => {
		if (backgroundOfSharedLink) {
			history.push(backgroundOfSharedLink);
			history.push(cleansedPath, {from: {pathname: backgroundOfSharedLink}});
		}
	}, [backgroundOfSharedLink, cleansedPath, history]);

	return props.children;
};

export default SharedLinkWrapper;
