import React, {useEffect, useCallback, useMemo} from 'react';
import {Form, Container, Row, Col} from 'react-bootstrap';
import ReactSelect from '../input/ReactSelect';
import {useDebouncedCallback} from 'use-debounce';
import {useLazyQuery} from '@apollo/client';
import useBootstrapBreakpoint from '../../hooks/useBootstrapBreakpoint';

import {useMarket} from '../../hooks/useMarkets';
import {LATEST_MARKET_TRENDS} from '../../queries/market';

import SearchBar from './SearchBar';
import CardMasonry from '../card/CardMasonry';
import useContent from '../../hooks/useContent';
import CenteredCheckbox from '../../components/misc/CenteredCheckbox';
import PopularSearches from './PopularSearches';
import {useRecordClick, useRecordFilterChange, useRecordSortFilterChange} from '../../hooks/analytics';

import styles from './css/ScrollingContent.module.scss';

function ScrollingContent(props) {
	const {
		colSizes,
		initialFilters,
		searchQS,
		showFilters = true,
		showSearchBar = true,
		isFixedSearch,
		title,
		showPopularSearches,
		includeAutosuggestions,
		showMarket,
		showMarketAutosuggestions,
		onLoad,
		placeholderText,
		content,
		noResultsText,
		hideUntilResults,
		secondaryTitle,
		hubType,
		showBookmarked = true,
		showFeatured = true,
		useEventFilters = false,
	} = props;

	const {
		items,
		popularSearches,
		loading: contentLoading,
		error: contentError,
		totalItems,
		loadMoreContent,
		sortOptions,
		selectedSortFilter,
		sortFiltersUpdated,
		selectedFilter,
		filtersUpdated,
		filterOptions,
		filters,
		searchText,
		setSearchText,
		updateSearchTextInFilters,
		recordClearSearch,
		setShowBookmarks,
		setShowFeatured,
	} = useContent({
		initialFilters,
		searchQS: searchQS,
		useEventFilters,
	});

	const {callback: handleScroll} = useDebouncedCallback(() => {
		// trigger fetch 300px from the bottom of page
		if (!contentLoading) {
			if (!(window.innerHeight + document.documentElement.scrollTop > document.documentElement.offsetHeight - 300))
				return;
			loadMoreContent();
		}
	}, 25);

	useEffect(() => {
		if (!contentLoading && items && totalItems && !(totalItems <= 20) && items.length < totalItems) {
			window.addEventListener('scroll', handleScroll);
			return () => window.removeEventListener('scroll', handleScroll);
		}
		// eslint-disable-next-line
	}, [totalItems, items]);

	const doneLoading = items && items.length > 0;

	useEffect(() => {
		if (doneLoading && onLoad) {
			onLoad();
		}
		// eslint-disable-next-line
	}, [doneLoading]);

	const recordClickViewBookmarks = useRecordClick({
		description: 'view bookmarks',
	});

	const recordClickViewFeatured = useRecordClick({
		description: 'view featured',
	});

	const recordFilterChange = useRecordFilterChange();

	const recordSortChange = useRecordSortFilterChange();

	const showBookmarksClicked = useCallback(() => {
		setShowBookmarks(filters.bookmarked !== '1');
		if (filters.bookmarked !== '1') {
			recordClickViewBookmarks();
		}
	}, [setShowBookmarks, recordClickViewBookmarks, filters.bookmarked]);

	const showFeaturedContent = useCallback(() => {
		setShowFeatured(filters.featured !== '1');
		if (filters.featured !== '1') {
			recordClickViewFeatured();
		}
	}, [setShowFeatured, filters.featured, recordClickViewFeatured]);

	const [fetchMarketTrend, {loading: trendLoading, error: trendError, data: trendData}] = useLazyQuery(
		LATEST_MARKET_TRENDS
	);
	const market = useMarket({
		findMarketByName: showMarket && filters.bookmarked !== '1' && filters.featured !== '1' ? filters.searchText : '',
	});
	const relatedCountry = useMemo(() => {
		if (market) {
			if (filters.marketIDs && !filters.marketIDs.includes(market.ID)) {
				return null;
			} else {
				return {MarketName: market.Name, ...market};
			}
		}
		return market;
	}, [market, filters]);

	useEffect(() => {
		const refetchMarket =
			relatedCountry &&
			trendData &&
			trendData.latestMarketTrends &&
			trendData.latestMarketTrends.MarketTrends &&
			trendData.latestMarketTrends.MarketTrends[0].MarketID !== relatedCountry.ID;
		const fetchMarket = (relatedCountry && !trendData) || refetchMarket;
		if (fetchMarket && !(trendLoading || trendError)) {
			fetchMarketTrend({variables: {MarketID: relatedCountry.ID}});
		}
	}, [relatedCountry, trendLoading, trendData, trendError, fetchMarketTrend]);

	const marketInfo = useMemo(() => {
		return relatedCountry && trendData && trendData.latestMarketTrends && trendData.latestMarketTrends.MarketTrends
			? {Code: relatedCountry.Code, ...trendData.latestMarketTrends.MarketTrends[0]}
			: relatedCountry;
	}, [relatedCountry, trendData]);

	const breakpoint = useBootstrapBreakpoint();
	const isSmallBreakpoint = breakpoint === 'sm' || breakpoint === 'xs';

	const handleUpdateFilters = (selectedOption) => {
		recordFilterChange({description: selectedOption ? selectedOption.label : 'Clear'});
		filtersUpdated(selectedOption);
	};

	const handleUpdateSortFilters = (selectedOption) => {
		recordSortChange({description: selectedOption.label});
		sortFiltersUpdated(selectedOption);
	};

	const searchBarSize = {
		lg: filters.searchText ? 6 : 9,
		md: 8,
		sm: 6,
		xs: 12,
	};

	if (hideUntilResults && (!items || items.length === 0)) {
		return null;
	}
	return (
		<>
			{showSearchBar ? (
				<>
					{isFixedSearch ? (
						<div className={styles.fixedSearch}>
							<Container>
								<Form onSubmit={(event) => event.preventDefault()}>
									<Row>
										<Col xs={12}>{title ? <h3>{title}</h3> : null}</Col>
										<Col {...searchBarSize}>
											<SearchBar
												searchText={searchText}
												filters={filters}
												setSearchText={setSearchText}
												updateSearchTextInFilters={updateSearchTextInFilters}
												updateSortFilters={sortFiltersUpdated}
												recordClearSearch={recordClearSearch}
												includeAutosuggestions={includeAutosuggestions}
												showMarketAutosuggestions={showMarketAutosuggestions}
												placeholderText={placeholderText}
												content={content}
											/>
										</Col>
										{filters.searchText ? (
											<Col lg={3} md={4} sm={6} xs={12} className={`${styles.filterColumn} px-0`}>
												<ReactSelect
													id="sort"
													placeholder="Sort By"
													value={selectedSortFilter}
													onChange={handleUpdateSortFilters}
													options={sortOptions}
													hideSelectedOptions={false}
													isMulti={false}
													isClearable={false}
													isSearchable={false}
													className="search-filter-select"
												/>
											</Col>
										) : null}
										<Col lg={3} md={4} sm={6} xs={12} className={styles.filterColumn}>
											{showFilters ? (
												<ReactSelect
													placeholder="Filter"
													value={selectedFilter}
													onChange={handleUpdateFilters}
													options={filterOptions}
													hideSelectedOptions={false}
													isMulti={false}
													isClearable={true}
													isSearchable={false}
													className="search-filter-select"
												/>
											) : null}
										</Col>
									</Row>
									<Row>
										<Col md={8}>
											{!showPopularSearches || popularSearches.length === 0 || isSmallBreakpoint ? null : (
												<PopularSearches
													popularSearches={popularSearches}
													setSearchText={setSearchText}
													updateSearchTextInFilters={updateSearchTextInFilters}
													updateSortFilters={sortFiltersUpdated}
												/>
											)}
										</Col>
										<Col md={4}>
											{showBookmarked ? (
												<div className="mt-4 mb-2 mr-3 mt-sm-0 d-inline-block">
													<CenteredCheckbox
														onChange={showBookmarksClicked}
														checked={filters.bookmarked === '1'}
														label="Bookmarked Content Only"
														id="bookmark-checkbox"
													/>
												</div>
											) : null}
											{showFeatured ? (
												<div className="mt-4 mb-2 mt-sm-0 d-inline-block">
													<CenteredCheckbox
														onChange={showFeaturedContent}
														checked={filters.featured === '1'}
														label="Featured Content Only"
														id="featured-checkbox"
													/>
												</div>
											) : null}
										</Col>
									</Row>
								</Form>
							</Container>
						</div>
					) : (
						<Row>
							<Col sm={9} xs={12}>
								{title ? <h3 className={styles.grayHeader}>{title}</h3> : null}
							</Col>
							<Col lg={3} md={4} sm={6} xs={12} className={styles.filterColumn}>
								{showFilters ? (
									<ReactSelect
										placeholder="Filter"
										value={selectedFilter}
										onChange={filtersUpdated}
										options={filterOptions}
										hideSelectedOptions={false}
										isMulti={false}
										isClearable={true}
										isSearchable={false}
									/>
								) : null}
							</Col>
						</Row>
					)}
				</>
			) : null}
			<div className={isFixedSearch ? styles.searchResults : styles.inlineSearchResults}>
				{secondaryTitle ? (
					<Col xs={12}>
						<h3>{secondaryTitle}</h3>
					</Col>
				) : null}
				<CardMasonry
					filters={filters}
					loading={contentLoading}
					items={marketInfo && items ? [marketInfo, ...items] : items}
					totalItems={totalItems + Number(!!marketInfo)}
					error={contentError}
					hideComments={props.hideComments}
					colSizes={colSizes}
					isShowingBookmarksOnly={filters.bookmarked === '1'}
					noResultsText={noResultsText}
					hubType={hubType}
				></CardMasonry>
			</div>
		</>
	);
}

export default React.memo(ScrollingContent);
