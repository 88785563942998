import React from 'react';
import {Route, useLocation} from 'react-router-dom';

import MetaData from '../components/meta/MetaTags';

const PublicRoute = (props) => {
	const location = useLocation();
	const metaKey = location.state && location.state.background ? location.state.background.pathname : location.pathname;

	return (
		<MetaData key={metaKey} {...props}>
			<Route {...props} />
		</MetaData>
	);
};

export default PublicRoute;
