import {useReducer, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {omit} from 'lodash';

function filterReducer(state, action) {
	switch (action.type) {
		case 'ADD_FILTER':
			return {...state, [action.payload.filterKey]: action.payload.filterValue};
		case 'REMOVE_FILTER':
			return omit(state, action.payload);
		default:
			return state;
	}
}

const allowedParams = [
	'searchText',
	'filter',
	'marketIDs',
	'reportTypeIDs',
	'bookmarked',
	'keywordIDs',
	'sortBy',
	'featured',
	'eventDateTimeframe',
];
const defaultOptions = {qs: true};

export default function useFilters(options) {
	options = {...defaultOptions, ...options};
	const location = useLocation();
	const history = useHistory();

	const initialFilters = {};
	if (options.initialFilters) {
		for (let filter of options.initialFilters) {
			initialFilters[filter.key] = filter.value;
		}
	}

	const [filters, filterDispatch] = useReducer(filterReducer, initialFilters);
	useEffect(() => {
		if (options.qs) {
			const params = new URLSearchParams(location.search);
			for (let param of params) {
				if (allowedParams.includes(param[0]) && (!filters[param[0]] || filters[param[0]] !== param[1])) {
					filterDispatch({type: 'ADD_FILTER', payload: {filterKey: param[0], filterValue: param[1]}});
				}
			}
			for (let filter in filters) {
				if (!params.has(filter)) {
					filterDispatch({type: 'REMOVE_FILTER', payload: filter});
				}
			}
		}
		// eslint-disable-next-line
	}, [location.search, options.qs]);

	const addFilter = (key, value) => {
		if (allowedParams.includes(key)) {
			if (options.qs) {
				const params = new URLSearchParams(location.search);
				params.set(key, value);
				history.push({
					pathname: location.pathname,
					search: params.toString(),
				});
			}
			filterDispatch({type: 'ADD_FILTER', payload: {filterKey: key, filterValue: value}});
		}
	};
	const removeFilter = (key) => {
		if (options.qs) {
			const params = new URLSearchParams(location.search);
			params.delete(key);
			history.push({
				pathname: location.pathname,
				search: params.toString(),
			});
		}
		if (filters[key]) {
			filterDispatch({type: 'REMOVE_FILTER', payload: key});
		}
	};

	return [filters, {addFilter, removeFilter}];
}
