import {gql} from '@apollo/client';

import {UserFragment} from '../fragments/user';

export const INITIAL_AUTH_CHECK = gql`
	query GetCurrentUser {
		getCurrentUser {
			...UserFragmentFields
		}
	}
	${UserFragment}
`;

export const SESSION_CHECK = gql`
	query CheckSession {
		checkSession {
			isAuthenticated
		}
	}
`;

export const VERIFY_FORGOT_PASSWORD_TOKEN = gql`
	query VerifyForgotPasswordToken($token: String!) {
		verifyForgotPasswordToken(token: $token)
	}
`;

const defaultExports = {INITIAL_AUTH_CHECK, SESSION_CHECK};
export default defaultExports;
