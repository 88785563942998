import React from 'react';
import {default as ReactSelect, components} from 'react-select';
import './css/ReactSelect.module.scss';

const CheckOption = (props) => {
	return (
		<div>
			<components.Option {...props}>
				<input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
			</components.Option>
		</div>
	);
};

const Select = (props) => {
	if (props.isMulti) {
		return <ReactSelect components={{CheckOption}} {...props} />;
	}
	return <ReactSelect classNamePrefix="selectCustom" {...props} />;
};

export default Select;
