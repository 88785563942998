import {useMutation} from '@apollo/client';
import {Header} from 'commonsui';
import React, {Suspense, lazy, useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {withCookies} from 'react-cookie';
import {Redirect, Switch, useHistory, useLocation} from 'react-router-dom';
import styles from './App.module.scss';
import AuthModalWrapper from './components/modal/AuthModalWrapper';
import PopupContainer from './components/popup/PopupContainer';
import ResetPassword from './pages/forgotPassword/ResetPassword';
import Home from './pages/home/Home';
import Logout from './pages/logout/Logout';
import Unverified from './pages/verifyEmail/Unverified';
import VerifyEmail from './pages/verifyEmail/VerifyEmail';

// import ProtectedRoute from './components/auth/ProtectedRoute';

import {AuthContext} from './context/auth-context';
import {useRecordApplicationLoad, useRecordPublicApplicationLoad} from './hooks/analytics';
import {REFRESH_SESSION} from './mutations/auth';

import ReactGA from 'react-ga';
import {ToastContainer} from 'react-toastify';
import WebinarsLink from './components/header/WebinarsLink';
import CenteredSpinner from './components/loading/CenteredSpinner';
import ProtectedRoute from './hoc/ProtectedRoute';
import PublicPrivateRoute from './hoc/PublicPrivateRoute';
import PublicRoute from './hoc/PublicRoute';
import {useSendApplicationUnloadBeacon} from './hooks/analytics';
import useProfileSubscription from './hooks/useProfileSubscription';
import CheckSessionRedirect from './pages/checkSessionRedirect/CheckSessionRedirect';
import KeycloakLogin from './pages/keycloakLogin/KeycloakLogin';
import {env} from './util/environment';
import {TYPE_ENUM, TYPE_TO_URL_MAP} from './util/urls';

const ReactLazyPreload = (importStatement) => {
	const Component = lazy(importStatement);
	Component.preload = importStatement;
	return Component;
};

const MemoHeader = React.memo(Header);

// temporary name
const Marketing = ReactLazyPreload(() => import('./pages/marketing/Marketing'));
const Report = ReactLazyPreload(() => import('./pages/report/Report'));
// const AnalystCommentary = ReactLazyPreload(() => import('./pages/analystCommentary/AnalystCommentary'));
const Multimedia = ReactLazyPreload(() => import('./pages/multimedia/Multimedia'));
const InsightBite = ReactLazyPreload(() => import('./pages/insightBite/InsightBite'));
const Market = ReactLazyPreload(() => import('./pages/market/Market'));
const MarketsDropdown = ReactLazyPreload(() => import('./components/header/MarketsDropdown'));
const HealthcareDropdown = ReactLazyPreload(() => import('./components/header/HealthcareDropdown'));
const HubsDropdown = ReactLazyPreload(() => import('./components/header/HubsDropdown'));
const Analyst = ReactLazyPreload(() => import('./pages/analyst/Analyst'));
const SelectMarkets = ReactLazyPreload(() => import('./pages/selectMarkets/SelectMarkets'));
const Welcome = ReactLazyPreload(() => import('./pages/welcome/Welcome'));
const Hub = ReactLazyPreload(() => import('./pages/hub/Hub'));
const ProfileSettings = ReactLazyPreload(() => import('./pages/profile/ProfileSettings'));
const TermsAndConditions = ReactLazyPreload(() => import('./pages/termsAndConditions/TermsAndConditions'));
const Unsubscribe = ReactLazyPreload(() => import('./pages/unsubscribe/Unsubscribe'));
const LiveEvent = ReactLazyPreload(() => import('./pages/liveEvent/LiveEvent'));

const PublicReport = ReactLazyPreload(() => import('./pages/report/PublicReport'));
const PublicMultimedia = ReactLazyPreload(() => import('./pages/multimedia/PublicMultimedia'));
// const PublicAnalystCommentary = ReactLazyPreload(() => import('./pages/analystCommentary/PublicAnalystCommentary'));
const PublicInsightBite = ReactLazyPreload(() => import('./pages/insightBite/PublicInsightBite'));
const PublicLiveEvent = ReactLazyPreload(() => import('./pages/liveEvent/PublicLiveEvent'));

const RandomInsightBite = ReactLazyPreload(() => import('./pages/insightBite/RandomInsightBite'));
const RandomPublicInsightBite = ReactLazyPreload(() => import('./pages/insightBite/RandomPublicInsightBite'));

const PreloadAndRender = (props) => {
	const {Component, ComponentProps} = props;
	const content = [Report, Multimedia, InsightBite, /*AnalystCommentary,*/ LiveEvent];
	if (Component === Home) {
		content.push(Market, Analyst);
	}
	for (const c of content) {
		c.preload();
	}
	return props ? <Component {...ComponentProps} /> : <Component />;
};

const App = (props) => {
	const recordLoad = useRecordApplicationLoad();
	const recordPublicLoad = useRecordPublicApplicationLoad();
	useSendApplicationUnloadBeacon();
	useEffect(() => {
		ReactGA.initialize('UA-158744198-1', {
			debug: env.NODE_ENV !== 'production',
			gaOptions: {
				cookieDomain: env.REACT_APP_COOKIE_DOMAIN,
			},
		});
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	const {isAuthenticated, initialAuthChecked, hasFV3Access, userData, updateProfile} = useContext(AuthContext);

	const [loggedIn, setLoggedIn] = useState(hasFV3Access);
	const [allowFV3, setAllowFV3] = useState(hasFV3Access);

	const location = useLocation();
	const history = useHistory();

	// refreshing maintains location state which we don't want. if refresh, clear it.
	useEffect(() => {
		if (location.state && location.state.background) {
			history.replace(location.path, null);
		}
		// eslint-disable-next-line
	}, []);

	// make sure fv3 access reflects the cookie as well as it's value
	useEffect(() => {
		if (!loggedIn) {
			setAllowFV3(false);
		} else {
			setAllowFV3(hasFV3Access);
		}
	}, [loggedIn, hasFV3Access, setAllowFV3]);

	useEffect(() => {
		if (initialAuthChecked && !isAuthenticated) {
			setLoggedIn(false);
		} else if (hasFV3Access) {
			setLoggedIn(true);
		} else {
			setLoggedIn(false);
		}
		if (isAuthenticated) {
			recordLoad();
		} else if (initialAuthChecked) {
			recordPublicLoad();
		}
	}, [isAuthenticated, initialAuthChecked, recordLoad, recordPublicLoad, hasFV3Access]);

	// Refresh session on updates to persist subscription changes
	const [refetchSession] = useMutation(REFRESH_SESSION);
	useProfileSubscription(
		async ({
			data: {
				profileUpdate: {User},
			},
		}) => {
			try {
				await refetchSession();
				updateProfile(User);
			} catch {}
		}
	);

	useEffect(() => {
		const {hash} = location;
		const legacyFVURL = `https://${env.NODE_ENV !== 'production' ? 'staging' : ''}dashboards.frontierview.com`;
		if (hash && RegExp(/#tickets\/[0-9]+\/view/).test(hash)) {
			window.location.href = `${env.REACT_APP_API_DOMAIN}/redirect-me/` + encodeURIComponent(`${legacyFVURL}/${hash}`);
		}
	}, [location]);

	const background = location.state && location.state.background;

	const buildHeaderContent = (showInternal, tabs, isLoggedIn, allowFV3) => {
		return (
			<MemoHeader
				showInternal={showInternal}
				tabs={tabs}
				initialAuthChecked={initialAuthChecked}
				isLoggedIn={isLoggedIn}
				hasFV3Access={allowFV3}
				globalIntelligenceDomain={env.REACT_APP_LANDING_PAGE_DOMAIN}
				loginButtonClicked={() => {
					history.push(`/login?referring_url=${location.pathname}`);
				}}
				logoutButtonClicked={() => {
					window.location.href = `${env.REACT_APP_API_DOMAIN}/api/auth/logout`;
				}}
				loginTo={{
					pathname: '/login',
					state: {background: location},
				}}
			/>
		);
	};

	let headerContent = null;
	if (allowFV3 && userData) {
		const headerTabs = [
			<MarketsDropdown key={`header_tab_markets`} />,
			<HubsDropdown key={`header_tab_hubs`} />,
			<HealthcareDropdown key={`header_tab_healthcare`} />,
			<WebinarsLink key={`header_tab_webinars`} />,
		];
		headerContent = (
			<Suspense fallback={buildHeaderContent(false, [], false, false)}>
				{buildHeaderContent(userData.IsInternal, headerTabs, loggedIn, allowFV3)}
			</Suspense>
		);
	} else {
		headerContent = buildHeaderContent(false, [], loggedIn, allowFV3);
	}

	return (
		<div id="app">
			{headerContent}
			<AuthModalWrapper />
			<ToastContainer />
			<PopupContainer />
			<Container className={styles.mainContainer} fluid={location.pathname === '/' && (!loggedIn || !allowFV3)}>
				<Suspense
					fallback={
						<Row>
							<Col className={`d-flex justify-content-center`}>
								<CenteredSpinner />;
							</Col>
						</Row>
					}
				>
					<Switch location={background || location}>
						{allowFV3 ? (
							<ProtectedRoute path="/" exact render={(props) => <PreloadAndRender Component={Home} />} />
						) : loggedIn ? (
							<ProtectedRoute path="/" exact component={Marketing} />
						) : (
							<PublicRoute path="/" exact component={Marketing} />
						)}
						<ProtectedRoute
							path="/favorites"
							exact
							render={(props) => <PreloadAndRender Component={Home} ComponentProps={{showFavorites: true}} />}
						/>
						<PublicRoute
							path="/login-redirect"
							exact
							render={(props) => (
								<KeycloakLogin {...props} useLandingPage={env.NODE_ENV === 'development' ? false : true} />
							)}
						/>
						<PublicRoute path="/redirect" exact component={CheckSessionRedirect} />
						<PublicRoute path="/login" exact component={KeycloakLogin} />
						<PublicRoute path="/logout" exact component={Logout} />
						<PublicRoute
							path="/register"
							exact
							render={(props) => <Marketing openedAuthModal="register" {...props} />}
						/>
						<PublicRoute path="/verifyemail/:token" exact component={VerifyEmail} />
						<PublicRoute
							path="/forgotPassword"
							exact
							render={(props) => <Marketing openedAuthModal="forgotPassword" {...props} />}
						/>
						<PublicRoute path="/resetPassword/:token" exact component={ResetPassword} />
						<PublicRoute path="/welcome" exact component={Welcome} />
						<ProtectedRoute path="/unverified" exact component={Unverified} />
						<PublicPrivateRoute
							path="/insight"
							exact
							PublicComponent={RandomPublicInsightBite}
							PrivateComponent={RandomInsightBite}
							isModal={false}
							contentMeta={false}
						/>
						<PublicPrivateRoute
							path="/report/:id/:slug?/:pageNum?"
							exact
							PublicComponent={PublicReport}
							PrivateComponent={Report}
							metaKey={'report_page_:id'}
							isModal={false}
							contentMeta={true}
						/>
						{/* <PublicPrivateRoute
							path="/news/:id/:slug?"
							exact
							PublicComponent={PublicAnalystCommentary}
							PrivateComponent={AnalystCommentary}
							isModal={false}
							contentMeta={true}
						/> */}
						<PublicPrivateRoute
							path="/multimedia/:id/:slug?"
							exact
							PublicComponent={PublicMultimedia}
							PrivateComponent={Multimedia}
							isModal={false}
							contentMeta={true}
						/>
						<PublicPrivateRoute
							path="/insightBite/:id/:slug?/:pageNum?"
							exact
							PublicComponent={PublicInsightBite}
							PrivateComponent={InsightBite}
							metaKey={'insight_bite_page_:id'}
							isModal={false}
							contentMeta={true}
						/>
						<PublicPrivateRoute
							path="/liveEvent/:id/:slug?/:pageNum?"
							exact
							PublicComponent={PublicLiveEvent}
							PrivateComponent={LiveEvent}
							isModal={false}
							contentMeta={true}
							metaKey={'live_event_page_:id'}
						/>
						<ProtectedRoute
							path={TYPE_TO_URL_MAP[TYPE_ENUM.MARKET]}
							exact
							render={(props) => (
								<PreloadAndRender
									Component={Market}
									ComponentProps={{key: (background || location).pathname, ...props}}
								/>
							)}
						></ProtectedRoute>
						<ProtectedRoute
							path={TYPE_TO_URL_MAP[TYPE_ENUM.ANALYST]}
							exact
							render={(props) => <PreloadAndRender Component={Analyst} ComponentProps={props} />}
						/>
						<ProtectedRoute path="/selectmarkets" exact component={SelectMarkets} />
						<ProtectedRoute
							path={TYPE_TO_URL_MAP[TYPE_ENUM.HUB]}
							exact
							render={(props) => (
								<PreloadAndRender
									Component={Hub}
									ComponentProps={{key: `hub_page_${props.match.params.id}`, ...props}}
								/>
							)}
						/>
						<ProtectedRoute path="/settings" exact component={ProfileSettings} />
						<PublicRoute path="/termsOfService" exact component={TermsAndConditions} />
						<PublicRoute path="/unsubscribe/:emailID/:alertType" exact component={Unsubscribe} />
						<Redirect to="/" />
					</Switch>
					{background ? (
						<Switch>
							{/* <ProtectedRoute
								exact
								path="/news/:id/:slug"
								contentMeta={true}
								render={(props) => <AnalystCommentary isModal={true} {...props} />}
							/> */}
							<ProtectedRoute
								path="/report/:id/:slug/:pageNum?"
								exact
								contentMeta={true}
								render={(props) => <Report isModal={true} {...props} prevLocation={background} />}
							/>
							<ProtectedRoute
								path="/multimedia/:id/:slug"
								exact
								contentMeta={true}
								render={(props) => <Multimedia isModal={true} {...props} />}
							/>
							<ProtectedRoute
								path="/insightBite/:id/:slug/:pageNum?"
								exact
								contentMeta={true}
								render={(props) => <InsightBite isModal={true} {...props} />}
							/>
							<ProtectedRoute
								path="/liveEvent/:id/:slug/:pageNum?"
								exact
								contentMeta={true}
								render={(props) => <LiveEvent isModal={true} {...props} prevLocation={background} />}
							/>
						</Switch>
					) : null}
				</Suspense>
			</Container>
		</div>
	);
};

export default withCookies(App);
