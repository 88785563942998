import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';

import {useRecordClick, useRecordPublicClick} from '../../hooks/analytics';

const AnalyticLink = (props) => {
	const {resourceID, resourceType, isBootstrap, isPublic = false, ...rest} = props;
	const location = useLocation();
	const uri = props.href
		? props.href
		: typeof props.to === 'string' || props.to instanceof String
		? props.to
		: props.to.pathname;
	const recordClick = useRecordClick({
		description: props.description || uri,
		resourceID: resourceID,
		resourceType: resourceType,
		location: location.pathname,
	});
	const recordPublicClick = useRecordPublicClick({
		description: props.description || uri,
		resourceID: resourceID,
		resourceType: resourceType,
		location: location.pathname,
	});
	if (props.href) {
		return (
			<a {...rest} onClick={isPublic ? recordPublicClick : recordClick}>
				{props.children}
			</a>
		);
	} else if (isBootstrap) {
		return (
			<LinkContainer {...rest} onClick={isPublic ? recordPublicClick : recordClick}>
				{props.children}
			</LinkContainer>
		);
	} else {
		return (
			<Link {...rest} onClick={isPublic ? recordPublicClick : recordClick}>
				{props.children}
			</Link>
		);
	}
};

export default AnalyticLink;
