export const contentTypeToID = {
	REPORT: 1,
	MULTIMEDIA: 2,
	// NEWS_ARTICLE: 3,
	INSIGHT_BITE: 5,
	HUB: 7,
	LIVE_EVENT: 8,
};

export const contentTypeIDToType = {
	1: 'Report',
	2: 'Multimedia',
	// 3: 'NewsArticle',
	5: 'InsightBite',
	8: 'LiveEvent',
};

export const publicContentTypeIDToType = Object.keys(contentTypeIDToType).reduce((o, ID) => {
	o[ID] = 'Public' + contentTypeIDToType[ID];
	return o;
}, {});

export const HumanReadableContentTypes = {
	1: 'Report',
	2: 'Multimedia',
	// 3: 'Analyst Commentary',
	4: 'LiveView',
	5: 'Insight Bite',
	6: 'Event',
	7: 'Hub',
	8: 'LiveEvent',
};

export const contentTypeIDToURLIdentifier = {
	1: 'report',
	2: 'multimedia',
	// 3: 'news',
	5: 'insightBite',
	7: 'hub',
	8: 'liveEvent',
};

export const AssetType = {
	[contentTypeToID.REPORT]: {
		PDF: 1,
		POWERPOINT: 2,
		ICON: 3,
		CUSTOM: 4,
		VIDEO_URL: 5,
	},
	[contentTypeToID.MULTIMEDIA]: {
		RSVP_URL: 1,
		AUDIO_FILE: 2,
		WEBINAR_URL: 3,
		VIDEO_URL: 4,
		ICS_FILE: 5,
		POWERPOINT: 6,
	},
	[contentTypeToID.INSIGHT_BITE]: {
		IMAGE: 1,
	},
	[contentTypeToID.LIVE_EVENT]: {
		POWERPOINT: 1,
		PDF: 2,
		ICON: 3,
	},
};

export const ContentFilters = {
	[contentTypeToID.REPORT]: {
		value: 'reports',
		label: 'Reports',
	},
	[contentTypeToID.MULTIMEDIA]: {
		value: 'multimedia',
		label: 'Video',
	},
	// [contentTypeToID.NEWS_ARTICLE]: {
	// 	value: 'news',
	// 	label: 'Analyst Commentary',
	// },
	[contentTypeToID.INSIGHT_BITE]: {
		value: 'insightBites',
		label: 'Insight Bites',
	},
};

export const EventFilters = [
	{
		value: 'upcoming',
		label: 'Upcoming',
	},
	{
		value: 'past',
		label: 'Past',
	},
];

export const ContentTypes = {
	[contentTypeToID.INSIGHT_BITE]: {
		IMAGE: 1,
		DATA: 2,
		GENERAL_DATA: 3,
	},
	[contentTypeToID.REPORT]: {
		MARKET_REVIEW: 1,
		MONTHLY_MARKET_MONITOR: 2,
		REGIONAL_OUTLOOK: 3,
		CONSULTING_DELIVERABLE: 4,
		AHEAD_OF_THE_CURVE: 5,
		RAPID_RESPONSE_BRIEF: 6,
		MANAGEMENT_EXCELLENCE: 7,
		MARKET_SPOTLIGHT: 8,
		EVENT_PRESENTATION: 9,
		BENCHMARKING: 10,
		LEADERSHIP_BREIFING: 11,
	},
	[contentTypeToID.MULTIMEDIA]: {
		PODCAST: 1,
		WEBINAR: 2,
		VIDEO: 3,
	},
};
