import React, {useContext, useEffect} from 'react';
import {PasswordState, isPasswordValid} from 'commonsui';
import * as EmailValidator from 'email-validator';
import {useHistory} from 'react-router-dom';
import {Form, Button, Row, Col, Card, Image} from 'react-bootstrap';
import {Formik, ErrorMessage, Form as FormikForm} from 'formik';
import {AuthContext} from '../../context/auth-context';

import Error from '../../components/error/Error';

import styles from './css/Register.module.scss';

const Register = (props) => {
	const {
		register,
		registerState: {error},
		isAuthenticated,
		setAuthModalToShow,
	} = useContext(AuthContext);
	const history = useHistory();

	useEffect(() => {
		if (isAuthenticated) {
			history.push('/');
			setAuthModalToShow(null);
		}
	}, [isAuthenticated, history, setAuthModalToShow]);

	const handleSubmit = (values, actions) => {
		register(values.email, values.password, actions);
	};

	const validate = (values) => {
		const errors = {};
		if (!values.email || !EmailValidator.validate(values.email)) {
			errors.email = 'You must enter a valid email.';
		}

		if (!values.password) {
			errors.password = 'You must provide a password.';
		}
		if (!values.passwordConfirm) {
			errors.passwordConfirm = 'You must provide a password confirmation.';
		}

		const {isValid: passwordIsValid} = isPasswordValid(values.password);

		if (!passwordIsValid) {
			errors.password = 'Password is not valid.';
		}

		if (values.passwordConfirm !== values.password) {
			errors.passwordConfirm = 'Your passwords do not match.';
		}

		return errors;
	};

	return (
		<Row>
			<Col
				sm={!props.isModal ? {span: 8, offset: 2} : null}
				md={!props.isModal ? {span: 6, offset: 3} : null}
				lg={!props.isModal ? {span: 4, offset: 4} : null}
			>
				<Card className={props.isModal ? 'shadow-none' : null}>
					<div className={styles.header}>
						<Image alt="FrontierView" src="https://s3.amazonaws.com/images.frontierview.com/fv3/fv-fn-logo.png" />
					</div>
					<Card.Body className={`px-5 pb-5`}>
						<Row>
							<Col>
								<Formik
									initialValues={{email: '', password: '', passwordConfirm: ''}}
									onSubmit={handleSubmit}
									enableReinitialize={true}
									validate={validate}
								>
									{({handleChange, handleBlur, isSubmitting, values, errors, touched, isValid}) => (
										<FormikForm>
											<Form.Group className={touched.email && errors.email ? 'has-danger' : ''}>
												<Form.Control
													name="email"
													value={values.email}
													placeholder="Email"
													onChange={handleChange}
													onBlur={handleBlur}
													autoCapitalize="none"
													className={touched.email && errors.email ? 'is-invalid' : ''}
												/>
												<ErrorMessage component="div" className="invalid-feedback" name="email" />
											</Form.Group>
											<Form.Group className={touched.password && errors.password ? 'has-danger' : ''}>
												<Form.Control
													name="password"
													value={values.password}
													placeholder="Password"
													type="password"
													onChange={handleChange}
													onBlur={handleBlur}
													className={touched.password && errors.password ? 'is-invalid' : ''}
												/>
												<PasswordState password={values.password} />
											</Form.Group>
											<Form.Group className={touched.password && errors.password ? 'has-danger' : ''}>
												<Form.Control
													name="passwordConfirm"
													value={values.passwordConfirm}
													placeholder="Confirm Password"
													type="password"
													onChange={handleChange}
													onBlur={handleBlur}
													className={touched.passwordConfirm && errors.passwordConfirm ? 'is-invalid' : ''}
												/>
												<ErrorMessage component="div" className="invalid-feedback" name="passwordConfirm" />
											</Form.Group>
											{error && <Error error={error} />}
											<Button disabled={isSubmitting || !isValid} type="submit" className="btn-flat btn-block mt-5">
												<strong>{isSubmitting ? 'Creating Account...' : 'Create Account'}</strong>
											</Button>
										</FormikForm>
									)}
								</Formik>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col>
								<p className="text-center">
									Already have an account?{' '}
									<Button
										variant="link"
										className="p-0"
										onClick={() => {
											if (props.isModal) {
												setAuthModalToShow({modal: 'login'});
											} else {
												history.push('/login');
											}
										}}
									>
										Sign in
									</Button>
								</p>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default Register;
