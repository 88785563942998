import {useMemo, useContext, useEffect, useCallback, useState} from 'react';
import {useQuery, useLazyQuery} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../context/auth-context';
import {sortBy} from 'lodash';

import {HEADER_MARKET_REGIONS} from '../queries/market';
import {marketAliases} from '../commons/server/utils/markets';

function useMarketMaps() {
	const {data} = useQuery(HEADER_MARKET_REGIONS);

	const maps = useMemo(() => {
		const idMap = {};
		const nameMap = {};
		const humanReadableNameMap = {};

		if (data) {
			for (let region of data.headerMarketRegions) {
				for (let market of region.Children) {
					idMap[market.ID] = market;
					nameMap[market.Name.replace(/ /g, '').toLowerCase()] = market;
					humanReadableNameMap[market.Name] = market;
				}
			}
		}

		return {idMap, nameMap, humanReadableNameMap};
	}, [data]);

	return maps;
}

export function useMarkets() {
	const {isAuthenticated} = useContext(AuthContext);
	const [fetchMarkets, {data}] = useLazyQuery(HEADER_MARKET_REGIONS);

	useEffect(() => {
		if (isAuthenticated) {
			fetchMarkets();
		}
	}, [isAuthenticated, fetchMarkets]);

	const marketTree = useMemo(() => {
		if (!data) return [];

		const markets = data.headerMarketRegions.map((region) => {
			return {
				...region,
				Children: region.Children.map((market) => {
					return {
						...market,
						url: market.Name.replace(/ /g, '').toLowerCase(),
					};
				}),
			};
		});

		return markets;
	}, [data]);

	return marketTree;
}

export function usePopularMarkets() {
	const {isAuthenticated} = useContext(AuthContext);
	const [fetchMarkets, {data}] = useLazyQuery(HEADER_MARKET_REGIONS);

	useEffect(() => {
		if (isAuthenticated) {
			fetchMarkets();
		}
	}, [isAuthenticated, fetchMarkets]);

	const popularMarkets = useMemo(() => {
		if (!data) return [];

		const markets = [];
		for (let region of data.headerMarketRegions) {
			for (let market of region.Children) {
				markets.push(market);
			}
		}

		const inversePopularity = markets.map((market) => {
			return {
				...market,
				InversePopularity: 1 - market.Popularity,
			};
		});

		const sortedMarkets = sortBy(inversePopularity, ['InversePopularity', 'Name']);
		return sortedMarkets;
	}, [data]);

	return popularMarkets;
}

export function useMarket(options) {
	const {data} = useQuery(HEADER_MARKET_REGIONS);
	const history = useHistory();

	const [marketIDMap, marketNameMap] = useMemo(() => {
		const idMap = {};
		const nameMap = {};
		if (data) {
			for (let region of data.headerMarketRegions) {
				for (let market of region.Children) {
					idMap[market.ID] = market;
					nameMap[market.Name.replace(/ /g, '').toLowerCase()] = market;
				}
			}
		}
		return [idMap, nameMap];
	}, [data]);

	if (data && options.id) {
		if (marketIDMap[options.id]) {
			return marketIDMap[options.id];
		} else {
			history.push('/');
			return null;
		}
	} else if (data && options.name) {
		if (marketNameMap[options.name]) {
			return marketNameMap[options.name];
		} else {
			history.push('/');
			return null;
		}
	} else if (data && options.findMarketByName) {
		options.findMarketByName = options.findMarketByName.replace(/ /g, '').toLowerCase();
		if (marketAliases[options.findMarketByName]) {
			const foundMarketAlias = marketAliases[options.findMarketByName].replace(/ /g, '').toLowerCase();
			return marketNameMap[foundMarketAlias] ? marketNameMap[foundMarketAlias] : null;
		} else {
			return marketNameMap[options.findMarketByName] ? marketNameMap[options.findMarketByName] : null;
		}
	} else {
		return null;
	}
}

export function useMarketNameSearch(options) {
	const {humanReadableNameMap} = useMarketMaps();
	const [matchedMarkets, setMatchedMarkets] = useState([]);

	const searchMarketsByName = useCallback(
		(searchTerm) => {
			if (Object.keys(humanReadableNameMap).length === 0) {
				return {};
			}
			const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
			const highlightRegex = new RegExp(`\\b${escapedSearchTerm}`, 'i');
			const marketNames = Object.keys(humanReadableNameMap);
			const marketAliasKeys = Object.keys(marketAliases);
			const nameMatches = marketNames.filter((marketName) => highlightRegex.test(marketName));
			const aliasMatches = marketAliasKeys.filter((alias) => highlightRegex.test(alias));
			for (let aliasMatch of aliasMatches) {
				const market = marketAliases[aliasMatch];
				if (!nameMatches.includes(market)) {
					nameMatches.push(market);
				}
			}
			const markets = nameMatches.map((m) => {
				if (options.highlight) {
					const matches = m.match(highlightRegex);
					let highlighted = null;
					if (matches && matches.length > 0) {
						highlighted = `${m.replace(highlightRegex, `<span class="autosuggest-highlight">${matches[0]}</span>`)}`;
					}
					return {
						...humanReadableNameMap[m],
						Highlighted: highlighted,
					};
				} else {
					return humanReadableNameMap[m];
				}
			});
			setMatchedMarkets(markets);
		},
		[humanReadableNameMap, setMatchedMarkets, options.highlight]
	);

	return [searchMarketsByName, {matchedMarkets}];
}
