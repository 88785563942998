import React from 'react';
import {Image, Card, Media} from 'react-bootstrap';
import Link from '../analytics/Link';

import styles from './css/Card.module.css';

export default function MarketCard(props) {
	const {item: market} = props;

	const flagImage = `https://s3.amazonaws.com/images.frontierview.com/fv3/flags/${market.Code}.png`;
	const pathname = `/market/${market.MarketName.replace(/ /g, '').toLowerCase()}`;
	return (
		<Card border="light" className={'rounded-0 shadow ' + styles.cardContainer} style={{marginBottom: '25px'}}>
			<Link to={pathname} description="card" resourceID={market.MarketID} resourceType="Market" className="text-muted">
				<Card.Body>
					<Media className={styles.marketMedia}>
						<Media.Body className="p-2">
							<Image alt={market.MarketName} src={flagImage} className="mr-3" style={{width: '4rem'}} roundedCircle />
							<h4>{market.MarketName}</h4>
							<div className="mt-2">
								<p>{market.FSGView}</p>
							</div>
						</Media.Body>
					</Media>
				</Card.Body>
			</Link>
		</Card>
	);
}
