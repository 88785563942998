import React, {useEffect, useContext} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useMutation} from '@apollo/client';

import {AuthContext} from '../../context/auth-context';
import {MetaTagsContext} from '../../context/meta';

import {VERIFY_EMAIL} from '../../mutations/auth';

export default function VerifyEmail(props) {
	const {setDefaultMetaTags} = useContext(MetaTagsContext);
	const {userData} = useContext(AuthContext);
	const {token} = useParams();
	const history = useHistory();
	const [verifyEmail, {data: verifyData, error: verifyError}] = useMutation(VERIFY_EMAIL, {
		variables: {token},
		refetchQueries: ['GetCurrentUser'],
	});

	useEffect(() => {
		setDefaultMetaTags();
	}, [setDefaultMetaTags]);

	useEffect(() => {
		verifyEmail();
	}, [verifyEmail]);

	useEffect(() => {
		if (verifyData && userData && userData.VerifiedAt) {
			history.push('/selectmarkets');
		}
	}, [verifyData, userData, history]);

	if (verifyError) {
		return <p>Your token is invalid. It may be expired or incorrect.</p>;
	} else {
		return null;
	}
}
